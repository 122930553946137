import React, { useState } from "react";
import "../styles/CustomerNewProductDetails.css";
import "../styles/CustomerNewGiftCard.css";
import Nc1 from "../Images/newgiftcard.png";
import TextField from "@mui/material/TextField";
import Nc2 from "../Images/newgiftcard.png";
import Slider from "react-slick";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  CaretRight,
  CaretLeft,
  ShareNetwork,
  CalendarBlank,
} from "@phosphor-icons/react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <CaretRight className="CustomerNewGiftCard CustomerNewGiftCard-next-arrow" />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <CaretLeft className="CustomerNewGiftCard CustomerNewGiftCard-prev-arrow" />
    </div>
  );
}

function CustomerNewGiftCard() {
  const [count, setCount] = useState(1);

  const handleIncrement = () => setCount(count + 1);
  const handleDecrement = () => {
    if (count > 0) setCount(count - 1); // Prevent the count from going below 0
  };

  const images = [
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [isFavorited, setIsFavorited] = useState(false);

  const handleMouseEnter = () => {
    setIsFavorited(true);
  };

  const handleMouseLeave = () => {
    setIsFavorited(false);
  };

  const handleClick = () => {
    setIsFavorited((prev) => !prev);
  };

  return (
    <div>
      {" "}
      <div className="CustomerNewGiftCard-mainsection">
        <div className="CustomerNewGiftCard-mainsection1">
          <button
            className="fav-icons-CustomerNewGiftCard"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isFavorited ? (
              <FavoriteIcon style={{ color: "#fd2583" }} />
            ) : (
              <FavoriteBorderIcon style={{ color: "#fd2583" }} />
            )}
          </button>
          <button
            className="fav-icons-CustomerNewGiftCard2"
            onClick={handleClick}
          >
            <ShareNetwork className="share-icon-newgc" />
          </button>
          <Slider
            {...settings}
            className="CustomerNewGiftCard1-mainsection1-slider"
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.src}
                  alt={`Slide ${index}`}
                  className="CustomerNewGiftCard1-mainsection1-slider-images"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="CustomerNewGiftCard-mainsection2">
          <div className="CustomerNewGiftCard-mainsection2-padding">
            <div>
              <div className="CustomerNewGiftCard-t1">E GIFT CARD</div>
              <div className="CustomerNewGiftCard-t2">Myhraki Gift Card</div>
            </div>
            <div className="CustomerNewGiftCard-t3-sec">
              <div className="CustomerNewGiftCard-t3-sec-t1">₹ 2000</div>
              <div className="CustomerNewGiftCard-t3-sec-t2">2200</div>
              <div className="CustomerNewGiftCard-t3-sec-t3">
                MRP Inclusive of all taxes
              </div>
            </div>

            <div className="CustomerNewGiftCard-t5-sec">
              <div className="CustomerNewGiftCard-t4">Details</div>
              <div className="CustomerNewGiftCard-t4-line"></div>
              <div className="CustomerNewGiftCard-t6-sec">
                <TextField
                  required
                  label="Gift Cart Code"
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" }, // Label on focus
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" }, // Asterisk in pink
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)", // Line before focus
                    },
                  }}
                />

                <TextField
                  required
                  label="Receiver's Name "
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" }, // Label on focus
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" }, // Asterisk in pink
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)", // Line before focus
                    },
                  }}
                />
                <TextField
                  required
                  label="Receiver's Contact Number "
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" }, // Label on focus
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" }, // Asterisk in pink
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)", // Line before focus
                    },
                  }}
                />
                <TextField
                  required
                  label="Receiver's Email ID "
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" }, // Label on focus
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" }, // Asterisk in pink
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)", // Line before focus
                    },
                  }}
                />
                <TextField
                  required
                  label="Personal Message"
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" }, // Label on focus
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" }, // Asterisk in pink
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)", // Line before focus
                    },
                  }}
                />
                <div className="CustomerNewGiftCard-t7-sec-main">
                  <div className="CustomerNewGiftCard-t7-sec">
                    <div className="CustomerNewGiftCard-t7-text">
                      Delivery Date *
                    </div>

                    <CalendarBlank
                      style={{ color: "#fd2583", cursor: "pointer" }}
                      className="calender-icon-gc"
                    />
                  </div>
                  <div className="CustomerNewGiftCard-t7-line"></div>
                </div>
                <TextField
                  required
                  label="Sender Name"
                  variant="standard"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
                    "& .MuiInputLabel-asterisk": { color: "#fe585b" },
                    "& .MuiInput-underline:before": {
                      borderBottom: "2px solid hsla(0, 0%, 85%, 1)",
                    },
                  }}
                />
              </div>
              <div className="cust-new-land-gift-btnsec">
                <div className="cust-new-land-gift-btnsec1">
                  <div className="giftcard-cut-new-land-inc-dec">
                    <RemoveIcon
                      style={{ color: "#fd2583", cursor: "pointer" }}
                      onClick={handleDecrement}
                    />
                    <div className="giftcard-cut-new-land-inc-dec-num">
                      {count}
                    </div>
                    <AddIcon
                      style={{ color: "#fd2583", cursor: "pointer" }}
                      onClick={handleIncrement}
                    />
                  </div>
                </div>
                <div className="cust-new-land-gift-btnsec2">Add to chart</div>
              </div>
              <div className="CustomerNewGiftCard-t8-line"></div>
              <div className="GiftCard-pincode-section ">
                <div className="Giftcard-product-pincode ">
                  <div
                    className="Giftcard-pincode-entry"
                    contentEditable="true"
                    data-placeholder="Enter your pincode"
                  ></div>
                  <div className="Giftcard-check-pc">Check</div>
                </div>
                <div className="Giftcard-pincode-section-text">
                  Usually Delivered with 2-3 days
                </div>
              </div>
            </div>
            <div className="CustomerNewGiftCard-accord-sec">
              <Accordion
                sx={{
                  boxShadow: "none", // Removes the box shadow
                  borderBottom: "1px solid #e0e0e0", // Adds a bottom line
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Product Description
                </AccordionSummary>
                <AccordionDetails>yfrecbdhuxsk</AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  boxShadow: "none", // Removes the box shadow
                  borderBottom: "1px solid #e0e0e0", // Adds a bottom line
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Specifications
                </AccordionSummary>
                <AccordionDetails>yfrecbdhuxsk</AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  boxShadow: "none", // Removes the box shadow
                  borderBottom: "1px solid #e0e0e0", // Adds a bottom line
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  Reviews
                </AccordionSummary>
                <AccordionDetails>yfrecbdhuxsk</AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerNewGiftCard;

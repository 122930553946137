import React, { useEffect, useState, useContext } from "react";
// import CustomerNavbar from '../components/CustomerNavbar'
// import CustomerCategoryBar from '../components/CustomerCategoryBar'
import CustomerHeroCarousel from "../components/CustomerHeroCarousel";
// import "../styles/CustomerLandingPage.css";
import CustomerMarquee from "../components/CustomerMarquee";
// import Heading from '../components/Heading';
// import BuyerHeading from '../components/BuyerHeading';
import HorizontalScroller from "../components/HorizontalScroller";
// import { Star } from '@phosphor-icons/react';
import { API_BASE_URL } from "../App";
// import { Button, Skeleton } from '@mui/material';
import CustomerHeading from "../components/CustomerHeading";
import CustomerLayout from "../components/CustomerLayout";
import img1 from "../Images/LandingPage3.jpg";
import img2 from "../Images/TaleOfImages2.png";
// import img3 from "../Images/TaleOfImages3.png"
// import social1 from "../Images/social1.png"
// import social2 from "../Images/social2.png"
// import socila3 from "../Images/socila3.png"
import SliderArrowLeft from "../Images/SliderArrowLeft.png";
import SliderArrowRight from "../Images/SliderArrowRight.png";
import GCBanner from "../Images/GCBanner.png";
import GiftCard from "../Images/Gift Card New Option 1.jpg";
import GiftCardBanner from "../Images/GCBanner.jpg";
import GCBannerMob from "../Images/GCMobile.jpg";
// import CustomerCrafStoryCard from '../components/CustomerCrafStoryCard';
// import { useContext } from 'react';
// import { FilterContext, useFilterContext } from '../context/FilterContext';
// import CustomerImageLayout from '../components/CustomerImageLayout';
// import { useRef } from 'react';
// import ToastComponent from '../components/ToastComponent';
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import landingPage1 from "../Images/LandingImg1.jpg";
import landingPage2 from "../Images/LandingImg2.jpg";
import landingPage3 from "../Images/LaningImg3.jpg";
import landingPageSmall1 from "../Images/MobileImg1.jpg";
import landingPageSmall2 from "../Images/MobileImg2.jpg";
import landingPageSmall3 from "../Images/MobileImg3.jpg";
import "slick-carousel/slick/slick.css";
import { Skeleton, styled } from "@mui/material";
import "../styles/CustomerLandingPage.css";
import "slick-carousel/slick/slick-theme.css";
import CustomerProductCard from "../components/CustomerProductCard";
import CustomerTaleOfCraftsCard from "../components/CustomerTaleOfCraftsCard";
import CustomerCrafStoryCard from "../components/CustomerCrafStoryCard";
import { CustomerContext } from "../context/CustomerContext";
import CustomerCategoryCard from "../components/CustomerCategoryCard";
import "../styles/CustomerLandingPage.css";
import "../styles/HorizontalScroller.css";
import { useFilterContext } from "../context/FilterContext";
import CustomerProductCard2 from "../components/CustomerProductCard2";

function CustomerLandingPage(props) {
  // const {filters, updateFilters, setFilters} = useFilterContext();

  const [productData, setProductData] = useState([]);
  const [NewArrivalData, setNewArrivalData] = useState([]);
  const [recentViewdData, setRecentViewdData] = useState([]);
  const [mostViewedData, setMostViewedData] = useState([]);
  // const [pageNumber, setPageNumber] = useState(0);
  const [recentLoading, setRecentLoading] = useState(false);
  const [mostViewedLoading, setMostViewedLoading] = useState(false);
  const [talesLoading, setTailsLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const params = useParams();
  const invitedBuyerUniqueId = params.id;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 700);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { customerData, handleAuthExpiry, logout } =
    useContext(CustomerContext);
  const {
    updateFilters,
    products,
    setProducts,
    searchValue,
    filters,
    setFilters,
    fetchProductData,
    PageNumber,
    setPageNumber,
    newArrival,
    setNewArrival,
  } = useFilterContext();

  const pageSize = 10;
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const { filterCategory, setFilterCategory } = useContext(FilterContext)

  async function fetchLandingPageProductData() {
    setProductLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/search?PageNumber=${2}&PageSize=${pageSize}`,
        {
          // const response = await fetch(`${API_BASE_URL}/productgen/api/v1/get?sellerId=68E9FFFA-9A88-4764-BAE4-FF337693FAED`, {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setProductData(data?.result);
      setProductLoading(false);
    } catch {
      //console.log('Error')
      setProductLoading(false);
    }
  }
  async function fetchMostViewedData() {
    setMostViewedLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/search?PageNumber=${5}&PageSize=${pageSize}`,
        {
          // const response = await fetch(`${API_BASE_URL}/productgen/api/v1/get?sellerId=68E9FFFA-9A88-4764-BAE4-FF337693FAED`, {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setMostViewedData(data?.result);
      setMostViewedLoading(false);
    } catch {
      //console.log('Error')
      setMostViewedLoading(false);
    }
  }
  async function fetchNewArrivalData() {
    setNewLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/search?NewArrival=true&PageNumber=${0}&PageSize=${pageSize}&IncludeCount=${true}`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setNewArrivalData(data?.result?.result);
      // console.log(data)
      setNewLoading(false);
    } catch {
      //console.log('Error')
      setNewLoading(false);
    }
  }
  async function fetchRecentViwedData() {
    setRecentLoading(true);
    try {
      console.log("User ID before fetch:", customerData?.result?.userID);
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/getHistory?UserId=${customerData?.result?.userID}&Page=1&ItemsPerPage=10`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        // errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      setRecentViewdData(data?.result);
      console.log(data?.result);
      setRecentLoading(false);
      console.log("Recent Viewed Data:", data?.result);
    } catch {
      //console.log('Error')
      setRecentLoading(false);
    }
  }

  const [categoryData, setCategoryData] = useState([]);
  const [categories, setCategories] = useState([]);
  const { accessToken, setSelectedCategory } = useContext(CustomerContext);

  async function fetchCategoryData() {
    setCategoryLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/category/All`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setCategoryData(data?.result);
      setCategoryLoading(false);
    } catch {
      setCategoryLoading(false);
      //console.log('Error')
    }
  }

  useEffect(() => {
    fetchLandingPageProductData();
    fetchCategoryData();
    fetchNewArrivalData();
    fetchRecentViwedData();
    fetchMostViewedData();
  }, []);

  useEffect(() => {
    fetchRecentViwedData();
  }, [customerData]);
  const [taleOfCraftsData, setTaleOfCraftsData] = useState([]);
  async function fetchTaleOfCraftData() {
    setTailsLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/facts/api/v1/GetAllCFacts`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setTaleOfCraftsData(data?.result);
      setTailsLoading(false);
    } catch {
      //console.log('Error')
      setTailsLoading(false);
    }
  }
  useEffect(() => {
    fetchTaleOfCraftData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!recentViewdData || recentViewdData.length === 0) {
          fetchLandingPageProductData();
        } else {
          await fetchRecentViwedData();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleTendingNowClick = () => {
    updateFilters({
      searchValue: "",
      category: "",
      priceRange: 0,
    });
    fetchProductData("", "", "", false);
    navigate("/products");
    window.scrollTo(0, 0);
  };
  const SlickArrowLeftHero = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowRight}
      alt="prevArrow"
      {...props}
      style={{
        width: "52px",
        height: "62px",
        zIndex: "10",
        marginRight: "20px",
      }}
    />
  );

  const SlickArrowRightHero = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowLeft}
      alt="nextArrow"
      {...props}
      style={{
        width: "52px",
        height: "62px",
        zIndex: "10",
        marginLeft: "20px",
      }}
    />
  );
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowRight}
      alt="prevArrow"
      {...props}
      style={{ width: "30px", height: "30px" }}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowLeft}
      alt="nextArrow"
      {...props}
      style={{ width: "30px", height: "30px" }}
    />
  );
  const SlickArrowLeftCategory = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowRight}
      alt="prevArrow"
      {...props}
      style={{ width: "30px", height: "30px", marginLeft: -"22px" }}
    />
  );

  const SlickArrowRightCategory = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowLeft}
      alt="nextArrow"
      {...props}
      style={{ width: "30px", height: "30px" }}
    />
  );
  const SlickArrowLeftTale = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowRight}
      alt="prevArrow"
      {...props}
      style={{ width: "30px", height: "30px", fontWeight: "bolder" }}
    />
  );

  const SlickArrowRightTale = ({ currentSlide, slideCount, ...props }) => (
    <img
      src={SliderArrowLeft}
      alt="nextArrow"
      {...props}
      style={{ width: "30px", height: "30px", fontWeight: "bolder" }}
    />
  );
  const SlideContent = ({ title, subtitle, onClick }) => (
    <div
      style={{
        position: "absolute",
        top: "25%",
        right: "-14.5%",
        // transform: 'translateY(-50%)',
        color: "white",
        textAlign: "center",
        padding: "20px",
        background: "none",
        width: "97%",
      }}
    >
      <div>
        <div
          style={{
            maxWidth: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <div style={{ fontSize: "30px", fontWeight: "400", margin: "0" }}>
            {title}
          </div>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "400",
              margin: "0",
              marginBottom: "8px",
            }}
          >
            {subtitle}
          </div>
          <button className="shop-now-btn" onClick={handleTendingNowClick}>
            Shop now
          </button>
        </div>
      </div>
    </div>
  );
  var settingsForHeroCard = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,

    nextArrow: <SlickArrowLeftHero />,
    prevArrow: <SlickArrowRightHero />,
  };
  var settingsForProductCard = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1104,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };
  var settingsForCategoryCard = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
    nextArrow: <SlickArrowLeftCategory />,
    prevArrow: <SlickArrowRightCategory />,
  };
  var settingsForTalesCard = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
    nextArrow: <SlickArrowLeftTale />,
    prevArrow: <SlickArrowRightTale />,
    adaptiveHeight: true,
  };

  //     const Test = ()=>{

  //         const conts=[
  //             {name:'A', cities:['AA', 'AAA']},
  //             {name:'B', cities:['BB', 'BBB']},
  //             {name:'C', cities:['CC', 'CCC']},

  //     ]

  //     const [selectedCont, setSelectedCont] = useState();

  //         return(
  //             <>
  //                 <select onChange={(e)=>{console.log(e.target.value); setSelectedCont(e.target.value)}}>
  //                     {conts.map(cont=><option value={cont.name} >{cont.name}</option>)}
  //                 </select>
  //                <p> {selectedCont}</p>
  //                 {selectedCont&&
  //  <select>
  //  {conts.find(cont=>cont.name==selectedCont)?.cities?.map(city=><option value={city}>{city}</option>)}
  // </select>
  //                 }
  //             </>
  //         )
  //     }
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    console.log(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <CustomerLayout>
      {/* <Test /> */}
      {/* <CustomerImageLayout /> */}
      {/* <ToastComponent ref={toastRef} timeout={2000} /> */}
      {/* <button onClick={handleClick}>Show Toast</button> */}
      {/* <CustomerHeroCarousel /> */}
      <div>
        <div className="customer-landing-page-section-container-hero">
          <Slider {...settingsForHeroCard}>
            {windowWidth >= 880 && (
              <div style={{ width: "100%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPage1}
                  style={{
                    width: "100%",
                    height: "580px",
                    margin: "0 auto",
                    objectFit: "fill",
                  }}
                />
              </div>
            )}
            {windowWidth >= 880 && (
              <div style={{ width: "100%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPage2}
                  style={{ width: "100%", height: "580px", margin: "0 auto" }}
                />
              </div>
            )}
            {windowWidth >= 880 && (
              <div style={{ width: "100%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPage3}
                  style={{ width: "100%", height: "580px", margin: "0 auto" }}
                />
              </div>
            )}
            {windowWidth < 880 && (
              <div style={{ width: "90%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPageSmall1}
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "0 auto",
                    objectFit: "fill",
                  }}
                />
              </div>
            )}

            {windowWidth < 880 && (
              <div style={{ width: "90%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPageSmall2}
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "0 auto",
                    objectFit: "fill",
                  }}
                />
              </div>
            )}
            {windowWidth < 880 && (
              <div style={{ width: "90%", margin: "0 auto" }}>
                <img
                  loading="lazy"
                  src={landingPageSmall3}
                  style={{
                    width: "100%",
                    height: "auto",
                    margin: "0 auto",
                    objectFit: "fill",
                  }}
                />
              </div>
            )}
          </Slider>
        </div>
        <CustomerMarquee />
        {/* NEW ARRIVAL INTEGRATION*/}
        <CustomerHeading
          text="New Arrivals"
          viewAll
          nav="/products"
          newArrival={true}
        />
        <div className="customer-landing-page-section-container">
          <Slider {...settingsForProductCard}>
            {newLoading
              ? [1, 2, 3, 4]?.map((i) => (
                  <div key={i}>
                    <CustomerWishlistCardShimmer />
                  </div>
                ))
              : NewArrivalData?.map((product) => (
                  <div key={product.productId}>
                    <CustomerProductCard
                      {...product}
                      onClick={() => {
                        navigate(`/productDetail/${product.productId}`);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                ))}
          </Slider>
        </div>
        {/* {productData && <HorizontalScroller productData={productData} customerProductCard initialItems={4} />} */}
        <div
          style={{
            background: "rgba(255, 244, 235, 1)",
            width: "97%",
            height: "auto",
            minHeight: "550px",
            margin: "0 auto",
            padding: "8px",
            // paddingBottom:"84px",
            marginTop: "24px",
            // marginBottom: "40px",
          }}
        >
          {/* TALE OF CRAFT INTEGRATION*/}
          <CustomerHeading text="Tale of Crafts" viewAll nav="/Blog" />
          <div className="customer-landing-page-section-container-tale">
            <div className="tale-of-craft-container">
              {taleOfCraftsData && (
                <Slider {...settingsForTalesCard}>
                  {talesLoading
                    ? [1, 2, 3, 4]?.map((i) => (
                        <div key={i}>
                          <div
                            style={{
                              width: "284px",
                              maxHeight: "240px",
                              height: "621px",
                              margin: "0 auto",
                              borderRadius: "8px 8px 8px 8px",
                              border: "1px solid #fff",
                              backgroundColor: "#fff",
                            }}
                          ></div>
                        </div>
                      ))
                    : taleOfCraftsData?.map((product) => (
                        <div key={product.id} className="tale-of-craft-alldata">
                          <CustomerTaleOfCraftsCard {...product} />
                        </div>
                      ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
        {/* Popular Gift Ideas INTEGRATION*/}
        <CustomerHeading text="Popular Gift Ideas" viewAll nav="/products" />
        <div className="customer-landing-page-section-container">
          <Slider {...settingsForProductCard}>
            {productLoading
              ? [1, 2, 3, 4]?.map((i) => (
                  <div key={i}>
                    <CustomerWishlistCardShimmer />
                  </div>
                ))
              : productData?.result?.map((product) => (
                  <div key={product.productId}>
                    <CustomerProductCard
                      {...product}
                      onClick={() => {
                        navigate(`/productDetail/${product.productId}`);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                ))}
          </Slider>
        </div>
        {/* <CustomerReviews /> */}
        {/* SHOP BY CATEGORY INTEGRATION*/}
        <CustomerHeading text="Shop by Category" nav="/products" />
        <div className="customer-landing-page-section-container">
          {categoryData && (
            <Slider {...settingsForCategoryCard}>
              {categoryLoading
                ? [1, 2, 3, 4]?.map((i) => (
                    <div key={i} style={{ width: "287px" }}>
                      {/* Add your loading shimmer component here */}
                      <div
                        style={{
                          width: "98%",
                          height: "290px",
                          margin: "0px",
                          borderRadius: "10px",
                          border: "1px solid #FAF7F5",
                          // backgroundColor: "#F5F5F5", // Placeholder background color
                        }}
                      ></div>
                    </div>
                  ))
                : categoryData?.map((category) => (
                    <div
                      key={category.categoryId}
                      className="landingpage-category-container"
                      onClick={() => {
                        updateFilters({
                          ...filters,
                          category: category?.categoryId,
                        });
                        setPageNumber(0);
                        fetchProductData("", category?.categoryId, "", false);
                        navigate("/products");

                        window.scrollTo(0, 0);
                      }}
                      style={{ width: "287px" }}
                    >
                      <div
                        style={{
                          width: "94%",
                          height: "290px",
                          margin: "0px auto",
                          borderRadius: "10px",
                          border: "1px solid #FAF7F5",
                          backgroundImage: `url(${category?.blobImageURL})`,
                          backgroundSize: "cover",
                          position: "relative",
                        }}
                        className="landingpage-category-tail-container"
                      >
                        <div
                          className="landingpage-category-tail"
                          style={{
                            display: "flex",
                            paddingTop: "8px",
                            width: "-webkit-fill-available",

                            margin: "0px auto",
                            backgroundColor: "#9e534c",
                            position: "absolute",
                            bottom: "0%",
                            zIndex: "100",
                            opacity: "100%",
                            justifyContent: "center",
                          }}
                        >
                          {category?.categoryName}
                        </div>
                      </div>
                    </div>
                  ))}
            </Slider>
          )}
        </div>
        {/* Gift Cards INTGRATION*/}
        <CustomerHeading text="Gift Cards" nav="/giftCardDetail" />
        <div className="customer-landing-page-section-container">
          {isDesktop ? (
            <img
              src={GiftCardBanner}
              className="GCBanner"
              onClick={() => {
                navigate("/giftCardDetail");
                window.scrollTo(0, 0);
              }}
              loading="lazy"
            />
          ) : (
            <img
              src={GCBannerMob}
              className="GCBanner"
              onClick={() => {
                navigate("/giftCardDetail");
                window.scrollTo(0, 0);
              }}
              loading="lazy"
            />
          )}
        </div>
        {/* MOST VIEWED INTEGRATIONINTGRATION*/}
        <CustomerHeading
          text={recentViewdData?.[0]?.title ? "Most Viewed" : "Most Viewed"}
          viewAll
          nav="/products"
        />
        <div className="customer-landing-page-section-container">
          {/* <Slider {...settingsForProductCard}>
          {(newLoading && recentLoading)
            ? [1, 2, 3, 4]?.map((i) => (
              <div key={i}>
                <CustomerWishlistCardShimmer />
              </div>
            ))
            : !recentLoading&&recentViewdData?.[0]?.title? (recentViewdData?.map((product) => (
              <div key={product.productId}>
                <CustomerProductCard
                  {...product}
                  onClick={() => {
                    navigate(`/productDetail/${product.productId}`);
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            )))
            
            : (!newLoading&& NewArrivalData?.map((product) => (
              <div key={product.productId}>
                <CustomerProductCard
                  {...product}
                  onClick={() => {
                    navigate(`/productDetail/${product.productId}`);
                    window.scrollTo(0, 0);
                  }}
                />
              </div>
            )))}
        </Slider> */}
          <Slider {...settingsForProductCard}>
            {newLoading
              ? [1, 2, 3, 4]?.map((i) => (
                  <div key={i}>
                    <CustomerWishlistCardShimmer />
                  </div>
                ))
              : NewArrivalData?.map((product) => (
                  <div key={product.productId}>
                    <CustomerProductCard
                      {...product}
                      onClick={() => {
                        navigate(`/productDetail/${product.productId}`);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                ))}
          </Slider>
        </div>
        <div>
          <div>
            {/* {!customerData?.result?.userID && (
    <>
      <CustomerHeading text="RECENTLY VIEWED" viewAll nav="/products" />
      <div className="customer-landing-page-section-container">
        <Slider {...settingsForProductCard}>
          {mostViewedLoading
            ? [1, 2, 3, 4]?.map((i) => (
                <div key={i}>
                  <CustomerWishlistCardShimmer />
                </div>
              ))
            : mostViewedData?.result?.map((product) => (
                <div key={product.productId}>
                  <CustomerProductCard
                    {...product}
                    onClick={() => {
                      navigate(`/productDetail/${product.productId}`);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
              ))}
        </Slider>
      </div>
    </>
  )} */}
          </div>

          <div>
            {customerData?.result?.userID &&
              recentViewdData &&
              recentViewdData.length > 0 && (
                <>
                  <CustomerHeading
                    text="Recently Viewed"
                    viewAll
                    nav="/products"
                  />
                  <div className="customer-landing-page-section-container">
                    <Slider {...settingsForProductCard}>
                      {newLoading
                        ? [1, 2, 3, 4]?.map((i) => (
                            <div key={i}>
                              <CustomerWishlistCardShimmer />
                            </div>
                          ))
                        : recentViewdData?.map((product) => (
                            <div key={product.productId}>
                              <CustomerProductCard
                                {...product}
                                onClick={() => {
                                  navigate(
                                    `/productDetail/${product.productId}`
                                  );
                                  window.scrollTo(0, 0);
                                }}
                              />
                            </div>
                          ))}
                    </Slider>
                  </div>
                </>
              )}
          </div>
        </div>
        {/* <CustomerHeading text="Craft Stories " viewAll nav="/Blog" /> */}
        {/* <div className="customer-landing-page-section-container">
        {taleOfCraftsData && (
          <Slider {...settingsForTalesCard}>
            {talesLoading
              ? [1, 2, 3, 4]?.map((i) => (
                  <div key={i}>
                    
                    <div
                      style={{
                        width: "284px",
                        height: "267px",
                        margin: "0 auto",
                        borderRadius: "8px 8px 0px 0px",
                        border: "1px solid #FAF7F5",
                        backgroundColor: "#F5F5F5", 
                      }}
                    ></div>
                  </div>
                ))
              : taleOfCraftsData?.map((product) => (
                <div key={product.id}>
                  <CustomerCrafStoryCard {...product} />
                </div>
              ))}
          </Slider>
        )}
      </div> */}
        {/* {productData && <HorizontalScroller productData={productData} customerProductCard initialItems={4} />} */}
      </div>
    </CustomerLayout>
  );
}

export default CustomerLandingPage;

const CustomerWishlistCardShimmer = () => {
  return (
    <div
      // className="customer-product-card"
      style={{
        border: "1px solid rgba(217,217,217,1)",
        borderRadius: "8px",
        padding: "7px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "8px",
        width: "80%",
      }}
    >
      <div className="customer-product-card-img">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          borderRadius="15px"
        />
      </div>
      <Skeleton
        variant="text"
        className="customer-product-card-title"
        sx={{ fontSize: "1.25rem" }}
        width="80%"
        borderRadius="15px"
        height={25}
      />
      {/* <Skeleton
        variant="text"
        className="customer-product-card-category"
        sx={{ fontSize: "1rem" }}
        width="50%"
        height={10}
      /> */}
      <Skeleton
        variant="rectangular"
        className="customer-product-card-price"
        sx={{ fontSize: "1rem" }}
        width="80%"
        borderRadius="15px"
        height={10}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-review"
        sx={{ fontSize: "1rem" }}
        width="80%"
        borderRadius="15px"
        height={20}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-quantity"
        sx={{ fontSize: "1rem" }}
        width="50%"
        borderRadius="15px"
        height={30}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-title"
        width="100%"
        borderRadius="15px"
        height={30}
      />
    </div>
  );
};

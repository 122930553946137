import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";
import CustomerProductDetails1 from "../components/CustomerProductDetails1";
import CustomerLandingCard5 from "../components/CustomerLandingCard5";
import CustomerLandingCard4 from "../components/CustomerLandingCard4";
function CustomerNewProductDetails() {
  return (
    <div>
      <CustomerLayoutnew>
        <CustomerProductDetails1 />
        <CustomerLandingCard4 />
        <CustomerLandingCard5 />
      </CustomerLayoutnew>
    </div>
  );
}

export default CustomerNewProductDetails;

import React from "react";
import "../styles/CustomerNewMyAccount2.css";
import { PhoneCall, Envelope, WhatsappLogo } from "@phosphor-icons/react";
import TextField from "@mui/material/TextField";
function Faqsectionmyaacnew() {
  return (
    <div className="cust-myacc-help">
      <div className="cust-myacc-help-col">
        <div className="cust-myacc-help-sec-main">
          <div className="cust-myacc-help-sec1">
            <div className="cust-myacc-help-sec1-title">
              Contact us through Phone Number
            </div>
            <div className="cust-myacc-help-sec1-subtitle">
              Mon - Sat, 9 am to 7 pm
            </div>
          </div>
          <div className="cust-myacc-help-sec2">
            <PhoneCall />
            <div>+91-80507 97094</div>
          </div>
        </div>
        <div className="cust-myacclinesection" />
        <div className="cust-myacc-help-sec-main">
          <div className="cust-myacc-help-sec1">
            <div className="cust-myacc-help-sec1-title">
              Contact us through Email
            </div>
            <div className="cust-myacc-help-sec1-subtitle">
              Average Response Time - 24hrs
            </div>
          </div>
          <div className="cust-myacc-help-sec2">
            <Envelope />
            <div>hello@myhraki.com</div>
          </div>
        </div>
        <div className="cust-myacclinesection" />
        <div className="cust-myacc-help-sec-main">
          <div className="cust-myacc-help-sec1">
            <div className="cust-myacc-help-sec1-title">
              Contact us through Whatsapp
            </div>
            <div className="cust-myacc-help-sec1-subtitle">
              Average Response Time - 3min
            </div>
          </div>
          <div className="cust-myacc-help-sec2">
            <WhatsappLogo />
            <div>Whatsapp</div>
          </div>
        </div>
      </div>
      <div className="cust-myacc-help-or-sec">OR</div>

      <TextField
        required
        label="Full Name"
        variant="standard"
        sx={{
          "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
        }}
      />
      <TextField
        required
        label="Email Adress"
        variant="standard"
        sx={{
          "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
        }}
      />
      <TextField
        required
        label="Query"
        variant="standard"
        sx={{
          "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
        }}
      />
      <div className="submit-btn-faq-new-myacc">
        <div className="submit-btn-faq-new-myacc-help">Submit</div>
      </div>
    </div>
  );
}

export default Faqsectionmyaacnew;

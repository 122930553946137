import React, { useState, useEffect, useContext, useRef } from "react";
import "../styles/MyOrders.css";
import "../styles/BcOrderCard.css";
import "../styles/Common.css";
import "../styles/CustomerProfilePage.css";
import { Skeleton } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import BcOrderCard from "../buyerComponents/BcOrderCard";
import { Link, useNavigate } from "react-router-dom";
import { CaretLeft } from "@phosphor-icons/react";
import { CustomerContext } from "../context/CustomerContext";
import CustomerOrderModal from "../components/CustomerOrderModal";
import ToastComponent from "../components/ToastComponent";
import { API_BASE_URL } from "../App";
import EmptyPage from "./EmptyPage";
import closeIcon from "../Images/closeIncon.png";
import Box from "@mui/joy/Box";
import { IconButton } from "@mui/joy";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import InviteSeller from "../pages/InviteSeller";
import GiftCardPurchaseDetails from "./GiftCardPurchaseDetails";
// import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";
// import { nonWhiteSpace } from "html2canvas/dist/types/css/syntax/parser";
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

export default function MyOrders({ isOpen, onClose }) {
  // useNavigationTracking();
  const [currentTab, setCurrentTab] = useState("orders");
  // const [thisProductDetails, setThisProductDetails] = useState();
  // const [visibleCards, setVisibleCards] = useState(3);
  const [myOrdersData, setMyOrdersData] = useState(null);
  const [invoiceData, setInvoiceData] = useState();
  const [orderDetailData, setOrderDetailData] = useState([]);
  const {
    customerData,
    accessToken,
    setSelectedOrder,
    handleAuthCheck,
    selectedOrder,
    handleAuthExpiry,
    logout,
  } = useContext(CustomerContext);
  const { selectedOrderItemIdIndex, setSelectedOrderItemIdIndex } = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const toastRef = useRef();
  const [refId, setRefId] = useState();
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);

  // const isMobile = window.innerWidth <= 600;
  const handleViewInvoiceClick = async (selectedOrder) => {
    console.log(selectedOrder);

    try {
      const data = await fetchInvoiceData(selectedOrder);
      console.log(data);

      if (data?.result[0]?.orderNumber) {
        setSelectedTab("viewInvoice");
        setOpenModal(true);
      }
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    }
  };

  const handleClose = () => {
    console.log("Closing modal");
    setOpenModal(false);
  };
  useEffect(() => {
    if (currentTab === "orders" && updated === true) {
      fetchMyOrdersData();
      setUpdated(false);
    }
  }, [currentTab]);

  useEffect(() => {
    if (updated === false) {
      fetchMyOrdersData();
      fetchGiftCardData();
    }
  }, []);

  const fetchMyOrdersData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/api/v1/GetAllOrderByUserId?UserId=${customerData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
        setLoading(false);
      }
      setLoading(false);

      //console.log(data);
      setMyOrdersData(data?.result?.reverse());
    } catch {
      setLoading(false);
    }
  };
  function removeLastHyphenAndAfter(input) {
    console.log(input);
    var lastHyphenIndex = input?.lastIndexOf("-");
    if (lastHyphenIndex >= 0) {
      return input?.substring(0, lastHyphenIndex);
    }
    return input;
  }

  const fetchInvoiceData = async (selectedOrder) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/api/v1/inv/${selectedOrder}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Assuming accessToken is defined
          },
        }
      );

      const data = await response.json();
      setLoading(false);
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result) {
        // Check if data.result is defined before reversing
        console.log("Invoice Data:", data.result);
        setInvoiceData(data.result.reverse());
        return data;
      } else {
        console.error("Error: Data or data.result is undefined");
        // Handle the case where data.result is undefined
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching invoice data:", error);
      // Handle the error as needed
    }
  };
  const componentRef = useRef();

  const uniqueOrderRefIds = [];

  // Then, filter the myOrdersData array to get only unique order_RefrenceId values
  const uniqueOrders = myOrdersData?.filter((order) => {
    if (!uniqueOrderRefIds.includes(order.order_RefrenceId)) {
      uniqueOrderRefIds.push(order.order_RefrenceId);
      return true;
    }
    return false;
  });

  const namesByRefId = uniqueOrderRefIds.map((refId) => {
    const names = myOrdersData
      .filter((order) => order.order_RefrenceId === refId)
      .map((order) => order.sku);
    return names;
  });
  // const detailsByRefId = uniqueOrderRefIds.map((refId) => {
  //   const names = myOrdersData
  //     .filter((order) => order.order_RefrenceId === refId)
  //     .map((order) => order);
  //   return names;
  // });
  const navigate = useNavigate();

  const orderDetailsByRefId = uniqueOrderRefIds.map((refId) => {
    const details = myOrdersData
      .filter((order) => order.order_RefrenceId === refId)
      .map((order) => order);
    return details;
  });

  const goToProductPage = async (sku) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?sku=${sku}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      handleAuthCheck(response);
      const data = await response.json();
      //console.log(data?.result[0]);
      // setThisProductDetails(data?.result[0]);
      navigate(`/productDetail/${data?.result[0]?.productId}`);
    } catch {
      // setProductLoading(false);
      //console.log('Error')
    }
  };

  const [orderDetailDates, setOrderDetailDates] = useState([]);

  const getOrderItemDate = async (item) => {
    console.log("insideFunctionCalled");
    console.log(item);
    if (item?.orderStatus === "Completed" && item?.shipmentStatus === "DC") {
      const data = await fetch(
        `${API_BASE_URL}/orders/api/v1/os/list?itemid=${item?.orderitemNumber}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const response = await data.json();
      console.log(
        response?.result?.[response?.result?.length - 1]?.updatedDate
      );
      const updatedDate =
        response?.result?.[response?.result?.length - 1]?.updatedDate ??
        "1999-02-01T09:43:47";
      setOrderDetailDates((prevDates) => {
        console.log([
          ...prevDates,
          {
            [`${response?.result?.[response?.result?.length - 1]?.itemId}`]:
              updatedDate,
          },
        ]);
        return [
          ...prevDates,
          {
            [`${response?.result?.[response?.result?.length - 1]?.itemId}`]:
              updatedDate,
          },
        ];
      });
    }
  };

  const getDates = () => {
    setOrderDetailDates([]);

    orderDetailData?.forEach((item) => getOrderItemDate(item));
  };

  useEffect(() => {
    if (currentTab === "details") {
      setOrderDetailDates([]);
      getDates();
    }
  }, [currentTab]);
  const [giftCardLoading, setGiftCardLoading] = useState(false);
  const [voucherData, setVoucherData] = useState();
  const fetchGiftCardData = async () => {
    setGiftCardLoading(true);
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        // `${API_BASE_URL}/cart/api/v1/getbybuyerID?buyerID=${customerData?.result?.userID}`,
       `${API_BASE_URL}/coupons/api/v1/GetVoucherStatus?PurchaseUserid=${customerData?.result?.userID}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setGiftCardLoading(false);
      const data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
      if (data?.result) {
        setVoucherData(data?.result);
        console.log(data?.result);
        return data?.result;
      } else {
        toastRef.current.showToast("Error Fetching Cart Data", "error");
      }
    } catch (e) {
      setGiftCardLoading(false);
      // console.log(e);
    }
  };
  return (
    <>
      <ToastComponent ref={toastRef} timeout={40000} />

      <>
        <Tabs />
      </>
    </>
  );

  function Tabs() {
    const [value, setValue] = React.useState("1");
    const containerRef = useRef();

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    function formatDate(inputDate) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(inputDate);
      return date.toLocaleDateString("en-US", options);
    }

    //     const an= 'abcdef';
    // const invoiceData=
    //   `<h1 style={{color: 'red'}}>hello World ${an}</h1>`

    // console.dir(invoiceData)

    // const downloadInvoice = () => {
    //   // Dynamically create the content for the PDF
    //   const invoiceData = '<h1 style="color: red;">Hello World</h1>';

    //   // Create a temporary container and append the content
    //   const tempContainer = document.createElement('div');
    //   tempContainer.innerHTML = invoiceData;
    //   containerRef.current.appendChild(tempContainer);

    //   // Use html2canvas to convert the content to a canvas
    //   html2canvas(tempContainer).then((canvas) => {
    //     // Create a PDF and add the canvas as an image
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const imgData = canvas.toDataURL('image/png');
    //     pdf.addImage(imgData, 'PNG', 10, 10);

    //     // Save or print the PDF as needed
    //     pdf.save('invoice.pdf');

    //     // Remove the temporary container
    //     containerRef.current.removeChild(tempContainer);
    //   });
    // };

    return (
      <Box
        sx={{ width: "100%", typography: "body1" }}
        className="my-orders-cards"
      >
        <CustomerOrderModal
          updated={updated}
          setUpdated={setUpdated}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          fetchMyOrdersData={fetchMyOrdersData}
          myOrdersData={myOrdersData}
          refId={refId}
          setRefId={setRefId}
          itemDetails={orderDetailData}
          orderDetailData={orderDetailData}
          selectedOrderItemIdIndex={selectedOrderItemIdIndex}
          setSelectedOrderItemIdIndex={setSelectedOrderItemIdIndex}
          selectedTab={selectedTab}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          invoiceData={invoiceData}
        />

        {currentTab === "orders" && (
          <div>
            <div className="profile-page-head">My Orders</div>
            <div className="orders-screen-container">
              <TabContext value={value}>
                {/* <Box  className="order-tabs" sx={{ maxWidth: { xs: 520, sm: 480 }, bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable prevent tabs example"
      >
        <Tab label="All Orders" value="1" key={1} />
        <Tab label="Completed" value="2" key={2}/>
        <Tab label="Returned"  value="3" key={3}/>
        <Tab label="Cancel" value="4" key={4}/>
        
      </Tabs>
    </Box> */}
                {loading && (
                  <div>
                    <MyOrdersShimmer />
                    <MyOrdersShimmer />
                    <MyOrdersShimmer />
                    <MyOrdersShimmer />
                    <MyOrdersShimmer />
                  </div>
                )}
                {myOrdersData?.length === 0 && (
                  <div>
                    <EmptyPage desc="No order" />
                  </div>
                )}
                
                {myOrdersData?.length !== 0 && (
                  <Box
                    className="order-tabs"
                    style={{
                      margin: "0",
                      position: "relative",
                      padding: "0px",
                      width:'100%',
                      gap:'1px',
        //            
                    }}
                    sx={{
                      width: { xs: "370px", sm: "500px", md: "660px" },
                      borderBottom: 0,
                    }}
                  >
                    {/* <button onClick={()=>{toastRef.current.showToast('Anand', 'success')}}>Click</button> */}
                    <TabList
                      onChange={handleChange}
                      indicator={
                        <Box
                          borderBottom={1}
                          borderColor="rgba(253, 42, 128, 1) !important"
                        />
                      }
                    >
                      <Tab label="Orders" value="1" key={1} />
                      <Tab label="Completed" value="2" key={2} />
                      <Tab label="Returned" value="3" key={3} />
                      <Tab label="Cancelled" value="4" key={4} />
                      <Tab label="GiftCard" value="5" key={5} />
                    </TabList>
                    <BasicMenu />
                  </Box>
                )}
                <TabPanel className="tab-panel" value="1" key={1}>
                  {uniqueOrders?.map((order, index) => {
                    return (
                      <BcOrderCard
                        myOrdersData={myOrdersData}
                        orderDetailData={orderDetailData}
                        setOrderDetailData={setOrderDetailData}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        selectedOrderItemIdIndex={selectedOrderItemIdIndex}
                        setSelectedOrderItemIdIndex={
                          setSelectedOrderItemIdIndex
                        }
                        tab={currentTab}
                        setTab={setCurrentTab}
                        items={namesByRefId[index]}
                        itemDetails={orderDetailsByRefId[index]}
                        key={order.order_RefrenceId}
                      />
                    );
                  })}
                </TabPanel>
                <TabPanel className="tab-panel" value="2" key={2}>
                  {orderDetailsByRefId
                    ?.flat()
                    .filter(
                      (order) =>
                        order.orderStatus.toLowerCase() ===
                          "Completed".toLowerCase() &&
                        order.shipmentStatus.toLowerCase() ===
                          "DC".toLowerCase()
                    )
                    ?.map((order, index) => {
                      const filteredRefIds = orderDetailsByRefId
                        .flat()
                        .filter(
                          (order) =>
                            order.orderStatus.toLowerCase() ===
                              "Completed".toLowerCase() &&
                            order.shipmentStatus.toLowerCase() ===
                              "DC".toLowerCase()
                        )
                        .map((order) => [order]);

                      return (
                        <BcOrderCard
                          myOrdersData={myOrdersData}
                          orderDetailData={orderDetailData}
                          setOrderDetailData={setOrderDetailData}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          selectedOrderItemIdIndex={selectedOrderItemIdIndex}
                          setSelectedOrderItemIdIndex={
                            setSelectedOrderItemIdIndex
                          }
                          tab={currentTab}
                          setTab={setCurrentTab}
                          items={namesByRefId[index]}
                          itemDetails={filteredRefIds[index]}
                          key={order.order_RefrenceId}
                        />
                      );
                    })}
                </TabPanel>
                <TabPanel className="tab-panel" value="3" key={3}>
                  {orderDetailsByRefId
                    ?.flat()
                    .filter(
                      (order) =>
                        order.orderStatus === "Return" ||
                        order.shipmentStatus === "Return" ||
                        (order.orderStatus === "Completed" &&
                          (order.shipmentStatus === "Returned" ||
                            order.shipmentStatus === "Refund Scheduled" ||
                            order.shipmentStatus === "Replaced"))
                    )
                    ?.map((order, index) => {
                      // //console.log(order)
                      const filteredRefIds = orderDetailsByRefId
                        .flat()
                        .filter(
                          (order) =>
                            order.orderStatus === "Return" ||
                            order.shipmentStatus === "Return" ||
                            (order.orderStatus === "Completed" &&
                              (order.shipmentStatus === "Returned" ||
                                order.shipmentStatus === "Refund Scheduled" ||
                                order.shipmentStatus === "Replaced"))
                        )
                        .map((order) => [order]);
                      // //console.log(filteredRefIds)
                      return (
                        <BcOrderCard
                          myOrdersData={myOrdersData}
                          orderDetailData={orderDetailData}
                          setOrderDetailData={setOrderDetailData}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          selectedOrderItemIdIndex={selectedOrderItemIdIndex}
                          setSelectedOrderItemIdIndex={
                            setSelectedOrderItemIdIndex
                          }
                          tab={currentTab}
                          setTab={setCurrentTab}
                          items={namesByRefId[index]}
                          itemDetails={filteredRefIds[index]}
                          key={order.order_RefrenceId}
                        />
                      );
                    })}
                </TabPanel>
                <TabPanel className="tab-panel" value="4" key={4}>
                  {orderDetailsByRefId
                    ?.flat()
                    .filter(
                      (order) =>
                        order?.orderStatus === "Canceled" ||
                        order?.orderStatus === "Rejected" ||
                        order?.shipmentStatus?.toLowerCase() ===
                          "Canceled".toLowerCase() ||
                        (order.orderStatus.toLowerCase() ===
                          "Completed".toLowerCase() &&
                          order.shipmentStatus.toLowerCase() ===
                            "Canceled".toLowerCase()) ||
                        (order.orderStatus.toLowerCase() ===
                          "Completed".toLowerCase() &&
                          order.shipmentStatus.toLowerCase() ===
                            "Rejected".toLowerCase())
                    )
                    ?.map((order, index) => {
                      const filteredRefIds = orderDetailsByRefId
                        .flat()
                        .filter(
                          (order) =>
                            order?.orderStatus === "Canceled" ||
                            order?.orderStatus === "Rejected" ||
                            order?.shipmentStatus?.toLowerCase() ===
                              "Canceled".toLowerCase() ||
                            (order.orderStatus.toLowerCase() ===
                              "Completed".toLowerCase() &&
                              order.shipmentStatus.toLowerCase() ===
                                "Canceled".toLowerCase()) ||
                            (order.orderStatus.toLowerCase() ===
                              "Completed".toLowerCase() &&
                              order.shipmentStatus.toLowerCase() ===
                                "Rejected".toLowerCase())
                        )
                        .map((order) => [order]);
                      // console.log(filteredRefIds);
                      return (
                        <BcOrderCard
                          myOrdersData={myOrdersData}
                          orderDetailData={orderDetailData}
                          setOrderDetailData={setOrderDetailData}
                          selectedTab={selectedTab}
                          setSelectedTab={setSelectedTab}
                          selectedOrderItemIdIndex={selectedOrderItemIdIndex}
                          setSelectedOrderItemIdIndex={
                            setSelectedOrderItemIdIndex
                          }
                          tab={currentTab}
                          setTab={setCurrentTab}
                          items={namesByRefId[index]}
                          itemDetails={filteredRefIds[index]}
                          key={order.order_RefrenceId}
                        />
                      );
                    })}
                </TabPanel>

                <TabPanel className="tab-panel" value="5" key={5}>
                  {voucherData?.result?.map((voucher, index) => {
                    console.log(voucher);
                    return <GiftCardPurchaseDetails data={voucher} />;
                  })}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        )}
        {currentTab === "details" && (
          <div>
            <div
              className="profile-page-head"
              onClick={() => {
                setCurrentTab("orders");
              }}
            >
              <CaretLeft
                style={{
                  width: "28px",
                  height: "28px",
                  fontSize: "36px !important",
                  cursor: "pointer",
                }}
              />{" "}
              My Orders
            </div>
            <div className="buyer-order-details-container">
              {/* <div onClick={() => { setCurrentTab('orders') }} className="order-back-link">
              <CaretLeft /> Back</div> */}
              <div className="order-details-head-container">
                <div className="order-details-heading">
                  <div className="order-detail-date">
                    Ordered on :<> </> 
                     { formatDate( orderDetailData[0]?.date.split("T")[0])}
                  </div>
                  <div> order ID : {orderDetailData[0]?.order_RefrenceId}</div>
                </div>
                <div ref={containerRef} style={{ display: "none" }}></div>

                <div>
                  <div
                    className="order-detilas-invoice"
                    onClick={() => {
                      handleViewInvoiceClick(
                        orderDetailData[0]?.order_RefrenceId
                      );
                    }}
                  >
                    View Invoice
                  </div>
                  {/* <Modal open={openModal} onClose={onClose}>
      <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
              maxWidth:'100%'
              
            },
            [theme.breakpoints.only("sm")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
            },
            padding: '15px',
            borderRadius: 16,
          })}
        >
         <Box
            sx={{
              padding: '0px',
              display: "flex",
              //   gap: 1,
              width: { xs: "330px", sm:'330px',md: "700px" },
              flexDirection: { xs: "column", sm: "column" },
              alignItems: "flex-start",
              justifyContent: "space-center",
              overflow: "scroll", 
              "::-webkit-scrollbar": {
                width:'0px',
                background: "transparent !important"
              },
              "::-scrollbar": {
                width:'0px',
                background: "transparent !important"
              }
            }}
          >
             <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Adjust positioning as needed
              }}
            >
              <img loading="lazy" src={closeIcon} alt="" />
            </IconButton>
          <div>
       <InviteSeller />
          </div>
          </Box>
        </ModalDialog>
      </Modal> */}
                </div>
              </div>

              <div className="order-details-info">
                <div className="order-details-info-fields">
                  <div className="order-details-info-title">Shipping</div>
                  <div className="order-details-info-desc">
                    <div>
                      {" "}
                      {orderDetailData[0]?.name}
                      {orderDetailData[0]?.destinationAddress}
                      {orderDetailData[0]?.destinationPincode},
                      <div>{orderDetailData[0]?.destinationPlace},</div>
                      {orderDetailData[0]?.destinationState}
                    </div>

                    {/* {orderDetailData?.address?.[0]?.city}, */}
                    {/* {orderDetailData?.address?.[0]?.zipcode} */}
                  </div>
                </div>
                <div className="order-details-info-fields">
                  <div className="order-details-info-title"> Payment</div>
                  <div className="order-details-info-desc">
                    {" "}
                    {orderDetailData[0]?.payMode}
                  </div>
                </div>
                <div className="order-details-info-fields">
                  <div className="order-details-info-title">Order Summary</div>
                  <div className="order-details-info-desc">
                    {/* <Divider style={{margin:"1rem"}}/> */}
                    <div className="cart-checkout-row-buyers">
                      <div className="cart-checkout-amount-text">Amount</div>
                      <div className="cart-checkout-amount">
                        {orderDetailData?.reduce(
                          (sum, item) => sum + item.itemCost * item.quantity,
                          0
                        )}
                      </div>{" "}
                    </div>
                    <div className="cart-checkout-row-buyers">
                      <div className="cart-checkout-amount-text">GST</div>
                      <div className="cart-checkout-amount">
                        {orderDetailData
                          ?.reduce(
                            (sum, item) =>
                              sum +
                              parseFloat(
                                (
                                  (item?.itemCost *
                                  item?.gst *
                                  item?.quantity)/100
                                ).toFixed(2)
                              ),
                            0
                          )
                          .toFixed(2)}
                      </div>{" "}
                    </div>
                    {/* <div className="cart-checkout-row-buyers">
                    <div className="cart-checkout-amount-text">Quantity</div>
                    <div className="cart-checkout-amount"> {orderDetailData[0]?.quantity}</div>
                  </div> */}
                    <div className="cart-checkout-row-buyers">
                      <div className="cart-checkout-amount-text">
                        Shipping cost
                      </div>
                      <div className="cart-checkout-amount">
                        {" "}
                        {orderDetailData[0]?.shippingCost}
                      </div>
                    </div>
                    {/* <Divider style={{margin:"1rem"}}/> */}
                    <div className="cart-checkout-row-dark">
                      <div className="cart-checkout-amount-text">
                        TOTAL AMOUNT
                      </div>
                      <div>
                        
                        {orderDetailData[0]?.totalCost +
                          orderDetailData[0]?.shippingCost
                          +
                         parseFloat(orderDetailData
                            ?.reduce(
                              (sum, item) =>
                                sum +
                                parseFloat(
                                  (
                                    (item?.itemCost *
                                    item?.gst *
                                    item?.quantity)/100
                                  ).toFixed(2)
                                ),
                              0
                            )
                            .toFixed(2))
                          }
                      </div>
                      {/* <div className="cart-checkout-amount">₹ {sellerProductsData[sellerProductsId].sellerProductsQuantity*sellerProductsData[sellerProductsId].sellerProductsPrice}</div> */}
                    </div>
                  </div>
                </div>
              </div>
              {orderDetailData?.map((orderItem, index) => {
                return (
                  <div className="order-detail-info-item">
                    <div className="product-order-detail-info">
                      <div>
                        <img
                          loading="lazy"
                          src={orderDetailData[index]?.image}
                          alt="image"
                          className="order-detail-info-image"
                        />
                      </div>
                      <div>
                        <div className="order-detail-title">
                          {
                            orderItem?.title
                          }{" "}
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          Quantity : {orderItem?.quantity}
                        </div>
                        <div className="order-detail-info-button-fields">
                          <div className="order-detail-info-buttons">
                            {(orderItem?.orderStatus?.toLowerCase() ===
                              "completed".toLowerCase() ||
                              orderItem?.orderStatus === "Return" ||
                              orderItem?.orderStatus === "Replace" ||
                              orderItem?.orderStatus === "Rejected" ||
                              orderItem?.orderStatus === "Refund Scheduled" ||
                              orderItem?.orderStatus === "Canceled") && (
                              <button
                                className="Customer-common-btn"
                                onClick={() => {
                                  goToProductPage(orderDetailData[index]?.sku);
                                }}
                              >
                                Buy Again
                              </button>
                            )}

                            {(orderItem?.orderStatus?.toLowerCase() ===
                              "completed".toLowerCase() ||
                              orderItem?.orderStatus !== "Return" ||
                              orderItem?.shipmentStatus !== "Return" ||
                              orderItem?.orderStatus === "Replace" ||
                              orderItem?.shipmentStatus === "Canceled" ||
                              orderItem?.orderStatus !== "pending" ||
                              orderItem?.shipmentStatus !== "Rejected") && (
                              <button
                                className="CustomerPlain-common-btn"
                                style={{
                                  display: orderDetailDates?.some((item) => {
                                    const diff =
                                      new Date().getTime() -
                                      (`QC_${orderItem?.orderitemNumber}` ===
                                      `${Object.keys(item)[0]}`
                                        ? new Date(
                                            Object.values(item)[0]
                                          ).getTime()
                                        : 0);

                                    return diff < 5 * 24 * 60 * 60 * 1000;
                                  })
                                    ? "block"
                                    : "none",
                                }}
                                onClick={async () => {
                                  await setSelectedOrder(
                                    orderItem.orderitemNumber
                                  );
                                  setRefId(
                                    orderDetailData[index]?.orderitemNumber
                                  );
                                  setSelectedTab("return");
                                  setOpenModal(true);
                                }}
                              >
                                Return{" "}
                              </button>
                            )}
                            {(orderItem?.orderStatus === "pending" ||
                              (orderItem?.orderStatus ===
                                "Waiting for QC_Pass" &&
                                orderItem?.shipmentStatus === "QC_Failed")) && (
                              <button
                                className="CustomerPlain-common-btn"
                                onClick={async () => {
                                  await setSelectedOrder(
                                    orderItem.orderitemNumber
                                  );
                                  setRefId(
                                    orderDetailData[index]?.orderitemNumber
                                  );
                                  setSelectedTab("cancel");
                                  setOpenModal(true);
                                }}
                              >
                                Cancel
                              </button>
                            )}
                            {(orderItem?.orderStatus === "pending" ||
                              (orderItem?.orderStatus ===
                                "Waiting for QC_Pass" &&
                                orderItem?.shipmentStatus === "QC_Failed")) && (
                              <button
                                className="CustomerPlain-common-btn"
                                onClick={async () => {
                                  await setSelectedOrder(
                                    orderItem.orderitemNumber
                                  );
                                  setRefId(
                                    orderDetailData[index]?.orderitemNumber
                                  );
                                  setSelectedTab("track");
                                  setOpenModal(true);
                                }}
                              >
                                Track
                              </button>
                            )}
                          </div>
                          {(orderItem?.orderStatus === "Return" ||
                            orderItem?.orderStatus?.toLowerCase() ===
                              "completed") && (
                            <div>
                              <Link
                                className="review-link-order"
                                onClick={async () => {
                                  await setSelectedOrder(
                                    orderItem.orderitemNumber
                                  );
                                  setRefId(
                                    orderDetailData[index]?.orderitemNumber
                                  );
                                  setSelectedTab("review");
                                  setOpenModal(true);
                                }}
                              >
                                Write Review
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Box>
    );
  }

  function BasicMenu() {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //   setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //   setAnchorEl(null);
    // };

    return (
      <div>
        {/* <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="orders-menu"
        >
          Past 3 months
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Past 6 months</MenuItem>
          <MenuItem onClick={handleClose}>2021</MenuItem>
          <MenuItem onClick={handleClose}>2020</MenuItem>
        </Menu> */}
      </div>
    );
  }
}

const MyOrdersShimmer = () => {
  return (
    <div
      style={{
        border: "1px solid rgba(217,217,217,1",
        borderRadius: "8px",
        padding: "1rem",
        width: "80%",
        margin: "1rem 0",
      }}
    >
      {/* <Skeleton variant="circular" width={40} height={40} /> */}
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="60%"
        height={30}
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: "1rem" }}
        width="90%"
        height={180}
      />
    </div>
  );
};

import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";
import CustomerNewCart1 from "../components/CustomerNewCart1";
import CustomerLandingCard5 from "../components/CustomerLandingCard5";
import CustomerLandingCard4 from "../components/CustomerLandingCard4";
function CustomerNewCart() {
  return (
    <div>
      <CustomerLayoutnew>
        <CustomerNewCart1 />
        <CustomerLandingCard4 />
        <CustomerLandingCard5 />
      </CustomerLayoutnew>
    </div>
  );
}

export default CustomerNewCart;

import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom/dist";
import LogisticAdminLayoutAdjustment from "../components/LogisticAdminLayoutAdjustment";
import { useState } from "react";
import { API_BASE_URL } from "../App";

import "../styles/LogisticsAdmin.css";
import { LogisticsAdminContext } from "../context/LogisticsAdminContext";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import FreightCalculator from "../components/FreightCalculator";
import { errorAlert } from "../components/Alert";

function LogisticsAdminOrderItemDetails() {
  // const navigate = useNavigate();

  const [status, setStatus] = useState([]);
  const [currentStatus, setCurrentStatus] = useState();
  const [selectedStatus, setSelectedStatus] = useState();

  const getCurrentStatus = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/api/v1/os/list?itemid=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const data = await response.json();
      console.log(data);

      console.log(data?.result[data.result.length - 1]?.statusCode);
      setCurrentStatus(
        data?.result?.length > 0
          ? data?.result[data.result.length - 1]
          : { statusCode: "Pending" }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getAllStatus = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/orders/api/v1/sc/list`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStatus(data?.result);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateOrderStatusByLocal = async (code) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/logistic/api/v1/UpdateStatuscodeByLocalAgg`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            orderId: removeLastHyphenAndAfter(id),
            sku: product?.productSKUs[0]?.sku,
            orderItemId: id,
            statusCode: code,
          }),
        }
      );
      const data = response.json();
      getAllStatus();
      // setSelectedStatus(event.target.value);
    } catch {}
  };

  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
    // setCurrentStatus(null);
    updateOrderStatusByLocal(event.target.value);
  };

  const params = useParams();
  const id = params.orderId;

  const { setSelectedListItemIndex, accessToken } = useContext(
    LogisticsAdminContext
  );

  useEffect(() => {
    setSelectedListItemIndex(0);
  }, []);
  const [orderData, setOrderData] = useState("");
  const [buyerData, setBuyerData] = useState();
  const [costList, setCostList] = useState();
  // const [currentOrderStatus, setCurrentOrderStatus] = useState();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/logistic/api/v1/GetLogisticOrder?ItemId=${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setOrderData(data?.result);
        fetchProductData(data?.result?.sku);
        fetchBuyerDataFromOrder();
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateCost = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/logistic/api/v1/GetFreightRate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({
            pickup_postcode: pickupPincode,
            delivery_postcode: deliveryPincode,
            weight: weight,
            cod: true,
            breadth: dimensions?.width * dimensions?.length,
            height: dimensions?.height,
            declared_value: parseInt(buyerData?.totalCost),
          }),
        }
      );
      const data = await response.json();

      if (response.ok) {
        // setCostList(data?.result);
        console.log(JSON.parse(data?.result));
        const parsed = JSON.parse(data?.result);
        const couriers = parsed?.data?.available_courier_companies.map(
          (company) => {
            return {
              companyName: company.courier_name,
              freightCharge: company.freight_charge,
            };
          }
        );
        console.log(couriers);
        setCostList(couriers);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllStatus();
    fetchData();
  }, []);

  useEffect(() => {
    getCurrentStatus();
  }, []);

  const [product, setProduct] = useState();

  async function fetchProductData(sku) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?sku=${sku}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data?.result[0]);
      setProduct(data?.result[0]);
    } catch {
      console.log("Error");
    }
  }

  function removeLastHyphenAndAfter(input) {
    if (input.startsWith("QC_")) {
      input = input.substring(3);
    }
    var lastHyphenIndex = input.lastIndexOf("-");
    if (lastHyphenIndex >= 0) {
      return input.substring(0, lastHyphenIndex);
    }

    return input;
  }

  async function fetchBuyerDataFromOrder() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/api/v1/orderDetailsById?orderid=${removeLastHyphenAndAfter(
          id
        )}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
        }
      );
      const data = await response.json();
      console.log(data?.result?.result[0]);
      setBuyerData(data?.result?.result[0]);
    } catch {
      console.log("Error");
    }
  }

  const [pickupPincode, setPickupPincode] = useState();
  const [deliveryPincode, setDeliveryPincode] = useState();
  const [dimensions, setDimensions] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [weight, setWeight] = useState();

  const handleCheckButtonClick = () => {
    calculateCost();
  };

  const [selectedVendor, setSelectedVendor] = useState("");
  const [showThirdPartyScreen, setShowThirdPartyScreen] = useState(false);

  const handleVendorSelect = (event) => {
    const vendor = event.target.value;
    setSelectedVendor(vendor);
  };

  const assignOrderToLocal = async () => {
    try {
      let orderState = "";

      // Check if "Local" is selected and set the orderState accordingly
      if (selectedVendor === "Local") {
        orderState = "AfterQCLocalAggregator";
      } else if (!currentStatus?.statusCode === "DC") {
        orderState = ""; // or some other value for different vendors
      }

      const response = await fetch(
        `${API_BASE_URL}/logistic/api/v1/LocalPickUp`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({
            orderItemId: id,
            lableName: localOrderLabel,
            sku: product?.productSKUs[0]?.sku,
            orderid: removeLastHyphenAndAfter(id),
            orderState: orderState,
          }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (data?.result === "Update Successfull") {
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const assignOrderToShipRocket = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/logistic/api/v1/ConfirmOrRejectOrder`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json-patch+json",
          },
          body: JSON.stringify({
            itemName: product?.title,
            skuid: product?.productSKUs[0]?.sku,
            orderid: removeLastHyphenAndAfter(id),
            itemNumber: id,
            quantity: buyerData?.quantity,
            qualityCheckStatus: "New",
            ifRejectAddReason: "",
            warehouseId: orderData?.warehouseId,
            returnWarehouseId: orderData?.warehouseId,
            warehousePincode: orderData?.warehousepincode,
            logisticType: "BeforeQCAggregator",
            giftwrapCharges: 0,
            transactionCharges: 0,
            totalDiscount: 0,
            ifRejectLogisticName: "",
          }),
        }
      );
      const data = await response.json();
      console.log(data);
      if (data?.result == "Order Placed For PickUp") {
        fetchData();
      }
    } catch {}
  };

  const handleSubmit = () => {
    if (selectedVendor === "Local") {
      if (localOrderLabel != "") {
        assignOrderToLocal();
      } else {
        errorAlert("", "Label not present");
      }
    } else {
      assignOrderToShipRocket();
    }
  };
  const [activeTab, setActiveTab] = useState("ThirdParty");
  const [orderStatus, setOrderStatus] = useState("pending");

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  //local pickUp
  // const [LocalPickUp, setLocalPickUp] = useState();
  const [localOrderLabel, setLocalOrderLabel] = useState("");

  const [qc, setQc] = useState();

  useEffect(() => {
    console.log(pickupPincode, deliveryPincode, dimensions, weight);
  }, [pickupPincode, deliveryPincode, dimensions, weight]);

  useEffect(() => {
    {
      console.log(currentStatus?.statusCode);
    }
  });

  return (
    <LogisticAdminLayoutAdjustment>
      <div className="logistic-orders">
        <div className="order-head">
          <div className="order-column">
            <div className="order-column-name">Order ID</div>
            <div className="order-column-value">{orderData.orderID}</div>
          </div>
          <div className="order-column">
            <div className="order-column-name">Order Placed</div>
            <div className="order-column-value">{buyerData?.date}</div>
          </div>
          <div className="order-column">
            <div className="order-column-name">Delivery Date</div>
            <div className="order-column-value">
              {/* {orderData.deliveryDate} */}6 -7 days
            </div>
          </div>
          <div className="order-column">
            <div className="order-column-name">Status</div>
            <div className="order-column-value">
              <div className="status-button">{orderData.status}</div>
            </div>
          </div>
        </div>
        <div className="order-detail">
          <div className="order-product-status-detail">
            <div className="order-details-product-info">
              <img
                loading="lazy"
                className="order-details-product-image"
                src={product?.productSKUs[0]?.imagesUriList[0]?.uri}
                alt=""
              />
              <div className="order-details-product-details">
                <div className="order-details-product-name">
                  {product?.title}
                </div>
                <div className="order-details-product-size-color-qty">
                  Size {product?.productSKUs[0]?.size} Color:{" "}
                  {product?.productSKUs[0]?.color} Quantity:
                  {product?.productSKUs[0]?.quantity}
                </div>
                <div className="order-details-product-dimensions">
                  L*W*H:{" "}
                  {product?.productSKUs[0]?.productAttributeList[0]?.a_Value} *{" "}
                  {product?.productSKUs[0]?.productAttributeList[1]?.a_Value} *{" "}
                  {product?.productSKUs[0]?.productAttributeList[2]?.a_Value}
                  Weight:
                  {product?.productSKUs[0]?.productAttributeList[3]?.a_Value}
                </div>
              </div>
            </div>
            <div className="order-details-status-dropdown">
              Previous Status : {currentStatus?.remarks}
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                  value={
                    selectedStatus?.statusCode
                      ? selectedStatus?.statusCode
                      : null
                  }
                  onChange={handleChange}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ minWidth: "350px" }}
                >
                  {status?.map((stat) => {
                    return (
                      <MenuItem value={stat.statusCode}>
                        <div>{stat.description}</div>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="order-product-address-deatil">
            <div className="order-product-address-buyers">
              <div className="order-product-address-heading">Buyer Address</div>
              <div>
                {buyerData?.destinationAddress} ,{buyerData?.destinationPlace},{" "}
                {buyerData?.destinationState} {buyerData?.destinationPincode}
              </div>
            </div>
            <div className="order-product-address-buyers">
              <div className="order-product-address-heading">Order Placed</div>
              <div>{buyerData?.date}</div>
            </div>
            <div className="order-product-address-buyers">
              <div className="order-product-address-heading">
                {" "}
                Seller address
              </div>
              <div>
                Vaidehi Paradkar Raiya Kar Bhavan Nr Times Of India, Ashram
                Road, AHEMEDABAD, GUJARAT, INDIA - 380009
              </div>
            </div>
          </div>

          <div>
            {
              // currentStatus?.statusCode === "OPP" ||
              // currentStatus?.statusCode === "OPG" ||
              currentStatus?.statusCode === "Pending" &&
                !id?.startsWith("QC") && (
                  // currentStatus?.statusCode === "OPD"
                  // currentStatus?.statusCode === "PBC" ||
                  // currentStatus?.statusCode === "PIT" ||
                  // currentStatus?.statusCode === "RDD")

                  <>
                    <FreightCalculator
                      pickupPincode={pickupPincode}
                      setPickupPincode={setPickupPincode}
                      deliveryPincode={deliveryPincode}
                      setDeliveryPincode={setDeliveryPincode}
                      dimensions={dimensions}
                      setDimensions={setDimensions}
                      weight={weight}
                      setWeight={setWeight}
                      onCheckButtonClick={handleCheckButtonClick}
                    />
                    <div className="thirdPart-head">THIRD PARTY (TBD)</div>
                    <table className="thirdPart-table-style">
                      <tr>
                        <th>Provider</th>
                        <th>Price</th>
                      </tr>
                      {costList?.map((comp) => {
                        return (
                          <tr>
                            <td>{comp.companyName}</td>
                            <td>{comp.freightCharge}</td>
                          </tr>
                        );
                      })}
                    </table>
                    <div>
                      <button className="Logistic-check-btn">RESET</button>
                    </div>
                    <div className="logistic-assignment-container">
                      <div className="logistic-assignment-head">
                        LOGISTIC ASSIGNMENT
                      </div>
                      <div>
                        <div className="logistic-assignment-dropdown">
                          <FormControl fullWidth>
                            <Select
                              value={selectedVendor}
                              onChange={handleVendorSelect}
                            >
                              <MenuItem value="Local">Local</MenuItem>
                              <MenuItem value="vendor2">ShipRocket</MenuItem>
                            </Select>
                          </FormControl>
                          {selectedVendor === "Local" && (
                            <TextField
                              value={localOrderLabel}
                              onChange={(e) => {
                                setLocalOrderLabel(e.target.value);
                              }}
                              placeholder="label"
                            />
                          )}
                        </div>
                        <div>
                          <button
                            className="local-common-btn"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )
            }

            {(currentStatus?.statusCode === "OFD" ||
              currentStatus?.statusCode === "DA" ||
              currentStatus?.statusCode === "DF" ||
              currentStatus?.statusCode === "DC") && (
              <div>
                <div className="QualityHeading">Quality Check</div>
                <div className="QualityCheckButtons">
                  <button
                    className="Logistic-check-btn"
                    onClick={() => setQc(true)}
                  >
                    Approve
                  </button>
                  <button
                    className="Logistic-check-btn"
                    onClick={() => setQc(false)}
                  >
                    Reject
                  </button>
                </div>
              </div>
            )}

            {qc === true && currentStatus?.statusCode === "DC" && (
              <div>
                <FreightCalculator
                  pickupPincode={pickupPincode}
                  setPickupPincode={setPickupPincode}
                  deliveryPincode={deliveryPincode}
                  setDeliveryPincode={setDeliveryPincode}
                  dimensions={dimensions}
                  setDimensions={setDimensions}
                  weight={weight}
                  setWeight={setWeight}
                  onCheckButtonClick={handleCheckButtonClick}
                />
                <div className="thirdPart-head">THIRD PARTY (TBD)</div>
                <table className="thirdPart-table-style">
                  <tr>
                    <th>Provider</th>
                    <th>Price</th>
                  </tr>
                  {costList?.map((comp) => {
                    return (
                      <tr>
                        <td>{comp.companyName}</td>
                        <td>{comp.freightCharge}</td>
                      </tr>
                    );
                  })}
                </table>
                <div>
                  <button className="Logistic-check-btn">RESET</button>
                </div>
                <div className="logistic-assignment-container">
                  <div className="logistic-assignment-head">
                    LOGISTIC ASSIGNMENT
                  </div>
                  <div>
                    <div className="logistic-assignment-dropdown">
                      <FormControl fullWidth>
                        <Select
                          value={selectedVendor}
                          onChange={handleVendorSelect}
                        >
                          <MenuItem value="Local">Local</MenuItem>
                          <MenuItem value="vendor2">ShipRocket</MenuItem>
                        </Select>
                      </FormControl>
                      {selectedVendor === "Local" && (
                        <TextField
                          value={localOrderLabel}
                          onChange={(e) => {
                            setLocalOrderLabel(e.target.value);
                          }}
                          placeholder="label"
                        />
                      )}
                    </div>
                    <div>
                      <button
                        className="local-common-btn"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === "QualityCheckReject" && (
              <div>
                <div className="QualityHeading">Quality Check</div>
                <div className="QualityCheckButtons">
                  <button className="Logistic-check-btn">Approve</button>
                  <button className="Logistic-check-btn">Reject</button>
                </div>
                <div>
                  <h3>Reason fr Rejection</h3>
                  <textarea
                    className="textarea-QualityReject"
                    placeholder="Enter Reason for rejection"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="tab-container">
        <div
          className={`tab ${orderStatus === "pending" ? "active" : ""}`}
          onClick={() => handleTabChange("ThirdParty")}
        >
          ThirdParty
        </div>
        <div
          className={`tab ${orderStatus === "in-progress" ? "active" : ""}`}
          onClick={() => handleTabChange("LogisticAssignmnent")}
        >
          LogisticAssignmnent
        </div>
        <div
          className={`tab ${orderStatus === "delivered" ? "active" : ""}`}
          onClick={() => handleTabChange("QualityCheck")}
        >
          QualityCheck
        </div>
      </div>
    </LogisticAdminLayoutAdjustment>
  );
}

export default LogisticsAdminOrderItemDetails;

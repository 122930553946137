import React, { useState } from "react";
import "../styles/CustomerNewMyAccount2.css";
import { Trash, PencilSimple, Plus, XCircle } from "@phosphor-icons/react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import "../styles/CustomerNewCart.css";
function CustomerNewMyAccountAdress() {
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const handleCloseAddress = () => setOpenAddAddress(false);
  const handleOpenAddress = () => setOpenAddAddress(true);

  const [openEditAddress, setEditAddAddress] = useState(false);
  const handleCloseEditAddress = () => setEditAddAddress(false);
  const handleOpenEditAddress = () => setEditAddAddress(true);

  const [opendeletDailog, setdeletDailog] = useState(false);
  const handleClosedeletDailog = () => setdeletDailog(false);
  const handleOpendeletDailog = () => setdeletDailog(true);

  const getDynamicWidth = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      return 200; // Less width for small screens
    } else if (width >= 600 && width <= 900) {
      return 500; // More width for medium screens
    }
    return 600; // Default width for other cases
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: getDynamicWidth(), // Set width dynamically
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [defaultAddress, setDefaultAddress] = useState({
    name: "Tejas",
    type: "Home",
    details: "Thathaguni kanakapura road bangalore south bangalore 560082",
    mobile: "123456789",
  });

  const [otherAddresses, setOtherAddresses] = useState([
    {
      name: "Tejas",
      type: "Work",
      details:
        "Indiranagar bangalore office address where I have my lunch 560092",
      mobile: "123456789",
    },
    {
      name: "Tejas",
      type: "Office",
      details: "Mangalore office address where I have my lunch 560092",
      mobile: "123456789qw234",
    },
  ]);

  const [newAddress, setNewAddress] = useState({
    name: "",
    mobile: "",
    fullAddress: "",
    state: "",
    city: "",
    pinCode: "",
    saveAs: "",
  });

  const handleSetAsDefault = (index) => {
    const newDefault = otherAddresses[index];
    const updatedOtherAddresses = otherAddresses.filter((_, i) => i !== index);
    updatedOtherAddresses.push(defaultAddress);
    setDefaultAddress(newDefault);
    setOtherAddresses(updatedOtherAddresses);
  };

  const handleInputChange = (field, value) => {
    setNewAddress((prev) => ({ ...prev, [field]: value }));
  };

  const validateAddress = (address) => {
    return (
      address.name &&
      address.mobile &&
      address.fullAddress &&
      address.state &&
      address.city &&
      address.pinCode &&
      address.saveAs
    );
  };

  const handleAddAddress = () => {
    if (validateAddress(newAddress)) {
      setOtherAddresses([
        ...otherAddresses,
        {
          name: newAddress.name,
          type: newAddress.saveAs,
          details: `${newAddress.fullAddress}, ${newAddress.city}, ${newAddress.state} - ${newAddress.pinCode}`,
          mobile: newAddress.mobile,
        },
      ]);
      setNewAddress({
        name: "",
        mobile: "",
        fullAddress: "",
        state: "",
        city: "",
        pinCode: "",
        saveAs: "",
      });
      handleCloseAddress();
    }
  };

  return (
    <div className="CustomerNewMyAccountAdresss">
      <div>
        <div className="CustomerNewMyAccountAdresssectionMain-head">
          Default
        </div>
        <div className="CustomerNewMyAccountAdresssectionMain">
          <div className="CustomerNewMyAccountAdress">
            <div className="CustomerNewMyAccountAdress-sec1">
              <div className="CustomerNewMyAccountAdress-sec1-tit">
                {defaultAddress.name}
              </div>
              <div className="CustomerNewMyAccountAdress-sec1-subtit">
                {defaultAddress.type}
              </div>
            </div>
            <div className="CustomerNewMyAccountAdress-sec2">
              <Trash
                style={{ color: "hsla(359, 99%, 67%, 1)", cursor: "pointer" }}
                onClick={handleOpendeletDailog}
              />
              <PencilSimple
                style={{ color: "hsla(359, 99%, 67%, 1)", cursor: "pointer" }}
                onClick={handleOpenEditAddress}
              />
            </div>
          </div>
          <div className="CustomerNewMyAccountAdress-details-section">
            <div>{defaultAddress.details}</div>
            <div>Mobile: {defaultAddress.mobile}</div>
          </div>
        </div>
      </div>

      <div>
        <div className="CustomerNewMyAccountAdresssectionMain-head">
          Other Address
        </div>
        <div className="CustomerNewMyAccountAdress-sec-gap">
          {otherAddresses.map((address, index) => (
            <div key={index} className="CustomerNewMyAccountAdresssectionMain">
              <div className="CustomerNewMyAccountAdress">
                <div className="CustomerNewMyAccountAdress-sec1">
                  <div className="CustomerNewMyAccountAdress-sec1-tit">
                    {address.name}
                  </div>
                </div>
                <div className="CustomerNewMyAccountAdress-sec2">
                  <Trash
                    style={{
                      color: "hsla(359, 99%, 67%, 1)",
                      cursor: "pointer",
                    }}
                    onClick={handleOpendeletDailog}
                  />
                  <PencilSimple
                    style={{
                      color: "hsla(359, 99%, 67%, 1)",
                      cursor: "pointer",
                    }}
                    onClick={handleOpenEditAddress}
                  />
                </div>
              </div>
              <div className="CustomerNewMyAccountAdress-details-section">
                <div>{address.details}</div>
                <div>Mobile: {address.mobile}</div>
              </div>

              <div
                className="CustomerNewMyAccountAdress-setasdefaultsec"
                onClick={() => handleSetAsDefault(index)}
                style={{ cursor: "pointer" }}
              >
                Set As Default
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="CustomerNewMyAccountAdresss-add-adress">
        <Plus
          style={{ color: "hsla(359, 99%, 67%, 1)", cursor: "pointer" }}
          weight="bold"
          onClick={handleOpenAddress}
        />
        <div>Address</div>
      </div>
      <Modal
        open={openAddAddress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Add-shipping-adress-modal-main">
            <div className="Add-shipping-adress-title">Add Address</div>
            <XCircle
              weight="bold"
              style={{ color: "#fe585b", cursor: "pointer" }}
              onClick={() => {
                setNewAddress({
                  name: "",
                  mobile: "",
                  fullAddress: "",
                  state: "",
                  city: "",
                  pinCode: "",
                  saveAs: "",
                });
                handleCloseAddress();
              }}
            />
          </div>
          <div className="Add-shipping-adress-modal">
            <div className="Add-shipping-adress-subtitle">Contact Details</div>
            <TextField
              required
              label="Full Name"
              variant="standard"
              onChange={(e) => handleInputChange("name", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="Enter Mobile Number"
              variant="standard"
              onChange={(e) => handleInputChange("mobile", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
          </div>
          <div className="Add-shipping-adress-modal">
            <div className="Add-shipping-adress-subtitle">Address Details</div>
            <TextField
              required
              label="Full Address"
              variant="standard"
              onChange={(e) => handleInputChange("fullAddress", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="State"
              variant="standard"
              onChange={(e) => handleInputChange("state", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="City"
              variant="standard"
              onChange={(e) => handleInputChange("city", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="PinCode"
              variant="standard"
              onChange={(e) => handleInputChange("pinCode", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="Save as"
              variant="standard"
              onChange={(e) => handleInputChange("saveAs", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
          </div>
          <div className="add-deveilveryadressbutton-sec">
            <div
              className={`add-deveilveryadressbutton ${
                !validateAddress(newAddress) ? "disabled" : ""
              }`}
              onClick={handleAddAddress}
              style={{
                cursor: validateAddress(newAddress) ? "pointer" : "not-allowed",
              }}
            >
              Add Address
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openEditAddress}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Add-shipping-adress-modal-main">
            <div className="Add-shipping-adress-title">Add Address</div>
            <XCircle
              weight="bold"
              style={{ color: "#fe585b", cursor: "pointer" }}
              onClick={handleCloseEditAddress}
            />
          </div>
          <div className="Add-shipping-adress-modal">
            <div className="Add-shipping-adress-subtitle">Contact Details</div>
            <TextField
              required
              label="Full Name"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="Enter Mobile Number"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
          </div>
          <div className="Add-shipping-adress-modal">
            <div className="Add-shipping-adress-subtitle">Address Details</div>
            <TextField
              required
              label="Full Address"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="State"
              variant="standard"
              onChange={(e) => handleInputChange("state", e.target.value)}
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="City"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="PinCode"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <TextField
              required
              label="Save as"
              variant="standard"
              className=""
              sx={{
                "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
              }}
            />
            <div className="CustomerNewMyAccountAdress-saveadress">
              Save Address
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={opendeletDailog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="deletdailog-myaccadressnew">Delete Confirmation</div>
          <div className="cus-myscc-new-del-dai">
            <div className="deletdailog-myaccadressnew-dtl">
              Are you sure you want to delete this address?
            </div>
            <div className="Add-shipping-adress-modal-main">
              <div
                className="cus-myscc-new-del-cancel"
                onClick={handleClosedeletDailog}
              >
                Cancel
              </div>
              <div className="cus-myscc-new-del-delet">Delet</div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CustomerNewMyAccountAdress;

import React from "react";
import img1 from "../Images/cart-img1-new.png";
import "../styles/CustomerNewMyAccount3.css";
import "../styles/CustomerNewCart.css";
import "../styles/CustomerNewMyAccount2.css";

function CusnewOerderCompleted() {
  return (
    <div>
      {" "}
      <div className="customer-new-all-order-details-img-section">
        <div className="CustomerNewCart-subheading-section2">
          <div className="customer-new-all-order-img-details-section-review">
            <div className="cust-new-myacc-img-sec">
              <img
                src={img1}
                alt="product"
                className="CustomerNewCart-subheading-itemlist-img"
              />

              <div className="CustomerNewCart-subheading-itemlist-deatils">
                <div className="CustomerNewCart-subheading-itemlist-deatils-head">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-heading">
                    <div>name : soap</div>
                  </div>
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-price">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price1">
                    ₹ 3000
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price2">
                    ₹ 4000
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price-d">
                    MRP Inclusive of all taxes
                  </div>
                </div>
                <div className="cust-my-acc-wishlist-main-btn-sec">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button1">
                    <img
                      src={img1}
                      alt="color preview"
                      className="CustomerNewCart-subheading-itemlist-deatils-button1-img"
                    />
                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-color-size">
                      Color:red
                    </div>

                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName">
                      Size: xl
                    </div>
                    <div className="CustomerNewCart-size-dropdown-wrapper"></div>
                  </div>
                </div>
                <div className="CustomerNewCart-all-ord-imag-details-btn-sec">
                  <div className="CustomerNewCart-all-ord-imag-details-btn-track">
                    Buy Again
                  </div>
                  <div className="CustomerNewCart-all-ord-imag-details-btn-cancel">
                    Return Order
                  </div>
                </div>
              </div>
            </div>
            <div className="write-a-review-column-cust-new">Review</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CusnewOerderCompleted;

import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import "../styles/CustomerLoginModal.css";
import { IconButton, Switch, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import OtpInput from "react-otp-input";
import { GenderFemale } from "@phosphor-icons/react";
import { GenderMale, GenderNeuter, Info } from "@phosphor-icons/react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
// import { errorAlert, successAlert } from "./Alert";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import ToastComponent from "./ToastComponent";
import ToolTip from "./ToolTip";
import { API_BASE_URL } from "../App";
import "../styles/CustomerLoginModal.css";
import { errorAlert, successAlert } from "./Alert";

const passwordRegexp = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%^&*_(\\)\\+\\-\\=\\[\\]\\\\\\{\\}\\|:;\";'<>?,./`~])(?=.{6,20}$)"
);
const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
// const phoneRegExp = /^[0-9]{10}$/;
const fullNameRegExp = /^(?!.*\\s{2})(?!.*[.'-]{2})[A-Za-z][A-Za-z' ]{0,48}[A-Za-z]$/;
const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const validationSchema = yup.object({
  // firstName: yup
  //   .string("Enter Name")
  //   .required("Name is Required")
  //   .min(1, " Name should be 3 chars atleast")
  //   .max(50, 'Full Name should not exceed 50 characters')
  //   .matches(fullNameRegExp, "Invalid Full Name"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .trim()
    .required("Email is required"),
  // mobileNumber: yup
  //   .string()
  //   .trim()
  //   .matches(phoneRegExp, "Mobile number is not valid")
  //   .required("Mobile number is required")
  //   .min(10, "Mobile number must consist of 10 digits")
  //   .max(10, "Mobile number must consist of 10 digits"),
  password: yup
    .string("Enter your password")
    .trim()
    // .min(8, "Invalid Password")
    // .matches(passwordRegexp, "Invalid Password")
    .required("Password is required"),
  // rePassword: yup
  //   .string("Enter Your Password again")
  //   .oneOf([yup.ref("password"), null], "Passwords must match")
  //   .min(8, "Password should be of minimum 8 characters length")
  //   .required("Password is required"),
  // mobNumber: yup
  //   .string()
  //   .trim()
  //   .matches(phoneRegExp, "Mobile number is not valid")
  //   .required("Mobile number is required")
  //   .min(10, "Mobile number must consist of 10 digits")
  //   .max(10, "Mobile number must consist of 10 digits"),
});

const validationSchemaMobile = yup.object({
  mobNumber: yup
    .string()
    .trim()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Phone number is required")
    .min(10, "Phone number must consist of 10 digits")
    .max(10, "Phone number must consist of 10 digits"),
});

export default function CustomerLoginModal({ isOpen, onClose, ...props }) {
  const {
    setPhoneNumberForOtp,
    accessToken,
    verifyPhoneOtp,
    verifyEmailOtp,
    loginWithOtp,
    loginWithOtpEmail,
    fetchData,
    phoneNumberForOtp,
  } = useContext(CustomerContext);
  const { login } = useContext(CustomerContext);
  const { customerData } = useContext(CustomerContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [error] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);
  const [code, setCode] = useState("");
  const toastRef = React.useRef();
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [invalidForgotOTP, setInvalidForgotOTP] = useState(false);
  //There is a function resendOtp. DOn't get confused.
  const [resendOTP, setResendOTP] = useState(false);
  const {
    emailForVerification,
    phoneForVerification,
    editLayout,
    setEditLayout,
  } = props;

  useEffect(() => {
    if (
      customerData?.result?.userID &&
      props.currentTab !== "phoneNumberForVerificationChange" &&
      props.currentTab !== "emailForVerificationChange" &&
      tab !== "phoneForOtpVerifyForgotPassword" &&
      tab !== "phoneForForgotPasswordUpdate"
    ) {
      onClose();
    }
  });
  const isAuth = () => {
    if (customerData?.result?.userID) {
      return true;
    }
    return false;
  };
  const [tab, setTab] = useState(
    props.currentTab ? props.currentTab : "password"
  );
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const result = await login(values.email, values.password);
      setLoading(false);
      //console.log("first", result, result.status);
      if (result?.result?.accessToken) {
        onClose();
        setInvalidPassword(false);
        setLoading(false);
        toastRef.current.showToast("Login successful", "success");
      } else if (result.status === 400) {
        setLoading(false);
        setInvalidPassword(true);
      } else {
        setLoading(false);
        onClose();
        setInvalidPassword(false);
        setLoading(false);
        toastRef?.current?.showToast(
          "Something went wrong. Please try later",
          "error"
        );
      }
    },
  });
  const formikOTP = useFormik({
    initialValues: {
      mobNumber: "",
    },
    validationSchema: validationSchemaMobile,
    onSubmit: async (values) => {
      setLoading(true);
      setPhoneNumberForOtp(values.mobNumber);
      const response = await checkUserExist(values.mobNumber);
      //console.log(response);
      if (response === "otpSent") {
        await postOtp(values.mobNumber);
        setLoading(false);
        setInvalidPhone(false);
      } else if (response === "otpNotSent") {
        setLoading(false);
        setInvalidPhone(true);
      } else {
        setLoading(false);
        toastRef.current.showToast(
          "Something went wrong. Try again later",
          "error"
        );
        onClose();
      }
    },
  });
  async function postOtp(mobNumber) {
    setLoading(true);
    try {
      let response = await fetch(
        `${
          API_BASE_URL
        }/users/api/v1/otp?mobileNumber=${mobNumber}&userType=${2}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      const result = await response.json();
      //console.log(result);
      //console.log("Message", result.message);
      //console.log("Message", result.result);
      if (result?.result > 0) {
        // successAlert("", "OTP sent Successfully");
        setTab("phoneForOtpVerify");
        setPhoneNumberForOtp(mobNumber.trim());
        setTimeLeft(180);
        setResendLoading(false);
        setCode()
      } else {
        setResendLoading(false);
        setTab("password");
        onClose();
        setResendLoading(false);
        toastRef.current.showToast(
          "Maximum otps sent. contact Admin for more",
          "error"
        );
      }
    } catch (e) {
      setResendLoading(false);
      setCode()

      onClose();
      setLoading(false);
      toastRef.current.showToast(
        "Something went wrong. Try again later",
        "error"
      );
    }
  }
  async function postOtpForRegister(mobNumber) {
    setLoading(true);
    try {
      let response = await fetch(
        `${
          API_BASE_URL
        }/users/api/v1/otp?mobileNumber=${mobNumber}&userType=${2}`,
        {
          headers: {},
        }
      );
      const result = await response.json();
      //console.log(result);
      if (result?.result > 0) {
        // successAlert("", "OTP sent Successfully");
        setLoading(false);
        setTab("phoneForOtpVerifyRegister");
        setPhoneNumberForOtp(mobNumber);
        setEnabled(false);
        setTimeLeft(180);
      } else {
        setTab("password");
        onClose();
        setResendLoading(false);
        toastRef.current.showToast(
          "Maximum otps sent. contact Admin for more",
          "error"
        );
      }
    } catch (e) {
      onClose();
      setResendLoading(false);

      setLoading(false);
      toastRef.current.showToast(
        "Something went wrong. Try again later",
        "error",
        2000
      );
    }
  }
  async function resendOtp() {
    setResendLoading(true);
    try {
      let response = await fetch(
        `${
          API_BASE_URL
        }/users/api/v1/otp?mobileNumber=${phoneNumberForOtp}&userType=${2}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      const result = await response.json();
      //console.log(result);

      if (result?.result > 0) {
        setResendOTP(true);
        setInvalidOTP(false);

        setCode();
        setPhoneNumberForOtp(phoneNumberForOtp.trim());
        setTimeLeft(180);
        setResendLoading(false);
      } else {
        setTab("password");
        onClose();
        setResendLoading(false);
        toastRef.current.showToast(
          "Maximum otps sent. contact Admin for more",
          "error"
        );
      }
    } catch (e) {
      onClose();
      setLoading(false);
      toastRef.current.showToast(
        "Something went wrong. Try again later",
        "error"
      );
      setResendLoading(false);
    }
  }
  async function resendOtpForEmailChange() {
    setLoading(true);
    try {
      let response = await fetch(
        `${
          API_BASE_URL
        }/users/api/v1/otp?email=${emailForVerification}&userType=${2}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      const result = await response.json();
      //console.log(result);
      //console.log("Message", result.message);
      //console.log("Message", result.result);
      if (result?.result > 0) {
        // successAlert("", "OTP Resent");
        // setTab("phoneForOtpVerify")
        // setPhoneNumberForOtp(phoneNumberForOtp.trim());
        setTimeLeft(180);
      }
    } catch (e) {
      onClose();

      // errorAlert("", "Failed to send OTP. Try again.");
      setLoading(false);
    }
  }
  async function resendOtpForPhoneChange() {
    setLoading(true);
    try {
      let response = await fetch(
        `${
          API_BASE_URL
        }/users/api/v1/otp?mobileNumber=${phoneForVerification}&userType=${2}`,
        {
          headers: {
            // Accept: "application.json",
            // "Content-type": "application.json",
          },
        }
      );
      // onClose();
      const result = await response.json();
      //console.log(result);
      //console.log("Message", result.message);
      //console.log("Message", result.result);
      if (result?.result > 0) {
        // successAlert("", "OTP Resent");
        // setTab("phoneForOtpVerify")
        // setPhoneNumberForOtp(phoneNumberForOtp.trim());
        setTimeLeft(180); 
      }
    } catch (e) {
      onClose();

      // errorAlert("", "Failed to send OTP. Try again.");
      setLoading(false);
    }
  }

  useEffect(() => {
    setEnabled(false);
    const timer = setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    if (timeLeft === 0) {
      clearTimeout(timer);
      setEnabled(true);
    }
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const checkUserExist = async (mobNumber) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?mobNumber=${mobNumber}&userTypeId=2`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response.result === true) {
        return "otpSent";
      } else if (response.result === false) {
        return "otpNotSent";
      }
    } catch (e) {
      return false;
    }
  };
  
  const checkUserExistEmail = async (email) => {
    try {
      const data = await fetch(
        `${API_BASE_URL}/users/api/v1/checkUser?email=${email}&userTypeId=2`
      );
      const response = await data.json();
      // //console.log("Check", response)

      if (response.result === true) {
        return true;
      } else if (response.result === false) {
        return false;
      }
    } catch (e) {
      return false;
    }
  };
  async function verifyOtp(mobNumber, code) {
    setLoading(true);
  
    try {
      const response = await loginWithOtp(mobNumber, code);
  
      if (response?.result?.accessToken) {
        // Case 2: Successful login
        setInvalidOTP(false);
        onClose();
        setCode();
        setLoading(false);
        toastRef.current.showToast("Login successful", "success");
        setTab("password");
      } else if (response?.message === "Unsuccessful login - Invalid OTP.") {
        // Case 1: Invalid OTP
        setInvalidOTP(true);
        toastRef.current.showToast("Invalid OTP", "error");
        setLoading(false);
      } else {
        setLoading(false);
        onClose();
        toastRef.current.showToast("Something went wrong. Please try later", "error");
      }
    } catch (error) {
      // Case 3: Network error (500)
      console.error("Network error:", error);
      setLoading(false);
      onClose();
      toastRef.current.showToast("Network error. Please try later", "error");
    }
  }
  async function verifyOtpRegister(mobNumber, code) {
    setLoading(true);
    const response = await verifyPhoneOtp(mobNumber, code);
    setResendOTP(false);
    //console.log(response);
    if (response?.result === true) {
      setInvalidOTP(false);
      onClose();
      //console.log("Verified Phone");
      setCode();
      setLoading(false);
      toastRef.current.showToast("OTP Verified", "success");
      await postData();
      setTab("password");
      await login(formikRegister.values.email, formikRegister.values.password);
    } else if (response?.result) {
      setInvalidOTP(true);
      setLoading(false);
      // toastRef.current.showToast(" went wrong. Please try later", "error");
    } else {
      onClose();
      setTab("password");
      setLoading(false);

      toastRef.current.showToast(
        "Something went wrong. Please try later",
        "error"
      );
    }
  }

  async function verifyOtpForget(mobNumber, code) {
    setLoading(true);
    const userExists = await checkUserExist(mobNumber);
    if (userExists === "otpSent") {
      const response = await loginWithOtp(mobNumber, code);

      setResendOTP(false);

      if (!response?.result?.accessToken) {
        setInvalidForgotOTP(true);
        setLoading(false);
      } else if (response?.result?.accessToken) {
        setInvalidForgotOTP(false);
        // onClose();
        setCode();
        setLoading(false);
        setTab("password");
        // toastRef.current.showToast("OTP Verified", "success");
        setTab("phoneForForgotPasswordUpdate");
      } else {
        onClose();
        setLoading(false);

        toastRef.current.showToast(
          "Something went wrong. Please try later",
          "error"
        );
        return;
      }
    } else {
      onClose();
      setLoading(false);

      toastRef.current.showToast(
        "You don't have an account. Please register",
        "error"
      );
    }
  }
  async function verifyOtpForEmailVerification(email, code) {
   
    setLoading(true);

    const response = await loginWithOtpEmail(email, code);
    console.log(response);
    if (response?.result === true) {
      
      updateEmail(email);

      // setTab('phoneForForgotPasswordUpdate');
      setLoading(false);
      toastRef.current.showToast("Email Updated Successfully", "success");
      setTimeLeft(180);
      onClose();
      return ;
    } else {
      // onClose();
      setTimeLeft(180); 
      setEnabled(false);
      setLoading(false);
      // toastRef.current.showToast("Wrong OTP. Please check", "error");
      // successAlert("", "Login Successful", "success");
    }
  }
  async function verifyOtpForPhoneVerification(phone, code) {
    setLoading(true);

    const response = await verifyPhoneOtp(phone, code);
    //console.log(response);
    if (response?.result === true) {
     
      updatePhone(phone);
      setLoading(false);
      toastRef.current.showToast("PhoneNumber Updated Successfully", "success");
      setTimeLeft(180);
      onClose();
    } else {
      // onClose();
      // updatePhone(phone);
      // setTab('phoneForForgotPasswordUpdate');
      setLoading(false);
      setEnabled(false);
      setTimeLeft(180); 

      // toastRef.current.showToast("Wrong OTP. Please check", "error");
      // successAlert("", "Login Successful", "success");
    }
  }

  async function updateEmail(email) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userID: customerData?.result?.userID,
            email: email,
          }),
        }
      );

      if (response.ok) {
        // successAlert("Email updated successfully");
        fetchData(accessToken);
        setTab("password");
        
        toastRef.current.showToast("Email Updated Successfully", "success");
        onClose();
        return true;
      } else {
        // errorAlert("Password update failed")
        toastRef.current.showToast("Update failed try again", "error");
        onClose();
        return false;
      }
    } catch (error) {
      console.error("Error updating password:", error);
      return false;
    }
  }
  async function updatePhone(phone) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userID: customerData?.result?.userID,
            mobileNumber: phone,
          }),
        }
      );

      if (response.ok) {
        // successAlert("Email updated successfully");
        toastRef.current.showToast("PhoneNumber Updated Successfully", "success");
        fetchData(accessToken);
        setTab("password");
        onClose();
        return true;
      } else {
        // errorAlert("Password update failed")
        toastRef.current.showToast("Failed to update Try again", "error");
        onClose();
        return false;
      }
    } catch (error) {
      console.error("Error updating password:", error);
      return false;
    }
  }

  const handleChange = (code) => setCode(code);

  const validationSchemaRegister = yup.object({
    email: yup
      .string("Enter your email")
      .trim()
      .email("Enter a valid email")
      .required("Email is required *"),
    password: yup
      .string("Enter your password")
      .trim()
      .min(8, "Password must have at least 8 characters, with upper & lowercase letters, a number, and a special character.")
      .matches(passwordRegexp, "Password must have at least 8 characters, with upper & lowercase letters, a number, and a special character.")
      .required("Password is required*"),
    mobNumber: yup
      .string()
      .trim()
      .matches(phoneRegExp, "Mobile number is not valid")
      .required("Mobile number is required*")
      .min(10, "Mobile number must consist of 10 digits")
      .max(10, "Mobile number must consist of 10 digits"),
    firstName: yup
      .string("Enter Name")
      .trim()
      .required(" Name is Required*")
      .min(1, " Name should be 1 chars atleast")
      .max(50, "Full Name should not exceed 50 characters")
      .matches(fullNameRegExp, "Invalid Full Name"),
  });
  const formikRegister = useFormik({
    initialValues: {
      password: "",
      mobNumber: "",
      email: "",
      firstName: "",
      gender: "",
    },
    validationSchema: validationSchemaRegister,
    onSubmit: async () => {
      setLoading(true);
      const userExists = await checkUserExist(formikRegister.values.mobNumber.trim());
      const userExistEmail = await checkUserExistEmail(formikRegister.values.email);
      if (userExists !== 'otpSent' && !userExistEmail) {
        await postOtpForRegister(formikRegister.values.mobNumber.trim());
        setLoading(false)
      } else {
        onClose();
        setLoading(false);
        toastRef.current.showToast("User Already exists", "error");
      }
    },
  });
  async function postData() {
    try {

      const response = await fetch(`${API_BASE_URL}/users/api/v1/registration`, {
        method: "POST",
        headers: {
          // Accept: "text/plain",
          "Content-type": "application/json-patch+json",
        },
        body: JSON.stringify({
          email: formikRegister.values.email,
          password: formikRegister.values.password,
          mobileNumber: formikRegister.values.mobNumber.trim(),
          firstName: formikRegister.values.firstName,
          gender: selectedGenderValue,
          typeOfUserId: 2,
          dateOfBirth: ''
        }),
      });
      const data = await response.json();
      //console.log(data);
      setLoading(false);

      // return data;
    } catch (e) {
      setLoading(false);
    }
  }

  const validationSchemaForgotPassword = yup.object({
    mobNumber: yup
      .string()
      .trim()
      .matches(phoneRegExp, "Mobile number is not valid")
      .required("Mobile number is required*")
      .min(10, "Mobile number must consist of 10 digits")
      .max(10, "Mobile number must consist of 10 digits"),
  });
  const formikForgot = useFormik({
    initialValues: {
      mobNumber: "",
    },
    validationSchema: validationSchemaForgotPassword,
    onSubmit: async () => {
      setLoading(true);
      setInvalidPhone(false);
      const userExists = await checkUserExist(formikForgot.values.mobNumber);
      setLoading(false);
      if (userExists === "otpSent") {
        await postOtp(formikForgot.values.mobNumber);
        setTab("phoneForOtpVerifyForgotPassword");
        setPhoneNumberForOtp(formikForgot.values.mobNumber);
        setEnabled(false);
        setLoading(false);
      } else if (userExists === "otpNotSent") {
        setInvalidPhone(true);
        // errorAlert('User does not exist');
      } else {
        onClose();
        setLoading(false);

        toastRef.current.showToast(
          "Something Went Wrong. Please try later.",
          "error"
        );
      }
    },
  });

  const passwordRegexp2 = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const validationSchemaPasswordUpdate = yup.object({
    password: yup
      .string("Enter your password")
      .trim()
      .matches(passwordRegexp2, "Password is not valid")
      .min(8, "Password should contain 8 characters ")
      .required("Password is required"),
    rePassword: yup
      .string("Enter Your Password again")
      .trim()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .min(8, "Password should contain 8 characters")
      .required("Password is required"),
  });
  const formikPasswordUpdate = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: validationSchemaPasswordUpdate,
    onSubmit: async () => {
      await updatePassword();
    },
  });
  async function updatePassword() {
    const userID = customerData?.result?.userID;
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userID: userID,
            password: formikPasswordUpdate.values.password,
          }),
        }
      );

      if (response.ok) {
        setLoading(false);
        toastRef.current.showToast("Password Updated Successfully", "success");
        setTab("password");
        onClose();
        return true;
      } else {
        setLoading(false);

        // errorAlert("Password update failed")
        toastRef.current.showToast("Password update failed", "error");

        return false;
      }
    } catch (error) {
      toastRef.current.showToast("Password Update failed", "error");
      return false;
    }
  }
  const [showPassword, setShowPassword] = useState(false);
  const [passwordEntered, setPasswordEntered] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [selectedGenderValue, setSelectedGenderValue] = useState("");

  const handleGenderChange = (event) => {
    setSelectedGenderValue(event.target.value);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // useEffect(() => {
  //   if (tab === "phoneForOtpVerify" && code.length === 4) {
  //     verifyOtp(formikOTP.values.mobNumber, code);
  //   }
  // },[code]);
  // useEffect(() => {
  //   if (tab === "phoneForOtpVerify") {
  //     if (code?.length === 4) {
  //       verifyOtp(formikOTP?.values?.mobNumber, code);
  //       setInvalidOTP(false);
  //     } else if (code.length === 0) {
  //       setInvalidOTP(false);
  //     }
  //   }
  // }, [code, tab]);

  return (
    <React.Fragment>
      {/* <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        Open modal
      </Button> */}

      <ToastComponent ref={toastRef} timeout={4000} />

      <Modal open={isOpen} onClose={onClose}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("sm")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
            },
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
            },
            // padding: '24px',
            borderRadius: 16,
          })}
        >
          <Box
            sx={{
              mt: 1,
              display: "flex",
              gap: 1,
              margin:'1rem auto',
              width: { xs: "330px", md: tab === "signup" ? "912px" : "550px" },
              flexDirection: { xs: "column", sm: "column" },
              alignItems: "center",
              justifyContent: "space-center",
            }}
          >
            <div>
              {!isAuth() && tab === "password" && (
                <div>
                  <div className="customer-login-modal-login">LOGIN</div>
                  {invalidPassword && (
                    <div
                      style={{
                        backgroundColor: "rgba(249, 0, 0, 0.13)",
                        color: "#FF2121",
                        padding: "8px 24px",
                        width: "60%",
                        margin: "0 auto",
                        fontSize: "12px",
                        borderRadius: "10px",
                        textAlign: "center",
                        position: "relative",
                        bottom: "15px",
                      }}
                    >
                      Invalid Email or Password
                    </div>
                  )}
                </div>
              )}
              {!isAuth() && tab === "phoneForOtp" && (
                <div>
                  <div className="customer-login-modal-login">LOGIN</div>
                  {invalidPhone && (
                    <div
                      style={{
                        backgroundColor: "rgba(249, 0, 0, 0.13)",
                        color: "#FF2121",
                        padding: "8px 24px 8px 24px",
                        width: "60%",
                        margin: "0 auto",
                        fontSize: "12px",
                        borderRadius: "10px",
                        textAlign: "center",
                        position: "relative",
                        bottom: "15px",
                      }}
                    >
                      Phone number does not exist
                    </div>
                  )}
                </div>
              )}
              {!isAuth() && tab === "signup" && (
                <div className="customer-login-modal-login">SIGN UP</div>
              )}
              {!isAuth() && tab === "phoneForOtpVerify" && (
                <div>
                  <div className="customer-login-modal-login">
                    We sent you an OTP
                  </div>
                  {/* {invalidOTP && <div style={{ color: '#FF2121', padding: '8px 24px 8px 24px', width: '30%', margin: '0 auto', fontSize: '12px', borderRadius: '10px', textAlign: 'center', position: 'relative', bottom: '15px' }} >Invalid OTP</div>} */}
                  {resendOTP && (
                    <div
                      style={{
                        backgroundColor: "rgba(2, 159, 74, 0.26)",
                        color: "#029F4A",
                        padding: "8px 24px 8px 24px",
                        width: "30%",
                        margin: "0 auto",
                        fontSize: "12px",
                        borderRadius: "10px",
                        textAlign: "center",
                        position: "relative",
                        bottom: "15px",
                      }}
                    >
                      {" "}
                      OTP has been Resent
                    </div>
                  )}
                </div>
              )}
              {!isAuth() && tab === "forgotPassword" && (
                <div>
                  <div className="customer-login-modal-login">
                    Password assistance
                  </div>
                  {invalidPhone && (
                    <div
                      style={{
                        backgroundColor: "rgba(249, 0, 0, 0.13)",
                        color: "#FF2121",
                        padding: "8px 24px 8px 24px",
                        margin: "0 auto",
                        fontSize: "12px",
                        borderRadius: "10px",
                        textAlign: "center",
                        position: "relative",
                        bottom: "15px",
                      }}
                    >
                      Phone number does not exist
                    </div>
                  )}
                </div>
              )}
              {tab === "phoneForForgotPasswordUpdate" && (
                <div className="customer-login-modal-login">
                  Create new password
                </div>
              )}

              {!isAuth() && tab === "password" && (
                <form
                  className="customer-login-modal-container"
                  onSubmit={(e) => {
                    e.preventDefault();
                    //console.log("Clicked otp btn");
                    formik.handleSubmit();
                  }}
                >
                  <div className="customer-login-modal-field-name">
                    Email Address
                  </div>
                  <TextField
                    style={{ marginBottom: "16px" }}
                    className="textfield-login-modal-fields"
                    autoFocus
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                  <div className="customer-login-modal-field-name">
                    Password
                  </div>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    style={{ marginBottom: "16px" }}
                    className="textfield-login-modal-fields"
                    // autoFocus
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: formik.values.password ? (
                        <IconButton onClick={handleTogglePassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ) : null,
                    }}
                  />

                  <Link
                    className="customer-login-forgot-password"
                    onClick={() => {
                      setTab("forgotPassword");
                    }}
                  >
                    Forgot Password
                  </Link>
                  {!loading && (
                    <Button
                      className="customer-login-btn"
                      fullWidth
                      type="submit"
                    >
                      LOGIN
                    </Button>
                  )}
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "pink" }} />
                    </div>
                  )}

                  <div className="customer-login-modal-field-divider">Or</div>
                  <Button
                    className="customer-login-sec-button"
                    fullWidth
                    variant="outlined"
                    onClick={() => {
                      setTab("phoneForOtp");
                    }}
                  >
                    LOGIN WITH OTP{" "}
                  </Button>
                  <div className="customer-login-modal-field-signup">
                    Not a Member?{" "}
                    <div
                      onClick={() => {
                        setTab("signup");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                    >
                      Create an account
                    </div>
                  </div>
                  <div className="customer-login-modal-field-terms">
                    By continuing, you agree that you have read and accept our{" "}
                    <Link
                    to="/termsAndCondition"
                      className="register-privacy-link"
                    >

                      Terms & Condition
                    </Link>{" "}
                    and{" "}
                    <Link to="/buyerPrivacyPolicy" className="register-privacy-link">
                      Privacy Policy
                    </Link>
                  </div>
                </form>
                

                
              )}
              {!isAuth() && tab === "phoneForOtp" && (
                <div>
                  <form
                    className="customer-login-modal-container"
                    onSubmit={formikOTP.handleSubmit}
                  >
                    <div className="customer-login-modal-field-name">
                      Mobile Number
                    </div>
                    <TextField
                      style={{ marginBottom: "16px" }}
                      className="textfield-login-modal-fields"
                      // error helperText={'Test'}
                      name="mobNumber"
                      placeholder="Enter Mobile Number"
                      value={formikOTP.values.mobNumber}
                      onChange={formikOTP.handleChange}
                      error={
                        formikOTP.touched.mobNumber &&
                        Boolean(formikOTP.errors.mobNumber)
                      }
                      helperText={
                        formikOTP.touched.mobNumber &&
                        formikOTP.errors.mobNumber
                      }
                    />
                    {!loading && (
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="submit"
                      >
                        Get OTP
                      </Button>
                    )}
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}

                    <div className="customer-login-modal-field-divider">Or</div>
                    <Button
                      className="customer-login-sec-button"
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        setTab("password");
                      }}
                    >
                      Login with Password{" "}
                    </Button>
                    <div className="customer-login-modal-field-signup">
                      Not a Member?{" "}
                      <div
                        onClick={() => {
                          setTab("signup");
                        }}
                        className="customer-login-signup-button"
                        variant="plain"
                      >
                        Create an account
                      </div>
                    </div>

                    <div className="customer-login-modal-field-terms">
                      By continuing, you agree that you have read and accept our{" "}
                      <Link
                        to="termsAndCondition"
                        className="register-privacy-link"
                      >
                        Terms & Condition
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="privacyPolicy"
                        className="register-privacy-link"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div
                      onClick={() => {
                        setTab("password");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                      type="button"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Back to Login
                    </div>
                  </form>
                </div>
              )}
              {!isAuth() && tab === "phoneForOtpVerify" && (
                <div className="customer-signup-modal-container">
                  <form
                    className="customer-login-modal-container"
                    onSubmit={formikOTP.handleSubmit}
                  >
                    {/* <div className="customer-login-modal-login">
                        WE SENT YOU AN OTP
                      </div> */}
                    <p className="para-otp-verify">
                      Please enter the 4 digits number to verify{" "}
                    </p>
                    <div className="otp-numbers-Buyers">
                      <OtpInput
                        value={code}
                        onChange={handleChange}
                        numInputs={4}
                        //   separator={<span style={{ width: "8px" }}></span>}

                        isInputNum={true}
                        shouldAutoFocus={true}
                        className="otp-verify-otp-verify"
                        autoFocus={true}
                        inputStyle={{
                          border: "1px solid rgba(0, 0, 0, 1)",
                          width: "2.5rem",
                          height: "1rem",
                          padding: "20px 0px 20px 0px",
                          borderRadius: "8px",
                          fontSize: "16px",
                          color: "rgba(0, 0, 0, 1)",
                          marginTop: "20%",
                          display: "flex",
                          justifyContent: "center",
                          margin: "0 auto",

                          //   fontWeight: "400",
                          // caretColor: "blue"
                        }}
                        focusStyle={{
                          border: "1px solid rgba(2, 159, 74, 1)",
                          padding: "20px 0px 20px 0px",
                          borderRadius: "8px",
                          outline: "none",
                        }}
                      />
                    </div>
                    {invalidOTP && (
                      <div className="otp-verify-error-buyers">Invalid OTP</div>
                    )}
                    <div className="otp-verify-resend-otp">
                      <div>
                        <span>Didn't receive the OTP?</span>
                      </div>
                      {!enabled && (
                        <div
                          className="otp-verify-resend-otp-button"
                          style={{ color: "black" }}
                          // onClick={resendOtp}
                        >
                          Resend
                        </div>
                      )}
                      {enabled && !resendLoading && (
                        <div
                          className="otp-verify-resend-otp-button"
                          type="button"
                          onClick={resendOtp}
                        >
                          Resend OTP
                        </div>
                      )}
                      {enabled && resendLoading && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress style={{ color: "pink" }} />
                        </div>
                      )}

                      <div>{timeLeft !== 0 && <div>{timeLeft}</div>}</div>
                    </div>
                    {!loading && (
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="button"
                        onClick={() => { verifyOtp(formikOTP.values.mobNumber, code) }}
                      >
                        CONTINUE
                      </Button>
                    )}
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        setTab("password");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                      type="button"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: "20px",
                      }}
                    >
                      Back to Login
                    </div>
                  </form>
                </div>
              )}
              {!isAuth() && tab === "phoneForOtpVerifyRegister" && (
                <div>
                  <div className="customer-signup-modal-container">
                    <form className="customer-login-modal-container">
                      <div className="customer-login-modal-login">
                        WE SENT YOU AN OTP
                      </div>
                      <p>
                        Please enter the 4 digits number to verify your number
                      </p>
                      {invalidOTP && (
                        <div
                          style={{
                            backgroundColor: "lightpink",
                            color: "maroon",
                            padding: "8px",
                            width: "60%",
                            margin: "0 auto",
                            fontSize: "12px",
                            borderRadius: "10px",
                            textAlign: "center",
                            position: "relative",
                            bottom: "15px",
                          }}
                        >
                          Invalid OTP
                        </div>
                      )}
                      {resendOTP && (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: "8px",
                            width: "60%",
                            margin: "0 auto",
                            fontSize: "12px",
                            borderRadius: "10px",
                            textAlign: "center",
                            position: "relative",
                            bottom: "15px",
                          }}
                        >
                          {" "}
                          OTP has been Resent
                        </div>
                      )}

                      <div className="otp-numbers-Buyers">
                        <OtpInput
                          value={code}
                          onChange={handleChange}
                          numInputs={4}
                          //   separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          autoFocus={true}
                          className="otp-verify-otp-verify"
                          inputStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            width: "2.5rem",
                            height: "1rem",
                            padding: "20px 0px 20px 0px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 1)",
                            marginTop: "20%",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",

                            //   fontWeight: "400",
                            // caretColor: "blue"
                          }}
                          focusStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            outline: "none",
                          }}
                        />
                      </div>
                      {error && (
                        <div className="otp-verify-error">
                          Please enter a valid OTP
                        </div>
                      )}
                      <div className="otp-verify-resend-otp">
                        <div>
                          <span>Didn't receive the OTP?</span>
                        </div>
                        {!enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            style={{ color: "black" }}
                          >
                            Resend
                          </div>
                        )}
                        {enabled && !resendLoading && (
                          <div
                            className="otp-verify-resend-otp-button"
                            type="button"
                            onClick={resendOtp}
                          >
                            Resend OTP
                          </div>
                        )}
                        {enabled && resendLoading && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress style={{ color: "pink" }} />
                          </div>
                        )}

                        <div>{timeLeft !== 0 && <div>{timeLeft}</div>}</div>
                      </div>
                      {!loading && (
                        <Button
                          className="customer-login-btn"
                          fullWidth
                          type="button"
                          onClick={() => {
                            verifyOtpRegister(
                              formikRegister.values.mobNumber,
                              code
                            );
                          }}
                        >
                          CONTINUE
                        </Button>
                      )}
                      {loading && (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress style={{ color: "pink" }} />
                        </div>
                      )}

                      <div
                        onClick={() => {
                          setTab("password");
                        }}
                        className="customer-login-signup-button"
                        variant="plain"
                        type="button"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "15px",
                        }}
                      >
                        Back to Login
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {tab === "forgotPassword" && (
                <div>
                  <form
                    className="customer-login-modal-container"
                    onSubmit={formikForgot.handleSubmit}
                  >
                    <div className="forgot-otp-content">
                      Enter the mobile phone number associated with your account
                    </div>
                    <div className="customer-login-modal-field-name">
                      Mobile Number
                    </div>
                    <TextField
                      style={{ marginBottom: "16px" }}
                      className="textfield-login-modal-fields"
                      name="mobNumber"
                      placeholder="Enter Mobile Number"
                      value={formikForgot.values.mobNumber}
                      onChange={formikForgot.handleChange}
                      error={
                        formikForgot.touched.mobNumber &&
                        Boolean(formikForgot.errors.mobNumber)
                      }
                      helperText={
                        formikForgot.touched.mobNumber &&
                        formikForgot.errors.mobNumber
                      }
                    />
                    {!loading && (
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="submit"
                      >
                        Get OTP
                      </Button>
                    )}
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}

                    <div className="customer-login-modal-field-terms">
                      By continuing, you agree that you have read and accept our{" "}
                      <Link
                        to="termsAndCondition"
                        className="register-privacy-link"
                      >
                        Terms & Condition
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="privacyPolicy"
                        className="register-privacy-link"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div
                      onClick={() => {
                        setTab("password");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                      type="button"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      Back to Login
                    </div>
                  </form>
                </div>
              )}
              {tab === "phoneForOtpVerifyForgotPassword" && (
                <div>
                  <div className="customer-signup-modal-container">
                    <form className="customer-login-modal-container">
                      <div className="customer-login-modal-login">
                        WE SENT YOU AN OTP
                      </div>
                      {invalidForgotOTP && (
                        <div
                          style={{
                            backgroundColor: "lightpink",
                            color: "maroon",
                            padding: "8px 24px 8px 24px",
                            width: "60%",
                            margin: "0 auto",
                            fontSize: "12px",
                            borderRadius: "10px",
                            textAlign: "center",
                            position: "relative",
                            bottom: "15px",
                          }}
                        >
                          Invalid OTP
                        </div>
                      )}
                      {resendOTP && (
                        <div
                          style={{
                            backgroundColor: "#0080009e",
                            color: "white",
                            padding: "8px 24px 8px 24px",
                            width: "60%",
                            margin: "0 auto",
                            fontSize: "12px",
                            borderRadius: "10px",
                            textAlign: "center",
                            position: "relative",
                            bottom: "15px",
                          }}
                        >
                          {" "}
                          OTP has been Resent
                        </div>
                      )}

                      <p className="para-otp-verify">
                        Please enter the 4 digits number to verify
                      </p>
                      <div className="otp-numbers-Buyers">
                        <OtpInput
                          value={code}
                          onChange={handleChange}
                          numInputs={4}
                          //   separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          autoFocus={true}
                          className="otp-verify-otp-verify"
                          inputStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            width: "2.5rem",
                            height: "1rem",
                            padding: "20px 0px 20px 0px",
                            borderRadius: "8px",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 1)",
                            marginTop: "20%",
                            display: "flex",
                            justifyContent: "center", 
                            margin: "0 auto",

                            //   fontWeight: "400",
                            // caretColor: "blue"
                          }}
                          focusStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            outline: "none",
                          }}
                        />
                      </div>
                      {error && (
                        <div className="otp-verify-error">
                          Please enter a valid OTP
                        </div>
                      )}
                      <div className="otp-verify-resend-otp">
                        <div>
                          <span>Didn't receive the OTP?</span>
                        </div>
                        {!enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            style={{ color: "black" }}
                          >
                            Resend
                          </div>
                        )}
                        {enabled && !resendLoading && (
                          <div
                            className="otp-verify-resend-otp-button"
                            type="button"
                            onClick={resendOtp}
                          >
                            Resend OTP
                          </div>
                        )}
                        {enabled && resendLoading && (
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <CircularProgress style={{ color: "pink" }} />
                          </div>
                        )}

                        <div>{timeLeft !== 0 && <div>{timeLeft}</div>}</div>
                      </div>
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="button"
                        onClick={() => {
                          verifyOtpForget(formikForgot.values.mobNumber, code);
                        }}
                      >
                        CONTINUE
                      </Button>
                      <div
                        onClick={() => {
                          setTab("password");
                        }}
                        className="customer-login-signup-button"
                        variant="plain"
                        type="button"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          marginTop: "15px",
                        }}
                      >
                        Back to Login
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {!isAuth() && tab === "signup" && (
                <form
                  className="customer-signup-modal-container"
                  onSubmit={formikRegister.handleSubmit}
                >
                  <div className="customer-signup-modal-row">
                    <div>
                      <div className="customer-login-modal-field-name">
                        Full Name *
                      </div>
                      <TextField
                        style={{ marginBottom: "16px" }}
                        className="textfield-login-modal-fields"
                        name="firstName"
                        value={formikRegister.values.firstName}
                        onChange={formikRegister.handleChange}
                        error={
                          formikRegister.touched.firstName &&
                          Boolean(formikRegister.errors.firstName)
                        }
                        helperText={
                          formikRegister.touched.firstName &&
                          formikRegister.errors.firstName
                        }
                      />
                    </div>
                    <div>
                      <div className="customer-login-modal-field-name">
                        Mobile Number *
                      </div>
                      <TextField
                        style={{ marginBottom: "16px" }}
                        className="textfield-login-modal-fields"
                        name="mobNumber"
                        value={formikRegister.values.mobNumber}
                        onChange={formikRegister.handleChange}
                        error={
                          formikRegister.touched.mobNumber &&
                          Boolean(formikRegister.errors.mobNumber)
                        }
                        helperText={
                          formikRegister.touched.mobNumber &&
                          formikRegister.errors.mobNumber
                        }
                      />
                    </div>
                  </div>
                  <div className="customer-signup-modal-row">
                    <div>
                      <div className="customer-login-modal-field-name">
                        Email *
                      </div>
                      <TextField
                        style={{ marginBottom: "16px" }}
                        className="textfield-login-modal-fields"
                        name="email"
                        value={formikRegister.values.email}
                        onChange={formikRegister.handleChange}
                        error={
                          formikRegister.touched.email &&
                          Boolean(formikRegister.errors.email)
                        }
                        helperText={
                          formikRegister.touched.email &&
                          formikRegister.errors.email
                        }
                      />
                    </div>
                    <div>
                      <div className="customer-login-modal-field-name">
                        <div> Password</div>{" "}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "4px",
                            cursor: "pointer",
                          }}
                        >
                          <ToolTip />
                        </div>
                      </div>
                      <TextField
                        style={{ marginBottom: "16px" }}
                        className="textfield-login-modal-fields"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formikRegister.values.password}
                        onChange={formikRegister.handleChange}
                        error={
                          formikRegister.touched.password &&
                          Boolean(formikRegister.errors.password)
                        }
                        helperText={
                          formikRegister.touched.password &&
                          formikRegister.errors.password
                        }
                        InputProps={{
                          endAdornment: formikRegister.values.password ? (
                            <IconButton
                              onClick={handleTogglePassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          ) : null,
                        }}
                      />
                    </div>
                  </div>
                  <div className="customer-signup-modal-row-gender">
                    <div>
                      <div className="customer-login-modal-field-name">
                        Gender
                      </div>
                      <FormControl
                        style={{ marginLeft: "0.5rem", gap: "16px" }}
                        className="form-gender-fields"
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={selectedGenderValue}
                          onChange={handleGenderChange}
                        >
                          <FormControlLabel
                            value="f"
                            sx={{ marginBottom: { xs: "16px", md: "none" } }}
                            control={<Radio style={{ color: "#FD2A80" }} />}
                            label={
                              <span
                                style={{
                                  borderRadius: "8px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  padding: "0.5rem 1rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <GenderFemale
                                  style={{
                                    color: "#000",
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />{" "}
                                Female
                              </span>
                            }
                          />

                          <FormControlLabel
                            value="m"
                            sx={{ marginBottom: { xs: "16px", md: "none" } }}
                            control={<Radio style={{ color: "#FD2A80" }} />}
                            label={
                              <span
                                style={{
                                  borderRadius: "8px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  padding: "0.5rem 1rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <GenderMale
                                  style={{
                                    color: "#000",
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />{" "}
                                Male
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="o"
                            sx={{ marginBottom: { xs: "16px", md: "none" } }}
                            control={<Radio style={{ color: "#FD2A80" }} />}
                            label={
                              <span
                                style={{
                                  borderRadius: "8px",
                                  border: "1px solid rgba(0, 0, 0, 0.15)",
                                  padding: "0.5rem 1rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <GenderNeuter
                                  style={{
                                    color: "#000",
                                    width: "22px",
                                    height: "22px",
                                  }}
                                />{" "}
                                Other
                              </span>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  {!loading && (
                    <Button
                      className="customer-signup-btn"
                      fullWidth
                      type="submit"
                    >
                      GET OTP
                    </Button>
                  )}
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress style={{ color: "pink" }} />
                    </div>
                  )}

                  <div className="customer-signup-modal-field-terms-button">
                    <div className="customer-signup-modal-field-terms">
                      By continuing, you agree that you have read and accept our{" "}
                      <Link
                        to="termsAndCondition"
                        className="register-privacy-link"
                      >
                        Terms & Condition
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="privacyPolicy"
                        className="register-privacy-link"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div className="customer-signup-modal-field-login">
                      Already a member?{" "}
                      <div
                        onClick={() => {
                          setTab("password");
                        }}
                        className="customer-login-signup-button"
                        variant="plain"
                        type="button"
                      >
                        Login
                      </div>
                    </div>
                  </div>
                </form>
              )}
              {tab === "phoneForForgotPasswordUpdate" && (
                <>
                  <div className="forgot-otp-content">
                    We’ll ask for this password whenevr you sign in.
                  </div>
                  <form
                    className="customer-login-modal-container"
                    onSubmit={formikPasswordUpdate.handleSubmit}
                  >
                    <div className="customer-login-modal-field-name">
                      New Password
                    </div>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      style={{ marginBottom: "16px" }}
                      className="textfield-login-modal-fields"
                      name="password"
                      value={formikPasswordUpdate.values.password}
                      onChange={formikPasswordUpdate.handleChange}
                      error={
                        formikPasswordUpdate.touched.password &&
                        Boolean(formikPasswordUpdate.errors.password)
                      }
                      helperText={
                        formikPasswordUpdate.touched.password &&
                        formikPasswordUpdate.errors.password
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formikPasswordUpdate.values.password && (
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="customer-login-modal-field-name">
                      Password Again
                    </div>
                    <TextField
                      type={showPassword ? "text" : "password"}
                      style={{ marginBottom: "16px" }}
                      className="textfield-login-modal-fields"
                      name="rePassword"
                      value={formikPasswordUpdate.values.rePassword}
                      onChange={formikPasswordUpdate.handleChange}
                      error={
                        formikPasswordUpdate.touched.rePassword &&
                        Boolean(formikPasswordUpdate.errors.rePassword)
                      }
                      helperText={
                        formikPasswordUpdate.touched.rePassword &&
                        formikPasswordUpdate.errors.rePassword
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {formikPasswordUpdate.values.rePassword && (
                              <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!loading && (
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="submit"
                        // onClick={formikPasswordUpdate.handleSubmit}
                      >
                        Save changes
                      </Button>
                    )}
                    {loading && (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    )}

                    <div className="customer-login-modal-field-terms">
                      By continuing, you agree that you have read and accept our
                      <Link
                        to="termsAndCondition"
                        className="register-privacy-link"
                      >
                        Terms & Condition
                      </Link>{" "}
                      and{" "}
                      <Link
                        to="privacyPolicy"
                        className="register-privacy-link"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                    <div
                      onClick={() => {
                        setTab("password");
                      }}
                      className="customer-login-signup-button"
                      variant="plain"
                      type="button"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        marginTop: "15px",
                      }}
                    >
                      Back to Login
                    </div>
                  </form>
                </>
              )}

              {props.currentTab === "emailForVerificationChange" && (
                <div>
                  <div className="customer-signup-modal-container">
                    <form className="customer-login-modal-container">
                      <div className="customer-login-modal-login">
                        WE SENT YOU AN OTP to your email
                      </div>
                      <p>
                        Please enter the 4 digits number to verify your Identity
                      </p>
                      <div className="otp-numbers-Buyers">
                        <OtpInput
                          value={code}
                          onChange={handleChange}
                          numInputs={4}
                          //   separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          className="otp-verify-otp-verify"
                          inputStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            width: "2rem",
                            height: "2rem",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 1)",
                            marginTop: "20%",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",

                            //   fontWeight: "400",
                            // caretColor: "blue"
                          }}
                          focusStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            outline: "none",
                          }}
                        />
                      </div>
                      {error && (
                        <div className="otp-verify-error">
                          Please enter a valid OTP
                        </div>
                      )}
                      <div className="otp-verify-resend-otp">
                        <div>
                          <span>Didn't receive the OTP?</span>
                        </div>
                        {!enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            style={{ color: "black" }}
                          >
                            Resend
                          </div>
                        )}
                        {enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            type="button"
                            onClick={resendOtpForEmailChange}
                          >
                            Resend OTP
                          </div>
                        )}

                        <div>{timeLeft !== 0 && <div>{timeLeft}</div>}</div>
                      </div>
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="button"
                        onClick={() => {
                          verifyOtpForEmailVerification(
                            emailForVerification,
                            code
                          );
                        }}
                      >
                        CONTINUE
                      </Button>
                    </form>
                  </div>
                </div>
              )}
              {props.currentTab === "phoneNumberForVerificationChange" && (
                <div>
                  <div className="customer-signup-modal-container">
                    <form className="customer-login-modal-container">
                      <div className="customer-login-modal-login">
                        WE SENT YOU AN OTP to your phone
                      </div>
                      <p>
                        Please enter the 4 digits number to verify your Identity
                      </p>
                      <div className="otp-numbers-Buyers">
                        <OtpInput
                          value={code}
                          onChange={handleChange}
                          numInputs={4}
                          //   separator={<span style={{ width: "8px" }}></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          className="otp-verify-otp-verify"
                          inputStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            width: "2rem",
                            height: "2rem",
                            fontSize: "16px",
                            color: "rgba(0, 0, 0, 1)",
                            marginTop: "20%",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",

                            //   fontWeight: "400",
                            // caretColor: "blue"
                          }}
                          focusStyle={{
                            border: "1px solid rgba(0, 0, 0, 1)",
                            outline: "none",
                          }}
                        />
                      </div>
                      {error && (
                        <div className="otp-verify-error">
                          Please enter a valid OTP
                        </div>
                      )}
                      <div className="otp-verify-resend-otp">
                        <div>
                          <span>Didn't receive the OTP?</span>
                        </div>
                        {!enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            style={{ color: "black" }}
                          >
                            Resend
                          </div>
                        )}
                        {enabled && (
                          <div
                            className="otp-verify-resend-otp-button"
                            type="button"
                            onClick={resendOtpForPhoneChange}
                          >
                            Resend OTP
                          </div>
                        )}

                        <div>{timeLeft !== 0 && <div>{timeLeft}</div>}</div>
                      </div>
                      <Button
                        className="customer-login-btn"
                        fullWidth
                        type="button"
                        onClick={() => {
                          verifyOtpForPhoneVerification(
                            phoneForVerification,
                            code
                          );
                        }}
                      >
                        CONTINUE
                      </Button>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}

import React, { useEffect, useState, useContext } from "react";
import AdminLayoutAdjustment from "./AdminLayoutAdjustment";
import { AdminContext } from "../context/AdminContext";
import headingIcon from "../Images/HedingIcon.png";
import "../styles/AdminAllOrders.css";
import "../styles/Common.css";
import { Modal, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { API_BASE_URL } from "../App";
function AdminAllOrders() {
  const { accessToken } = useContext(AdminContext);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [list, setList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [sellers, setSellers] = useState([]);
  const [sellerEmail, setSellerEmail] = useState("");
  const [sellerMobile, setSellerMobile] = useState("");
  const [sellerAdressId, setSellerAdressId] = useState("");
  const [sellerAdress, setSellerAdress] = useState("");

  /*fetcing all order data */
  async function fetchList() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetOrderBySellerid`,
        {
          method: "GET",
          headers: {
            Authorization: ` Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log("all orders list", data);
      setList(data);
      console.log("list of all orders", data);
    } catch {}
  }
  useEffect(() => {
    fetchList();
  }, []);
  /*fetchning all sellers details and saving in seller state  */
  async function FetchSellers() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/seller/api/v1/GetSellerById&GetAllSeller`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log("seller info", data);
      setSellers(data.result);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    }
  }
  useEffect(() => {
    FetchSellers();
  }, []);
  /*FETCHING DETAILS ORDER FROM ORDER NUMBER .The oRDER NUMBER IS OBTAINED is obtained when the view is clicked. */
  const fetchDetails = async (orderNumber) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/orders/api/v1/orderDetailsById?orderid=${orderNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setSelectedOrder(data);
    } catch (error) {
      console.error("Failed to fetch order details", error);
    }
  };
  /*fetching selleradress by passing selleradressid obtained from getSellerAdressId fucntion and saving in a state */
  async function sellerNewAdress(sellerAdressId) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/address/api/v1/getall?id=${sellerAdressId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      if (data.result && data.result.length > 0) {
        setSellerAdress(data.result[0]);
      } else {
        setSellerAdress(null);
      }
    } catch (error) {
      console.error("Failed to fetch address", error);
    }
  }
  useEffect(() => {
    sellerNewAdress(sellerAdressId);
  }, [sellerAdressId]);
  // Function to get the seller's name by seller ID. The seller ID is obtained when the view is clicked.
  const getSellerName = (sellerId) => {
    const seller = sellers.find(
      (seller) => seller.sellerId.toLowerCase() === sellerId.toLowerCase()
    );
    return seller ? seller.sellerName : "Unknown Seller";
  };
  // Function to get the seller's email by sellerId. The seller ID is obtained when the view is clicked.
  const getSellerEmail = (sellerId) => {
    const seller = sellers.find(
      (seller) => seller.sellerId.toLowerCase() === sellerId.toLowerCase()
    );
    return seller ? seller.emailId : "Email not found";
  };

  // Function to get the seller's mobile number by sellerId.The seller ID is obtained when the view is clicked.
  const getSellerMobileNumber = (sellerId) => {
    const seller = sellers.find(
      (seller) => seller.sellerId.toLowerCase() === sellerId.toLowerCase()
    );
    return seller ? seller.sellerMobileNumber : "Mobile number not found";
  };
  //Function to get the seller's adressid by sellerId.The seller ID is obtained when the view is clicked.
  const getSellerAdressId = (sellerId) => {
    const seller = sellers.find(
      (seller) => seller.sellerId.toLowerCase() === sellerId.toLowerCase()
    );
    return seller ? seller.companyAddress : " not found";
  };
  //modal open
  const handleOpen = (orderNumber, sellerId) => {
    setOpen(true);
    fetchDetails(orderNumber);
    setSellerEmail(getSellerEmail(sellerId));
    setSellerMobile(getSellerMobileNumber(sellerId));
    setSellerAdressId(getSellerAdressId(sellerId));
    console.log(getSellerAdressId(sellerId));
  };

  //modal close
  const handleClose = () => {
    setOpen(false);
    setSelectedOrder(null);
  };
  /*FILTER OF PEDNING FINISHED ORDERES */
  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setIsOpen(false);
  };

  const filteredOrders =
    selectedStatus === "all"
      ? list?.result
      : list?.result?.filter((item) => item.orderStatus === selectedStatus);

  return (
    <AdminLayoutAdjustment>
      <div>
        <div className="new-modal-of-admin">
          <div className="new-model-of-admin-head">
            <img
              loading="lazy"
              src={headingIcon}
              alt=""
              className="heading-image"
            />
            <h2 className="heading-text">Orders</h2>
          </div>
          <div>
            <div className="admin-all-order-dropdown-wrapper">
              <select
                className={`admin-all-order-dropdown ${isOpen ? "open" : ""}`}
                onClick={toggleDropdown}
                onChange={handleStatusChange}
                value={selectedStatus}
              >
                <option value="all">All Orders</option>
                <option value="pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="Refund Scheduled">Refund Scheduled</option>
              </select>
              <div className="dropdown-icon" onClick={toggleDropdown}>
                {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </div>
            </div>
          </div>
        </div>

        <table className="admin-categories-table">
          <tbody>
            <tr>
              <th>Order No</th>
              <th>Total Cost</th>
              <th>Payment status</th>
              <th>Seller Name</th>
              <th>Order Status</th>
              <th>Shipment Status</th>
              <th>Details</th>
            </tr>
            {filteredOrders?.map((item, index) => (
              <tr key={index}>
                {/* Order No */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.orderNumber}
                    </div>
                  </div>
                </td>
                {/* cost */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.totalCost}
                    </div>
                  </div>
                </td>
                {/* Payment status */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.paymentStatus}
                    </div>
                  </div>
                </td>
                {/* Seller name */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {getSellerName(item.sellerID)}
                    </div>
                  </div>
                </td>
                {/* Order status */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.orderStatus}
                    </div>
                  </div>
                </td>
                {/* Shipment status */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {item.shipmentStatus}
                    </div>
                  </div>
                </td>
                {/* View Button */}
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      <button
                        className="common-button invite-seller-button"
                        onClick={() =>
                          handleOpen(item.orderNumber, item.sellerID)
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal open={open} aria-labelledby="order-details-title">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            maxHeight: "80vh",
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 2,
            overflowY: "auto",
          }}
        >
          <div className="admin-view-order-modal">
            <h3>Details</h3>
            <CloseIcon
              onClick={handleClose}
              style={{
                cursor: "pointer",
              }}
            />
          </div>
          <div className="admin-view-order-details-container">
            {selectedOrder?.result?.result?.map((item) => (
              <div
                key={item.order_RefrenceId || item.sku}
                className="admin-view-order-details-order-container"
              >
                {/* Seller Information */}
                <div className="admin-view-order-details-section">
                  <h3>Seller Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>Email:</span> {sellerEmail}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Mobile:</span> {sellerMobile}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Company Address:</span>{" "}
                    {sellerAdress ? (
                      <>
                        {sellerAdress.addressLine1}, {sellerAdress.addressLine2}
                        , {sellerAdress.city}, {sellerAdress.state}{" "}
                        {sellerAdress.pincode}, {sellerAdress.country}
                      </>
                    ) : (
                      <p>No address found.</p>
                    )}
                  </div>
                </div>

                {/* Order Information */}
                <div className="admin-view-order-details-section">
                  <h3>Order Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>Order Reference ID:</span> {item.order_RefrenceId}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Order Date:</span>{" "}
                    {new Date(item.date).toLocaleDateString()}
                  </div>
                </div>

                {/* Customer Information */}
                <div className="admin-view-order-details-section">
                  <h3>Customer Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>Customer Name:</span> {item.name}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Email:</span> {item.email}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Contact:</span> {item.contact}
                  </div>
                </div>

                {/* Address Information */}
                <div className="admin-view-order-details-section">
                  <h3>Address Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>Destination Address:</span> {item.destinationAddress},{" "}
                    {item.destinationPlace}, {item.destinationState},{" "}
                    {item.destinationPincode}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Billing Address:</span> {item.billingAddress},{" "}
                    {item.billingPlace}, {item.billingState},{" "}
                    {item.billingPincode}
                  </div>
                </div>

                {/* Payment Information */}
                <div className="admin-view-order-details-section">
                  <h3>Payment Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>Total Cost:</span> ₹{item.totalCost}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Subtotal:</span> ₹{item.subTotal}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>GST:</span> ₹{item.gst}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Shipping Cost:</span> ₹{item.shippingCost}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Payment Mode:</span> {item.payMode}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Payment Reference Number:</span> {item.paymentRefNum}
                  </div>
                </div>

                {/* Product Information */}
                <div className="admin-view-order-details-section">
                  <h3>Product Information</h3>
                  <div className="admin-view-order-details-item">
                    <span>SKU:</span> {item.sku}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Quantity:</span> {item.quantity}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Item Cost:</span> ₹{item.itemCost}
                  </div>
                  <div className="admin-view-order-details-item">
                    <span>Title:</span> {item.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </Modal>
    </AdminLayoutAdjustment>
  );
}

export default AdminAllOrders;

import React from "react";
import CustomerLandingPageNewNavBar from "./CustomerLandingPageNewNavBar";
import CustomerLandingPageNewFooter from "./CustomerLandingPageNewFooter";

function CustomerLayoutnew(props) {
  return (
    <>
      <CustomerLandingPageNewNavBar />

      <div>{props.children}</div>
      <CustomerLandingPageNewFooter />
    </>
  );
}

export default CustomerLayoutnew;

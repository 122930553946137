import React, { useState } from "react";
import Slider from "react-slick";
import Nc1 from "../Images/sliderimage3.png";
import Nc2 from "../Images/sliderimage4.png";
import "../styles/CustomerLandingPageCard1.css";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";
import StarIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

// Custom Arrow Components
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard1Slidearrowright" onClick={onClick}>
      <CaretRight />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard1Slidearrowleft" onClick={onClick}>
      <CaretLeft />
    </div>
  );
}

// Slider Component
function MultipleItems() {
  const [favoritedStates, setFavoritedStates] = useState({}); // State to track clicks
  const [hoveredStates, setHoveredStates] = useState({}); // State to track hover

  const handleHover = (index, isHovering) => {
    setHoveredStates((prev) => ({
      ...prev,
      [index]: isHovering, // Set hover state for specific image
    }));
  };

  const handleClick = (index) => {
    setFavoritedStates((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle favorite state for the specific image
    }));
  };

  const images = [
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="customerNewlandingCard1">
      <Slider {...settings}>
        {images.map((image, index) => {
          const percentage = (image.rating / 5) * 100; // Calculate rating percentage
          const isFavorited = favoritedStates[index] || false; // Get click state
          const isHovered = hoveredStates[index] || false; // Get hover state

          return (
            <div key={index}>
              <div className="customerNewlandingCard1-best-seller-label">
                Best Seller
              </div>
              <div className="customerNewlandingCard1-overlay-container">
                <img
                  src={image.src}
                  alt={image.title}
                  style={{ width: "100%" }}
                />
                <div className="CustomerLandingPageCard1-imageRating">
                  <StarIcon
                    key="single-star"
                    rating={image.rating}
                    style={{
                      color: "#029F4A",
                      clipPath: `inset(0 ${100 - percentage}% 0 0)`,
                    }}
                  />
                  <div>{image.rating} | 5</div>
                </div>
                <button
                  className="heart-button"
                  onClick={() => handleClick(index)} // Pass index to handleClick
                  onMouseEnter={() => handleHover(index, true)} // Set hover true
                  onMouseLeave={() => handleHover(index, false)} // Set hover false
                >
                  {isFavorited || isHovered ? (
                    <FavoriteIcon
                      style={{ fontSize: "16px", color: "#fd2583" }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      style={{ fontSize: "16px", color: "#fd2583" }}
                    />
                  )}
                </button>
              </div>
              <div className="customerNewlandingCard-titlesection">
                <div className="customerNewlandingCard1-title">
                  {image.title}
                </div>
                <div className="customerNewlandingCard1-subtitle">
                  {image.subtitle}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

// Main Component
function CustomerLandingCard5() {
  return (
    <div className="customer-landing-card1">
      <div className="CustomerLandingCard1titesection">
        <div className="CustomerLandingCard1titesection-title">Most Viewed</div>
        <div className="CustomerLandingCard1titesection-subtitle">View All</div>
      </div>
      <div className="CustomerLandingCard1Slidesection">
        <MultipleItems />
      </div>
    </div>
  );
}

export default CustomerLandingCard5;

import React, { useState } from "react";
import "../styles/CustomerNewCart.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import img1 from "../Images/cart-img1-new.png";
import { Trash } from "@phosphor-icons/react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useNavigate } from "react-router-dom";
const ItemDetailsComponent = () => {
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "Button Down Dress",
      price: 2000,
      originalPrice: 2200,
      image: img1,
      color: "Beige",
      sizes: ["Small", "Medium", "Large", "X-Large"],
      selectedSize: "Small",
      quantity: 1,
    },
    {
      id: 2,
      name: "Casual T-Shirt",
      price: 1000,
      originalPrice: 1200,
      image: img1,
      color: "Blue",
      sizes: ["Small", "Medium", "Large"],
      selectedSize: "Medium",
      quantity: 1,
    },
    {
      id: 2,
      name: "Casual T-Shirt",
      price: 1000,
      originalPrice: 1200,
      image: img1,
      color: "Blue",
      sizes: ["Small", "Medium", "Large"],
      selectedSize: "Medium",
      quantity: 1,
    },
    {
      id: 2,
      name: "Casual T-Shirt",
      price: 1000,
      originalPrice: 1200,
      image: img1,
      color: "Blue",
      sizes: ["Small", "Medium", "Large"],
      selectedSize: "Medium",
      quantity: 1,
    },
    {
      id: 2,
      name: "Casual T-Shirt",
      price: 1000,
      originalPrice: 1200,
      image: img1,
      color: "Blue",
      sizes: ["Small", "Medium", "Large"],
      selectedSize: "Medium",
      quantity: 1,
    },
  ]);

  const handleIncrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleSizeChange = (id, size) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, selectedSize: size } : item
      )
    );
  };

  const removeItem = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <div className="CustomerNewCart-subheading-section2-scroll">
      <div className="CustomerNewCart-subheading-section2">
        {cartItems.map((item) => (
          <div
            key={item.id}
            className="CustomerNewCart-subheading-section-itemlist"
          >
            <div className="CustomerNewCart-subheading-section-itemlist-sub1">
              <img
                src={item.image}
                alt={item.name}
                className="CustomerNewCart-subheading-itemlist-img"
              />

              <div className="CustomerNewCart-subheading-itemlist-deatils">
                <div className="CustomerNewCart-subheading-itemlist-deatils-head">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-heading">
                    {item.name}
                  </div>
                  <Trash
                    size={18}
                    style={{
                      color: "hsla(359, 99%, 67%, 1)",
                      cursor: "pointer",
                    }}
                    onClick={() => removeItem(item.id)}
                  />
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-price">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price1">
                    {item.price}
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price2">
                    {item.originalPrice}
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price-d">
                    MRP Inclusive of all taxes
                  </div>
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-buttons">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button1">
                    <img
                      src={item.image}
                      alt={item.name}
                      className="CustomerNewCart-subheading-itemlist-deatils-button1-img"
                    />
                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-color-size">
                      Color : {item.color}
                    </div>

                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName">
                      Size : {item.selectedSize}
                    </div>
                    <div className="CustomerNewCart-size-dropdown-wrapper">
                      <ArrowDropDownIcon
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setCartItems((prevItems) =>
                            prevItems.map((curr) =>
                              curr.id === item.id
                                ? { ...curr, showDropdown: !curr.showDropdown }
                                : curr
                            )
                          )
                        }
                      />
                      {item.showDropdown && (
                        <div className="CustomerNewCart-subheading-itemlist-dropdown-menu">
                          {item.sizes.map((size) => (
                            <div
                              key={size}
                              className="CustomerNewCart-subheading-itemlist-dropdown-item"
                              onClick={() => handleSizeChange(item.id, size)}
                            >
                              {size}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button2">
                    <RemoveIcon
                      style={{ color: "black", cursor: "pointer" }}
                      onClick={() => handleDecrement(item.id)}
                    />
                    <div className="CustomerNewCart-subheading-itemlist-deatils-number">
                      {item.quantity}
                    </div>
                    <AddIcon
                      style={{ color: "black", cursor: "pointer" }}
                      onClick={() => handleIncrement(item.id)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CoupounAndGiftCard = () => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="CustomerNewCart-accordian-sec-1"
        >
          Apply Coupons
        </AccordionSummary>
        <AccordionDetails>
          <div className="CustomerNewCart-apply-coup-ip-sec">
            <div className="CustomerNewCart-apply-coup-ip">
              <input
                type="text"
                className="CustomerNewCart-apply-coup-ip-text"
                placeholder="Enter code or discount code"
              />
              <button className="CustomerNewCart-apply-coup-ip-btn">
                Apply
              </button>
            </div>
          </div>

          <div className="CustomerNewCart-subheading-section-applycoupon">
            <div className="CustomerNewCart-subheading-section-applycouponSection">
              <div className="CustomerNewCart-subheading-section-applycouponSection-flex">
                <div className="CustomerNewCart-subheading-section-applycouponSection-text1">
                  Flat ₹100 off
                </div>
                <div className="CustomerNewCart-subheading-section-applycouponSection-text2">
                  use code{" "}
                  <span className="CustomerNewCart-subheading-section-applycouponSection-text2-span">
                    FLAT 100 & get
                  </span>{" "}
                  ₹100 off on orders above 999
                </div>
              </div>
              <div className="CustomerNewCart-subheading-section-applycouponSection-apply">
                Apply
              </div>
            </div>
            <div className="CustomerNewCart-subheading-section-applycouponSection">
              <div className="CustomerNewCart-subheading-section-applycouponSection-flex">
                <div className="CustomerNewCart-subheading-section-applycouponSection-text1">
                  Flat ₹100 off
                </div>
                <div className="CustomerNewCart-subheading-section-applycouponSection-text2">
                  use code{" "}
                  <span className="CustomerNewCart-subheading-section-applycouponSection-text2-span">
                    FLAT 100 & get
                  </span>{" "}
                  ₹100 off on orders above 999
                </div>
              </div>
              <div className="CustomerNewCart-subheading-section-applycouponSection-apply">
                Apply
              </div>
            </div>
            <div className="CustomerNewCart-subheading-section-applycouponSection">
              <div className="CustomerNewCart-subheading-section-applycouponSection-flex">
                <div className="CustomerNewCart-subheading-section-applycouponSection-text1">
                  Flat ₹100 off
                </div>
                <div className="CustomerNewCart-subheading-section-applycouponSection-text2">
                  use code{" "}
                  <span className="CustomerNewCart-subheading-section-applycouponSection-text2-span">
                    FLAT 100 & get
                  </span>{" "}
                  ₹100 off on orders above 999
                </div>
              </div>
              <div className="CustomerNewCart-subheading-section-applycouponSection-apply">
                Apply
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className="CustomerNewCart-accordian-sec-1"
        >
          Redeem Gift Card
        </AccordionSummary>
        <AccordionDetails>
          <div className="CustomerNewCart-apply-coup-ip-sec">
            <div className="CustomerNewCart-apply-coup-ip">
              <input
                type="text"
                className="CustomerNewCart-apply-coup-ip-text"
                placeholder="Enter Gift Code"
              />
              <button className="CustomerNewCart-apply-coup-ip-btn">
                Apply
              </button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

function CustomerNewCart1() {
  const navigate = useNavigate();

  const handleProceedToClick = () => {
    navigate("/CustomerNewCheckOut");
  };

  return (
    <div>
      <div className="CustomerNewCart-heading">Shopping Cart</div>
      <div className="CustomerNewCart-subheading-section">
        <ItemDetailsComponent />

        <div className="CustomerNewCart-subheading-section-paymentDetails">
          <CoupounAndGiftCard />
          <div className="CustomerNewCart-subheading-section-AmountSection">
            Order Details
          </div>
          <div className="CustomerNewCart-subheading-section-AmountDetailsSection-main">
            <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                Sub Total{" "}
                <span className="CustomerNewCart-subheading-section-AmountDetailsSection-text1-span">
                  (3 Items)
                </span>
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                ₹ 2000
              </div>
            </div>
            <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                Discount
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                - ₹ 50
              </div>
            </div>
            <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                Shipping
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                ₹ 50
              </div>
            </div>
            <hr style={{ width: "100%", height: " 0px" }} />
            <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text2">
                To Pay
                <span className="CustomerNewCart-subheading-section-AmountDetailsSection-text2-span">
                  (Inclusive Of Taxes)
                </span>
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text3">
                ₹ 2000
              </div>
            </div>
          </div>
          <div className="CustomerNewCart-proceedtopay-section">
            <div
              className="CustomerNewCart-proceedtopay-btn"
              onClick={handleProceedToClick}
            >
              Proceed To Pay
            </div>
          </div>
          <div className="customernewcart-p-t-p">
            Great! You are saving ₹50 in total
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerNewCart1;

import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";

import CustomerLandingCard5 from "../components/CustomerLandingCard5";
import CustomerLandingCard4 from "../components/CustomerLandingCard4";
import CustomernewCartCheckout from "../components/CustomernewCartCheckout";

function CustomerNewCheckOut() {
  return (
    <div>
      <CustomerLayoutnew>
        <CustomernewCartCheckout />
        <CustomerLandingCard4 />
        <CustomerLandingCard5 />
      </CustomerLayoutnew>
    </div>
  );
}

export default CustomerNewCheckOut;

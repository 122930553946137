import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";
import CustomerLandingNewBlogs from "../components/CustomerLandingNewBlogs";

function CustomerLandingBlogs() {
  return (
    <div>
      <CustomerLayoutnew>
        <CustomerLandingNewBlogs />
      </CustomerLayoutnew>
    </div>
  );
}

export default CustomerLandingBlogs;

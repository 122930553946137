import React, { useState } from "react";
import Slider from "react-slick";
import Nc1 from "../Images/man and woman hands making clay pot in pottery_preview 1.png";

import "../styles/CustomerLandingPageCard1.css";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";

import "../styles/CustomerLandingPageCard4-6.css";

// Custom Arrow Components
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard6Slidearrowright" onClick={onClick}>
      <CaretRight />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard6Slidearrowleft" onClick={onClick}>
      <CaretLeft />
    </div>
  );
}

// Slider Component
function MultipleItems() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleReadMoreClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const images = [
    {
      src: Nc1,
      title: "The Art of Making Pottery",
      description:
        "Pottery is the ancient art of shaping clay into functional or decorative objects, such as vases, bowls, and sculptures. Once molded, the clay is fired at high temperatures to harden. Pottery blends creativity, craftsmanship, and utility, forming timeless pieces.",
    },
    {
      src: Nc1,
      title: "The Art of Weaving",
      description:
        "Weaving is a traditional craft that involves interlacing threads to create textiles. Artisans skillfully transform natural fibers like cotton, silk, and wool into fabrics that showcase intricate patterns and vibrant colors. Weaving is a cornerstone of heritage and culture.",
    },
    {
      src: Nc1,
      title: "The Art of Metalwork",
      description:
        "Metalwork is the skill of creating objects from various metals, including silver, gold, and brass. Artisans design jewelry, utensils, and sculptures with precision and creativity, blending traditional techniques with contemporary aesthetics.",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image.src}
              alt={image.title}
              className="CustomerLandingCard6-images-slide"
            />
            <div className="CustomerLandingCard6-title-para-slide">
              <div className="CustomerLandingCard6-para-slide-title">
                {image.title}
              </div>
              <div className="CustomerLandingPageCard6-para">
                {expandedIndex === index
                  ? image.description
                  : `${image.description.substring(0, 100)}...`}
              </div>
              <div
                className="CustomerLandingCard6-para-slide-readmore"
                onClick={() => handleReadMoreClick(index)}
              >
                {expandedIndex === index ? "Read Less" : "Read More"}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Main Component
function CustomerLandingPageCard6() {
  return (
    <div className="customer-landing-card1">
      <div className="CustomerLandingCard1titesection">
        <div className="CustomerLandingCard1titesection-title">
          Tale Of Crafts
        </div>
        <div className="CustomerLandingCard1titesection-subtitle">View All</div>
      </div>
      <div className="CustomerLandingCard6-subtite-section">
        <div className="CustomerLandingCard6-subtite-section-design">
          Indian Handicrafts
        </div>
        <div className="CustomerLandingCard6-subtite-section-design">
          Indian Artisans
        </div>
      </div>
      <div className="CustomerLandingCard6Slidesection">
        <MultipleItems />
      </div>
    </div>
  );
}

export default CustomerLandingPageCard6;

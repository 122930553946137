import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";
import CustomerListingPageC1 from "../components/CustomerListingPageC1";
import CustomerLandingPageNotifcation from "../components/CustomerLandingPageNotification";
import CustomerLandingCard5 from "../components/CustomerLandingCard5";
import CustomerLandingCard4 from "../components/CustomerLandingCard4";
function CustomerListingPageNew() {
  return (
    <>
      <CustomerLayoutnew>
        <CustomerLandingPageNotifcation />
        <CustomerListingPageC1 />
        <CustomerLandingCard4 />
        <CustomerLandingCard5 />
      </CustomerLayoutnew>
    </>
  );
}

export default CustomerListingPageNew;

import React, { useState } from "react";
import { CaretDown } from "@phosphor-icons/react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../styles/CustomerNewMyAccount2.css";
function Faqsectionmyaccnew2() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className="cus-myacc-new-faq-main-sec-width">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>{" "}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="cus-myacc-new-faq-main-title-head"
          >
            I am having trouble accessing the site?
          </AccordionSummary>
          <AccordionDetails>
            <div className="cus-myacc-new-faq-main-sec1-para">
              You may want to refresh your browser. If you are still
              experiencing any difficulty accessing the website, please check to
              make sure your internet connection is working properly. If it is
              not, please contact your internet provider. If you are able to
              connect but still having problems, you may need to clean out your
              temporary Internet files, cookies or cache
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

export default Faqsectionmyaccnew2;

import React, { useState } from "react";
import "../styles/CustomerNewMyAccount.css";
import { Funnel } from "@phosphor-icons/react";
import CustomerMyAccountWallet from "./CustomerMyAccountWallet";
import CustomerMyAccountPersonalInfoSec from "./CustomerMyAccountPersonalInfoSec";
import CustomerNewMyAccountAdress from "./CustomerNewMyAccountAdress";
import CustomerMyAccountWishList from "./CustomerMyAccountWishList";
import Faqsectionmyaacnew from "./Faqsectionmyaacnew";
import Faqsectionmyaccnew2 from "./Faqsectionmyaccnew2";
import Customernewmyaccallreturn from "./Customernewmyaccallreturn";
import CusnewOerderCompleted from "./CusnewOerderCompleted";
import Cusnewcancel from "./Cusnewcancel";
import Cusnewpending from "./Cusnewpending";
import CustomerNewMyAccountOrders from "../components/CustomerNewMyAccountOrders";
import { Menu, MenuItem } from "@mui/material";
import {
  UserCircle,
  CaretRight,
  Bag,
  Question,
  Wallet,
} from "@phosphor-icons/react";

function CustomerNewMyAccount() {
  const [activeTab, setActiveTab] = useState("PersonalInformation");
  const [helpTab, setHelpTab] = useState("Help");
  const [orderStatus, setOrderStatus] = useState("AllOrders");

  const getMenuItemClass = (itemId) => {
    if (
      itemId === "PersonalInformation" &&
      (activeTab === "MyAdress" || activeTab === "MyWishList")
    ) {
      return "CustomerNewMyAccount-active-tab";
    }
    return activeTab === itemId
      ? "CustomerNewMyAccount-active-tab"
      : "CustomerNewMyAccount-inactive-tab";
  };

  const menuItems = [
    { id: "PersonalInformation", icon: <UserCircle />, label: "My Profile" },
    { id: "MyOrders", icon: <Bag />, label: "My Orders" },
    { id: "Help", icon: <Question />, label: "Help" },
    { id: "Wallet", icon: <Wallet />, label: "Wallet" },
  ];

  const renderActiveComponent = () => {
    switch (activeTab) {
      case "PersonalInformation":
        return <CustomerMyAccountPersonalInfoSec />;
      case "MyAdress":
        return <CustomerNewMyAccountAdress />;
      case "MyWishList":
        return <CustomerMyAccountWishList />;
      case "MyOrders":
        if (orderStatus === "AllOrders") {
          return <CustomerNewMyAccountOrders />;
        } else if (orderStatus === "Pending") {
          return <Cusnewpending />;
        } else if (orderStatus === "Completed") {
          return <CusnewOerderCompleted />;
        } else if (orderStatus === "Return") {
          return <Customernewmyaccallreturn />;
        } else if (orderStatus === "Cancel") {
          return <Cusnewcancel />;
        }
        break;
      case "Help":
        return helpTab === "Help" ? (
          <Faqsectionmyaacnew />
        ) : (
          <Faqsectionmyaccnew2 />
        );
      case "Wallet":
        return <CustomerMyAccountWallet />;
      default:
        return <CustomerMyAccountPersonalInfoSec />;
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSort, setSelectedSort] = useState("Recommended");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSortSelection = (sortOption) => {
    setSelectedSort(sortOption);
    handleClose();

    console.log(`Selected sort option: ${sortOption}`);
  };

  return (
    <div>
      <div className="CustomerNewMyAccount-heading">My Profile</div>
      <div className="CustomerNewMyAccount-main-section">
        <div className="CustomerNewMyAccount-division-sec">
          <div className="CustomerNewMyAccount-menu-section">
            {menuItems.map((item) => (
              <div
                key={item.id}
                className={`CustomerNewMyAccount-icons-details ${
                  activeTab === item.id ||
                  (item.id === "MyOrders" && activeTab === "MyOrders") ||
                  (item.id === "PersonalInformation" &&
                    (activeTab === "MyAdress" || activeTab === "MyWishList"))
                    ? "CustomerNewMyAccount-active-menu"
                    : ""
                }`}
                onClick={() => {
                  setActiveTab(item.id);

                  if (item.id === "Help") setHelpTab("Help");
                  if (item.id === "MyOrders") setOrderStatus("AllOrders");
                }}
              >
                {item.icon}
                <div>{item.label}</div>
                <CaretRight />
              </div>
            ))}
          </div>
          <div className="vertical-line" />
          <div className="CustomerNewMyAccount-details-section">
            {(() => {
              if (
                activeTab === "PersonalInformation" ||
                activeTab === "MyAdress" ||
                activeTab === "MyWishList"
              ) {
                return (
                  <div className="CustomerNewMyAccount-details-tabs">
                    <div
                      className={
                        activeTab === "PersonalInformation"
                          ? "CustomerNewMyAccount-active-tab"
                          : "CustomerNewMyAccount-inactive-tab"
                      }
                      onClick={() => setActiveTab("PersonalInformation")}
                    >
                      Personal Information
                    </div>
                    <div
                      className={
                        activeTab === "MyAdress"
                          ? "CustomerNewMyAccount-active-tab"
                          : "CustomerNewMyAccount-inactive-tab"
                      }
                      onClick={() => setActiveTab("MyAdress")}
                    >
                      My Address
                    </div>
                    <div
                      className={
                        activeTab === "MyWishList"
                          ? "CustomerNewMyAccount-active-tab"
                          : "CustomerNewMyAccount-inactive-tab"
                      }
                      onClick={() => setActiveTab("MyWishList")}
                    >
                      My WishList
                    </div>
                  </div>
                );
              } else if (activeTab === "MyOrders") {
                return (
                  <div className="CustomerNewMyAccounthelp-details-tabs-for-myorders-sort">
                    <div className="CustomerNewMyAccounthelp-details-tabs-for-myord">
                      <div
                        className={
                          orderStatus === "AllOrders"
                            ? "CustomerNewMyAccount-active-tab"
                            : "CustomerNewMyAccount-inactive-tab"
                        }
                        onClick={() => setOrderStatus("AllOrders")}
                      >
                        All Orders
                      </div>
                      <div
                        className={
                          orderStatus === "Pending"
                            ? "CustomerNewMyAccount-active-tab"
                            : "CustomerNewMyAccount-inactive-tab"
                        }
                        onClick={() => setOrderStatus("Pending")}
                      >
                        Pending
                      </div>
                      <div
                        className={
                          orderStatus === "Completed"
                            ? "CustomerNewMyAccount-active-tab"
                            : "CustomerNewMyAccount-inactive-tab"
                        }
                        onClick={() => setOrderStatus("Completed")}
                      >
                        Completed
                      </div>
                      <div
                        className={
                          orderStatus === "Return"
                            ? "CustomerNewMyAccount-active-tab"
                            : "CustomerNewMyAccount-inactive-tab"
                        }
                        onClick={() => setOrderStatus("Return")}
                      >
                        Return
                      </div>
                      <div
                        className={
                          orderStatus === "Cancel"
                            ? "CustomerNewMyAccount-active-tab"
                            : "CustomerNewMyAccount-inactive-tab"
                        }
                        onClick={() => setOrderStatus("Cancel")}
                      >
                        Cancelled Orders
                      </div>
                    </div>
                    <div>
                      <div
                        onClick={handleClick}
                        className="sort-section-CustomerNewMyAccount"
                      >
                        Sort By <Funnel />
                      </div>

                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem
                          onClick={() => handleSortSelection("Recommended")}
                        >
                          Sort By :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            &nbsp;Past 3 months
                          </span>
                        </MenuItem>

                        <MenuItem
                          onClick={() => handleSortSelection("What’s New")}
                        >
                          Past 3 months
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleSortSelection("Popularity")}
                        >
                          2022
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleSortSelection("Discount")}
                        >
                          2021
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleSortSelection("Discount")}
                        >
                          2020
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                );
              } else if (activeTab === "Help") {
                return (
                  <div className="CustomerNewMyAccounthelp-details-tabs">
                    <div
                      className={
                        helpTab === "Help"
                          ? "CustomerNewMyAccount-active-tab"
                          : "CustomerNewMyAccount-inactive-tab"
                      }
                      onClick={() => setHelpTab("Help")}
                    >
                      Help
                    </div>
                    <div
                      className={
                        helpTab === "Faq"
                          ? "CustomerNewMyAccount-active-tab"
                          : "CustomerNewMyAccount-inactive-tab"
                      }
                      onClick={() => setHelpTab("Faq")}
                    >
                      FAQ
                    </div>
                  </div>
                );
              } else if (activeTab === "Wallet") {
                return (
                  <div className="CustomerNewMyAccounthelp-details-tabs">
                    <div className="CustomerNewMyAccount-active-tab2">
                      Gift Card
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })()}

            <div className="CustomerNewMyAccount-tabs-below">
              {renderActiveComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerNewMyAccount;

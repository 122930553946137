import React from "react";
import TextField from "@mui/material/TextField";
import "../styles/CustomerNewMyAccount2.css";
import { WarningCircle } from "@phosphor-icons/react";
function CustomerMyAccountWallet() {
  return (
    <div className="myacc-newwall-mainsec">
      <div>Available balance : ₹ 0</div>
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Gift Cart Code"
          variant="standard"
          sx={{
            "& .MuiInputLabel-root.Mui-focused": { color: "#fe585b" },
          }}
        />
        <div className="CustomerNewMyAccount-changePasswordText">
          Add Gift Card
        </div>
      </div>
      <div className="myacc-newwall-main">
        <WarningCircle className="warning-circle-myacc-newwall" />
        <div>
          For optimal utilization, balance expiring the earliest will be
          redeemed first.
        </div>
      </div>
    </div>
  );
}

export default CustomerMyAccountWallet;

import React, { useState } from "react";
import "../styles/CustomerNewMyAccount.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { SealCheck, XCircle } from "@phosphor-icons/react";
function CustomerMyAccountPersonalInfoSec() {
  const getDynamicWidth = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      return 280; // Less width for small screens
    } else if (width >= 600 && width <= 900) {
      return 600; // More width for medium screens
    }
    return 400; // Default width for other cases
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: getDynamicWidth(),
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [isEditable, setIsEditable] = useState(false);

  const toggleEdit = () => {
    setIsEditable(!isEditable);
  };

  const handleCancel = () => {
    setIsEditable(false);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="CustomerNewMyAccount-Personal-info">
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Full Name"
          variant="standard"
          defaultValue="Tejas M"
          disabled={!isEditable}
          sx={{
            "& .MuiInputLabel-root": {
              color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
            },
          }}
        />
      </div>{" "}
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Mobile Number"
          variant="standard"
          defaultValue="9901974627"
          disabled={!isEditable}
          sx={{
            "& .MuiInputLabel-root": {
              color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
            },
          }}
        />
        {isEditable && (
          <div className="CustomerNewMyAccount-changePasswordText">
            Verified <SealCheck weight="light" />
          </div>
        )}
      </div>{" "}
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Email"
          variant="standard"
          defaultValue="rcblover.com"
          disabled={!isEditable}
          sx={{
            "& .MuiInputLabel-root": {
              color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
            },
          }}
        />
        {isEditable && (
          <div className="CustomerNewMyAccount-changePasswordText">
            Verified <SealCheck weight="light" />
          </div>
        )}
      </div>{" "}
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Birthday"
          variant="standard"
          defaultValue="09/03/2001"
          disabled={!isEditable}
          sx={{
            "& .MuiInputLabel-root": {
              color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
            },
          }}
        />
      </div>
      <div className="CustomerNewMyAccount-Personal-passwordsec">
        <TextField
          required
          label="Password"
          variant="standard"
          type="password"
          defaultValue="********"
          disabled={!isEditable}
          sx={{
            "& .MuiInputLabel-root": {
              color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
            },
          }}
        />
        <div
          className="CustomerNewMyAccount-changePasswordText"
          onClick={handleOpen}
        >
          Change Password
        </div>
      </div>
      <div className="CustomerNewMyAccount-edit-save-cancel-section">
        {isEditable && (
          <div
            className="CustomerNewMyAccount-edit-save-cancel"
            onClick={handleCancel}
          >
            Cancel
          </div>
        )}
        <div
          className="CustomerNewMyAccount-Personal-info-edit-btn"
          onClick={toggleEdit}
        >
          {isEditable ? "Save" : "Edit"}
        </div>
      </div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="Custnewacc-change-pass-modal">
            <div className="Custnewacc-change-pass-title">Change Password</div>

            <XCircle
              style={{ color: "#fe585b", cursor: "pointer" }}
              className="Custnewacc-change-pass-close"
              onClick={handleClose}
            />
          </div>
          <div className="Custnewacc-change-pass">
            <div className="CustomerNewMyAccount-Personal-passwordsec">
              <TextField
                required
                label="Old Password"
                variant="standard"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
                  },
                  "& .MuiInputLabel-asterisk": {
                    color: "#fe585b", // Set the asterisk color to pink
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
                  },
                }}
              />

              <div className="CustomerNewMyAccount-changePasswordText">
                Forgot Password
              </div>
            </div>
            <div className="CustomerNewMyAccount-Personal-passwordsec">
              <TextField
                required
                label="New Password"
                variant="standard"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
                  },
                  "& .MuiInputLabel-asterisk": {
                    color: "#fe585b", // Set the asterisk color to pink
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
                  },
                }}
              />
            </div>
            <div className="CustomerNewMyAccount-Personal-passwordsec">
              <TextField
                required
                label="Confirm Password"
                variant="standard"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: !isEditable ? "hsla(0, 0%, 56%, 1)" : "inherit",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: isEditable ? "#fe585b" : "hsla(0, 0%, 56%, 1)",
                  },
                  "& .MuiInputLabel-asterisk": {
                    color: "#fe585b",
                  },
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "hsla(0, 0%, 56%, 1)",
                  },
                }}
              />
            </div>
            <div className="CustomerNewMyAccount-savepass-sec">
              <div className="CustomerNewMyAccount-savepass">Save password</div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CustomerMyAccountPersonalInfoSec;

import React, { useState } from "react";
import "../styles/CustomerNewProductDetails.css";
import imag1 from "../Images/women's wear aesthetic photoshoot.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Gift } from "@phosphor-icons/react";
import Frame1 from "../Images/Frame 1000001259.png";
import Frame2 from "../Images/Frame 1000001260.png";
import Frame3 from "../Images/Frame 1000001261.png";
import Frame4 from "../Images/Frame 1000001262.png";
import Nc1 from "../Images/sliderimage3.png";
import Nc2 from "../Images/sliderimage4.png";
import Slider from "react-slick";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CaretRight, CaretLeft, ShareNetwork } from "@phosphor-icons/react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <CaretRight className="CustomerProductDetails customerproduct-next-arrow" />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div onClick={onClick}>
      <CaretLeft className="CustomerProductDetails customerproduct-prev-arrow" />
    </div>
  );
}

function CustomerProductDetails1() {
  const images = [
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
    { src: Nc1 },
    { src: Nc2 },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const [count, setCount] = useState(1);

  const increment = () => {
    setCount(count + 1);
  };

  const decrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };
  const [isFavorited, setIsFavorited] = useState(false);

  const handleMouseEnter = () => {
    setIsFavorited(true);
  };

  const handleMouseLeave = () => {
    setIsFavorited(false);
  };

  const handleClick = () => {
    setIsFavorited((prev) => !prev);
  };
  return (
    <div>
      <div className="CustomerProductDetails1-mainsection">
        <div className="CustomerProductDetails1-mainsection1">
          <button
            className="fav-icons-product-details"
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {isFavorited ? (
              <FavoriteIcon style={{ fontSize: "32px", color: "#fd2583" }} />
            ) : (
              <FavoriteBorderIcon
                style={{ fontSize: "32px", color: "#fd2583" }}
              />
            )}
          </button>
          <button className="fav-icons-product-details2" onClick={handleClick}>
            <ShareNetwork
              size={32}
              className="fav-icons-product-details2-share-icon"
            />
          </button>
          <Slider
            {...settings}
            className="CustomerProductDetails1-mainsection1-slider"
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image.src}
                  alt={`Slide ${index}`}
                  className="CustomerProductDetails1-mainsection1-slider-images"
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="CustomerProductDetails1-mainsection2">
          <div className="CustomerProductDetails1-mainsection-wrapper">
            <div className="CustomerProductDetails1-mainsection-title">
              Women’s Wear
            </div>
            <div className="CustomerProductDetails1-mainsection-Main-title">
              Button Down Dress
            </div>
            <div className="CustomerProductDetails1-mainsubsection2-1">
              <div className="CustomerProductDetails1-price">₹ 2000 </div>
              <div className="CustomerProductDetails1-dis-price">2200 </div>
              <div className="CustomerProductDetails1-dis-price-details">
                MRP Inclusive of all taxes
              </div>
            </div>
            <div className="CustomerProductDetails1-Sub-mainsection">
              <div> Variant</div>
              <hr className="CustomerProductDetails1-horiline" />
            </div>
            <div className="CustomerProductDetails1-types-section">
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>{" "}
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>{" "}
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>{" "}
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>{" "}
              <div className="CustomerProductDetails1-types">
                <img
                  src={imag1}
                  alt="image6"
                  className="CustomerProductDetails1-types-img"
                />
                <div className="CustomerProductDetails1-types-col">
                  <div className="CustomerProductDetails1-types-col-text1">
                    Color:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Blue
                    </span>
                  </div>
                  <div className="CustomerProductDetails1-types-col-text1">
                    Size:
                    <span className="CustomerProductDetails1-types-col-text1-span">
                      Medium
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="CustomerProductDetails1-buttons-section">
              <div className="CustomerProductDetails1-border-button1">
                <div onClick={decrement}>
                  <RemoveIcon style={{ color: "#fe585b" }} />
                </div>
                <div>{count}</div>
                <div onClick={increment}>
                  <AddIcon style={{ color: "#fe585b" }} />
                </div>
              </div>
              <div className="CustomerProductDetails1-border-button2">
                Add To Cart
              </div>
            </div>

            <div className="GiftcardSection-customerProducts-line">
              <hr className="CustomerProductDetails1-horiline" />
              <div className="GiftcardSection-customerProducts">
                <div className="GiftcardSection-customerProducts-sub1">
                  <Gift size={26} />
                  <div className="GiftcardSection-customerProducts-title">
                    Make a Gift
                  </div>
                </div>
                <div className="GiftcardSection-customerProducts-learnmore">
                  Learn More
                </div>
              </div>
            </div>
            <div className="customer-product-pincode-section">
              <div className="customer-product-pincode">
                <div
                  className="customer-product-pincode-entry"
                  contentEditable="true"
                  data-placeholder="Enter your pincode"
                ></div>
                <div className="customer-product-check-pc">Check</div>
              </div>
              <div className="customer-product-pincode-section-text">
                Usually Delivered with 2-3 days
              </div>
            </div>
            <div className="customer-product-logos">
              <img src={Frame1} alt="" className="customer-product-logo-img" />
              <img src={Frame2} alt="" className="customer-product-logo-img" />
              <img src={Frame3} alt="" className="customer-product-logo-img" />
              <img src={Frame4} alt="" className="customer-product-logo-img" />
            </div>
            <div className="customerproduct-accordian">
              <Accordion className="customerproduct-custom-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="customer-product-text-title"
                >
                  Product Description
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
              <Accordion className="customerproduct-custom-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="customer-product-text-title"
                >
                  Specifications
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
              <Accordion className="customerproduct-custom-accordion">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  className="customer-product-text-title"
                >
                  Reviews
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
}

export default CustomerProductDetails1;

import React from "react";
import "../styles/CustomerLandingPage-Nav-Noti-Slide1-GC-Footer.css";
import Myhraki from "../Images/Final_MYHRAKI_LOGO_PNG 3.png";
import {
  WhatsappLogo,
  Phone,
  Envelope,
  InstagramLogo,
  YoutubeLogo,
  FacebookLogo,
} from "@phosphor-icons/react";

function CustomerFooterNew() {
  return (
    <div className="customerfootermain-bg">
      <div className="CustomerFooterNew-main">
        <div>
          <img
            src={Myhraki}
            alt="logo"
            className="CustomerFooterNew-main-logo"
          />
        </div>
        <div className="CustomerFooterNew-main-title">About Myhraki</div>
        <div className="CustomerFooterNew-main-subtitle">
          Myhraki is a new ecommerce boutique marketplace that offers a unique
          and personalized shopping experience. As a customer, you can browse
          through a wide range of products from various categories such as
          fashion, beauty, home décor, and more. Myhraki provides a curated
          selection of products that are carefully chosen based on their
          quality, design, and functionality. One of the key features of Myhraki
          is its user-friendly interface that allows customers to easily
          navigate the website and find the products they are looking for. The
          website is designed to provide a seamless shopping experience, from
          browsing to checkout, making it easy for customers to purchase the
          products they love. In addition to the products, Myhraki offers
          excellent customer service, providing quick and reliable support to
          address any questions or concerns that customers may have. The website
          also offers various payment options, including secure payment gateways
          and easy returns policies, ensuring that customers have
        </div>
      </div>
      <div className="CustomerFooterNew-2ndmain">
        <div className="CustomerFooterNew-2ndmain-sub1">
          <div className="CustomerFooterNew-2ndmain-sub1-title">
            Need Help ?
          </div>
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
            Track order
          </div>
          <hr />
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
            Cancel order
          </div>
          <hr />
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
            Shipping
          </div>
          <hr />
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">Payment</div>
          <hr />
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">FAQs</div>
        </div>
        <div className="CustomerFooterNew-2ndmain-sub1">
          <div className="CustomerFooterNew-2ndmain-sub1-title">My Account</div>
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">Profile</div>
          <hr />
          <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
            Settings
          </div>
        </div>
        <div className="CustomerFooterNew-2ndmain-sub111">
          <div className="CustomerFooterNew-2ndmain-sub1">
            <div className="CustomerFooterNew-2ndmain-sub1-title">
              Get In touch
            </div>
            <div className="gaps-bootom">
              <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
                hello@myhraki.com
              </div>

              <div className="CustomerFooterNew-2ndmain-sub123">
                <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
                  80507 97094
                </div>
                <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
                  Mon - Sat, 9 am to 7 pm
                </div>
              </div>

              <div className="CustomerFooterNew-2ndmain-sub1-subtitle">
                Chat with us on WhatsApp
              </div>
            </div>
          </div>
          <div className="CustomerFooterNew-2ndmain-sub1">
            <div className="empty"></div>
            <div className="bottomenvelop">
              <Envelope size={28} weight="thin" />
            </div>
            <div className="bottomcall">
              <Phone size={28} weight="fill" />
            </div>
            <div className="bottomwhatsapp">
              <WhatsappLogo size={28} />
            </div>
          </div>
        </div>
      </div>

      <div className="CustomerFooterNew-3ndmain">
        <div className="CustomerFooterNew-3ndmain--col">
          <div>Sign Up for MyhraKi Emails</div>
          <div>
            <div className="butn-to-suscribe">
              <input
                type="email"
                placeholder="Enter your mail ID"
                className="butn-to-suscribe-input"
              />
              <button className="butn-to-suscribe-btn">Subscribe</button>
            </div>
          </div>
        </div>
        <div className="CustomerFooterNew-3ndmain--col">
          <div className="CustomerFooterNew-3ndmain--col-flex">
            <div>
              <InstagramLogo size={32} weight="thin" />
            </div>
            <div>
              <FacebookLogo size={32} weight="thin" />
            </div>
            <div>
              <YoutubeLogo size={32} weight="thin" />
            </div>
          </div>
          <div>Copyrights © MyhraKi. All rights Reserved</div>
        </div>
      </div>
    </div>
  );
}

export default CustomerFooterNew;

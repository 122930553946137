import React, { useContext } from "react";
// import AdminLayout from "./AdminLayout";
import { useState } from "react";
import "../styles/AdminInvite.css";
import Heading from "../components/Heading";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { API_BASE_URL } from "../App";

import * as yup from "yup";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import { AdminContext } from "../context/AdminContext";
import { useNavigate } from "react-router-dom";
// import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import withAdminAuth from "../context/withAdminAuth";
import { errorAlert, successAlert } from "../components/Alert";
//import notif from "../components/Notify";
import { Select, MenuItem } from "@mui/material";

function AdminInvite() {
  const { adminData, accessToken } = useContext(AdminContext);
  const [selectedType, setSelectedType] = useState("");

  const passwordRegExp = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,20}$)"
  );
  const phoneRegExp = /^([6789]{1})([\d]{3})[(\D\s)]?[\d]{3}[(\D\s)]?[\d]{3}$/;
  const emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const fullNameRegExp =
    /^(?!.\s{2})(?!.[.'-]{2})[A-Z][A-Za-z' ]{0,48}[A-Za-z]$/;

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!accessToken) {
  //     navigate('/')
  //   }
  // }, [accessToken]);

  const validationSchema = yup.object({
    adminEmail: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .matches(emailRegExp, "Email is not valid")
      .trim()
      .required("Email is required"),
    adminPassword: yup
      .string("Enter your password")
      .min(8, "Invalid Password")
      .matches(passwordRegExp, "Password is not valid")
      .required("Password is required"),
    adminPhone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is required")
      .min(10, "Phone number must consist of 10 digits")
      .trim()
      .max(10, "Phone number must consist of 10 digits"),
    adminName: yup
      .string("Enter Your First Name")
      .matches(fullNameRegExp, "Name is invalid")
      .trim()
      .required("Name is required"),
  });

  const formikAdminInvite = useFormik({
    initialValues: {
      adminEmail: "",
      adminPassword: "",
      adminName: "",
      adminPhone: "",
      typeOfUserId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/users/api/v1/registration`,
          {
            method: "post",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              email: formikAdminInvite.values.adminEmail.trim(),
              password: formikAdminInvite.values.adminPassword,
              firstName: formikAdminInvite.values.adminName.trim(),
              mobileNumber: formikAdminInvite.values.adminPhone.trim(),
              typeOfUserId: selectedType,
              adminId: adminData.result.userID,
              dateOfBirth: "",
            }),
          }
        );
        const data = await response.json();
        if (data?.status === 401) {
          errorAlert("Session timed out. Please Log in");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          navigate("/");
        }
        if (data.result) {
          //notify('Admin Account created Successfully.')
          successAlert("", "Admin Invited Successfully");
          formikAdminInvite.setFieldValue("adminEmail", "");
          formikAdminInvite.setFieldValue("adminPassword", "");
          formikAdminInvite.setFieldValue("adminPhone", "");
          formikAdminInvite.setFieldValue("adminName", "");
        } else {
          errorAlert("", "Could not add Admin. Please try Again.");
        }
      } catch (error) {
        errorAlert("", "Oops! An error occured . Request Problem");
        //console.error(error);
      }
    },
  });

  return (
    <AdminLayoutAdjustment>
      <Heading text="Admin Invite" />
      <div className="admin-invite-dropdown">
        <Select
          name="typeOfUserId"
          value={selectedType}
          onChange={(event) => setSelectedType(event.target.value)}
          fullWidth
          variant="outlined"
          className="admin-invite-select"
          error={
            formikAdminInvite.touched.typeOfUserId &&
            Boolean(formikAdminInvite.errors.typeOfUserId)
          }
          helperText={
            formikAdminInvite.touched.typeOfUserId &&
            formikAdminInvite.errors.typeOfUserId
          }
        >
          <MenuItem value="">Select Type of User</MenuItem>
          <MenuItem value="1">SUADM</MenuItem>
          <MenuItem value="2">BYR</MenuItem>
          <MenuItem value="3">SLADM</MenuItem>
          <MenuItem value="4">SLUSR</MenuItem>
          <MenuItem value="5">LADM</MenuItem>
          <MenuItem value="6">LUSR</MenuItem>
        </Select>
      </div>
      <div className="admin-page-content">
        <form
          className="admin-invite-card"
          onSubmit={formikAdminInvite.handleSubmit}
        >
          <div className="admin-invite-form-field">
            <div className="admin-invite-form-field-name">Admin Name*:</div>
            <div className="admin-invite-form-field-textfield">
              <TextField
                fullWidth
                autoFocus
                variant="outlined"
                type="text"
                className="admin-invite-textfield"
                name="adminName"
                value={formikAdminInvite.values.adminName}
                onChange={formikAdminInvite.handleChange}
                error={
                  formikAdminInvite.touched.adminName &&
                  Boolean(formikAdminInvite.errors.adminName)
                }
                helperText={
                  formikAdminInvite.touched.adminName &&
                  formikAdminInvite.errors.adminName
                }
              />
            </div>
          </div>
          <div className="admin-invite-form-field">
            <div className="admin-invite-form-field-name">Admin Email*:</div>
            <div className="admin-invite-form-field-textfield">
              <TextField
                fullWidth
                className="admin-invite-textfield"
                name="adminEmail"
                value={formikAdminInvite.values.adminEmail}
                onChange={formikAdminInvite.handleChange}
                variant="outlined"
                type="text"
                error={
                  formikAdminInvite.touched.adminEmail &&
                  Boolean(formikAdminInvite.errors.adminEmail)
                }
                helperText={
                  formikAdminInvite.touched.adminEmail &&
                  formikAdminInvite.errors.adminEmail
                }
              />
            </div>
          </div>
          <div className="admin-invite-form-field">
            <div className="admin-invite-form-field-name"> Phone Number*:</div>
            <div className="admin-invite-form-field-textfield">
              <TextField
                fullWidth
                className="admin-invite-textfield"
                name="adminPhone"
                value={formikAdminInvite.values.adminPhone}
                onChange={formikAdminInvite.handleChange}
                variant="outlined"
                type="text"
                error={
                  formikAdminInvite.touched.adminPhone &&
                  Boolean(formikAdminInvite.errors.adminPhone)
                }
                helperText={
                  formikAdminInvite.touched.adminPhone &&
                  formikAdminInvite.errors.adminPhone
                }
              />
            </div>
          </div>
          <div className="admin-invite-form-field">
            <div className="admin-invite-form-field-name">Password*:</div>
            <div className="admin-invite-form-field-textfield">
              <TextField
                fullWidth
                className="admin-invite-textfield"
                name="adminPassword"
                value={formikAdminInvite.values.adminPassword}
                onChange={formikAdminInvite.handleChange}
                variant="outlined"
                type="text"
                error={
                  formikAdminInvite.touched.adminPassword &&
                  Boolean(formikAdminInvite.errors.adminPassword)
                }
                helperText={
                  formikAdminInvite.touched.adminPassword &&
                  formikAdminInvite.errors.adminPassword
                }
              />
            </div>
          </div>
          <div className="admin-invite-button">
            <input type="submit" value="Admin Invite" className="common-btn" />
          </div>
        </form>
        {/* //<ToastContaner theme="coloured" className="toast-message" /> */}
      </div>
    </AdminLayoutAdjustment>
  );
}

export default withAdminAuth(AdminInvite);

import React, { useState, useRef, useEffect } from "react";
import {
  CaretDown,
  User,
  ShoppingCart,
  MagnifyingGlass,
  List,
  Heart,
  XCircle,
} from "@phosphor-icons/react";
import { Trash } from "@phosphor-icons/react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import img1 from "../Images/cart-img1-new.png";
import { Menu, MenuItem, Button } from "@mui/material";
import "../styles/CustomerLandingPage-Nav-Noti-Slide1-GC-Footer.css";
import { useLocation, useNavigate } from "react-router-dom";
const ItemDetailsComponent = () => {
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "Button Down Dress",
      price: 2000,
      originalPrice: 2200,
      image: img1,
      color: "Beige",
      sizes: ["Small", "Medium", "Large", "X-Large"],
      selectedSize: "Small",
      quantity: 1,
    },
    {
      id: 2,
      name: "Casual T-Shirt",
      price: 1000,
      originalPrice: 1200,
      image: img1,
      color: "Blue",
      sizes: ["Small", "Medium", "Large"],
      selectedSize: "Medium",
      quantity: 1,
    },
  ]);

  const handleIncrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrement = (id) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleSizeChange = (id, size) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, selectedSize: size } : item
      )
    );
  };

  const removeItem = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <div className="CustomerNewCart-subheading-section2">
      {cartItems.map((item) => (
        <div
          key={item.id}
          className="CustomerNewCart-subheading-section-itemlist"
        >
          <div className="CustomerNewCart-subheading-section-itemlist-sub1">
            <img
              src={item.image}
              alt={item.name}
              className="CustomerNewCart-subheading-itemlist-img"
            />

            <div className="CustomerNewCart-subheading-itemlist-deatils">
              <div className="CustomerNewCart-subheading-itemlist-deatils-head">
                <div className="CustomerNewCart-subheading-itemlist-deatils-heading">
                  {item.name}
                </div>
                <Trash
                  size={18}
                  style={{ color: "hsla(359, 99%, 67%, 1)", cursor: "pointer" }}
                  onClick={() => removeItem(item.id)}
                />
              </div>
              <div className="CustomerNewCart-subheading-itemlist-deatils-price">
                <div className="CustomerNewCart-subheading-itemlist-deatils-price1">
                  {item.price}
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-price2">
                  {item.originalPrice}
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-price-d">
                  MRP Inclusive of all taxes
                </div>
              </div>
              <div className="CustomerNewCart-subheading-itemlist-deatils-buttons">
                <div className="CustomerNewCart-subheading-itemlist-deatils-button1">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="CustomerNewCart-subheading-itemlist-deatils-button1-img"
                  />
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button1-color-size">
                    Color : {item.color}
                  </div>

                  <div className="CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName">
                    Size : {item.selectedSize}
                  </div>
                  <div className="CustomerNewCart-size-dropdown-wrapper">
                    <ArrowDropDownIcon
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCartItems((prevItems) =>
                          prevItems.map((curr) =>
                            curr.id === item.id
                              ? { ...curr, showDropdown: !curr.showDropdown }
                              : curr
                          )
                        )
                      }
                    />
                    {item.showDropdown && (
                      <div className="CustomerNewCart-subheading-itemlist-dropdown-menu">
                        {item.sizes.map((size) => (
                          <div
                            key={size}
                            className="CustomerNewCart-subheading-itemlist-dropdown-item"
                            onClick={() => handleSizeChange(item.id, size)}
                          >
                            {size}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-button2">
                  <RemoveIcon
                    style={{ color: "black", cursor: "pointer" }}
                    onClick={() => handleDecrement(item.id)}
                  />
                  <div className="CustomerNewCart-subheading-itemlist-deatils-number">
                    {item.quantity}
                  </div>
                  <AddIcon
                    style={{ color: "black", cursor: "pointer" }}
                    onClick={() => handleIncrement(item.id)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
function CustNavBarNew() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleCartClick = () => {
    navigate("/CustomerNewCart");
  };
  const handleAccount = () => {
    navigate("/myaccount");
  };
  const handleHome = () => {
    navigate("/");
  };
  const handleGiftCart = () => {
    navigate("/shopgiftcard");
  };
  const handleBlog = () => {
    navigate("/Blogs");
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  // Open and close the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  // Close the sidebar on outside click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    if (sidebarOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [sidebarOpen]);
  const [heartDropdownOpen, setHeartDropdownOpen] = useState(false);
  const heartDropdownRef = useRef(null);

  // Toggle Dropdown
  const toggleHeartDropdown = () => {
    setHeartDropdownOpen(!heartDropdownOpen);
  };

  // Close Dropdown
  const toggleHeartDropdownClose = () => {
    setHeartDropdownOpen(false);
  };
  const isShopGiftCardPage = location.pathname === "/shopgiftcard";
  const isShopBlogPage = location.pathname === "/Blogs";

  return (
    <div>
      <div className="CustNavBarNew-section">
        <div className="CustNavBarNew-mobile-menu-icon" onClick={toggleSidebar}>
          <List size={24} />
        </div>

        <div className="CustNavBarNew-section1">
          <div
            className="CustNavBarNew-section1-title"
            onClick={handleHome}
            style={{ cursor: "pointer" }}
          >
            Myhraki
          </div>
          <div className="CustNavBarNew-sectionsub-1">
            <Button
              onClick={handleClick}
              endIcon={<CaretDown size={16} style={{ marginLeft: "3px" }} />}
              style={{ color: "black", textTransform: "none" }}
            >
              Categories
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Category 1</MenuItem>
              <MenuItem onClick={handleClose}>Category 2</MenuItem>
              <MenuItem onClick={handleClose}>Category 3</MenuItem>
            </Menu>
            <div>Trending </div>
            <div
              onClick={handleBlog}
              style={{ color: isShopBlogPage ? "#FE585B" : "black" }}
            >
              Blogs
            </div>
            <div
              onClick={handleGiftCart}
              style={{ color: isShopGiftCardPage ? "#FE585B" : "black" }}
            >
              GiftCart
            </div>
          </div>
        </div>
        <div className="CustNavBarNew-section2">
          <div className="CustNavBarNew-search-container">
            <MagnifyingGlass size={18} className="search-icons-cuslandnew" />
            <input
              type="text"
              className="CustNavBarNew-search-input"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="CustNavBarNew-section-sub2">
            <User
              size={22}
              weight={
                ["/myaccount"].includes(location.pathname) ? "fill" : "regular"
              }
              onClick={handleAccount}
              style={{
                cursor: "pointer",
                color: ["/myaccount"].includes(location.pathname)
                  ? "#FE585B"
                  : "hsla(0, 0%, 0%, 1)",
              }}
            />
            <ShoppingCart
              size={22}
              weight={
                ["/CustomerNewCart", "/CustomerNewCheckOut"].includes(
                  location.pathname
                )
                  ? "fill"
                  : "regular"
              }
              onClick={handleCartClick}
              style={{
                cursor: "pointer",
                color: ["/CustomerNewCart", "/CustomerNewCheckOut"].includes(
                  location.pathname
                )
                  ? "#FE585B"
                  : "hsla(0, 0%, 0%, 1)",
              }}
            />
            <Heart
              size={22}
              style={{
                cursor: "pointer",
                position: "relative",
                color: heartDropdownOpen ? "#FE585B" : "inherit",
              }}
              weight={heartDropdownOpen ? "fill" : "regular"}
              onClick={toggleHeartDropdown}
            />
            {heartDropdownOpen && (
              <div
                ref={heartDropdownRef}
                className="custnavbarnew-heart-dropdown"
              >
                <div className="custnavbarnew-heart-dropdown-section">
                  <div>My Wishlist</div>
                  <XCircle
                    size={22}
                    weight="bold"
                    onClick={toggleHeartDropdownClose}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="custnavbarnew-heart-dropdown-wishlist-section">
                  <ItemDetailsComponent />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Sidebar for Mobile */}
      <div
        ref={sidebarRef}
        className={`sidebar ${sidebarOpen ? "sidebar-open" : ""}`}
      >
        <div className="CustNavBarNew-section1-Mob-section">
          <div
            className="CustNavBarNew-section1-Mob-title"
            onClick={handleHome}
          >
            Myhraki
          </div>
          <div>
            <Button
              onClick={handleClick}
              endIcon={<CaretDown size={16} style={{ marginLeft: "5px" }} />}
              style={{ color: "black" }}
            >
              Categories
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Category 1</MenuItem>
              <MenuItem onClick={handleClose}>Category 2</MenuItem>
              <MenuItem onClick={handleClose}>Category 3</MenuItem>
            </Menu>
          </div>
          <div className="CustNavBarNew-section1-Mob-subtitle">
            <div>Trending New</div>
            <div onClick={handleBlog}>Blogs</div>
            <div onClick={handleGiftCart}>GiftCart</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustNavBarNew;

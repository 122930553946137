import React, { useState } from "react";
import "../styles/CustomernewLandingBlogs.css";
import Nc1 from "../Images/blog1.png";
import Nc2 from "../Images/blog2.png";

function CustomerLandingNewBlogs() {
  const images = [
    {
      src: Nc1,
      title: "DHALAPATHARA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone.Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone.  Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc2,
      title: "THALA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc1,
      title: "BALA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc2,
      title: "GOA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc1,
      title: "KARNATKA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc2,
      title: "PUNJAB",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc1,
      title: "CHENNIA",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
    {
      src: Nc2,
      title: "TMAIL NADU",
      para: "Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha Dhalapathara saree used to be the first choice of everyone. Dha",
      datetime: "01 Nov 2023 | 9min Read",
    },
  ];

  const [expandedId, setExpandedId] = useState(null);

  const handleReadMore = (index) => {
    setExpandedId(index);
  };

  const renderCard = (image, index) => {
    const words = image.para.split(" ");
    const displayedText =
      words.length > 20 ? words.slice(0, 20).join(" ") + "..." : image.para;

    return (
      <div key={index} className="customerBlogsPage-card">
        <img src={image.src} alt={image.title} style={{ width: "100%" }} />
        <div className="customerBlogsPage-cards-title-section">
          <div className="customerBlogsPage-cards-title1">{image.title}</div>
          <div className="customerBlogsPage-cards-title2">
            {displayedText}{" "}
            {words.length > 20 && (
              <span
                className="customerBlogsPage-cards-title2-span"
                onClick={() => handleReadMore(index)}
                style={{ cursor: "pointer", color: "#fe585b" }}
              >
                Read more
              </span>
            )}
          </div>
          <div className="customerBlogsPage-cards-title3">{image.datetime}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {expandedId === null && (
        <>
          <div className="customer-landing-blogs-title">Blog</div>
          <div className="customer-landing-blogs-btns">
            <div className="customer-landing-blogs-btn1">
              Indian Handicrafts
            </div>
            <div className="customer-landing-blogs-btn2">Indian Artisans</div>
          </div>
          <div className="customerBlogsPage-cards">
            {images.map((image, index) => renderCard(image, index))}
          </div>
        </>
      )}

      {expandedId !== null && (
        <div className="blog-expanded-section">
          <div className="blogs-read-more-sec">
            <img
              src={images[expandedId].src}
              alt={images[expandedId].title}
              className="image-of-readmore"
            />
            <div className="image-of-readmore-t1">
              {images[expandedId].title}
            </div>
            <div className="image-of-readmore-t2">
              {images[expandedId].para}
            </div>
          </div>
          <div className="redamore-heading-customer-landingcards">
            Read More
          </div>
          <div className="customerBlogsPage-cards">
            {images
              .filter((_, i) => i !== expandedId) // Ensure correct index filtering
              .slice(0, 4) // Show limited cards
              .map((image, idx) => renderCard(image, idx))}{" "}
            {/* Corrected index usage */}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomerLandingNewBlogs;

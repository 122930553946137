import * as React from "react";
import Box from "@mui/joy/Box";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import "../styles/CustomerOrderModal.css";
import "../styles/RatingCardComponent.css"
import { useState, useEffect } from "react";
import { CustomerContext } from "../context/CustomerContext";
import { useContext } from "react";
import { Rating } from "@mui/material";
import "../styles/Common.css";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CircularProgress } from '@mui/material';
import { errorAlert } from './Alert';
import {  CheckCircle } from '@mui/icons-material';
import { v4 as uuidv4 } from "uuid";
import imageUploader from "../Images/imgFrame.png";
import closeIcon from "../Images/closeIncon.png";
import { IconButton } from "@mui/joy";
import { API_BASE_URL } from "../App";
import ToastComponent from "./ToastComponent";
import { useRef } from "react";
import InviteSeller from "../pages/InviteSeller";

export default function CustomerOrderModal({ isOpen, productId, onClose, refId,itemDetails, myOrdersData, selectedTab, ...props }) {
console.log(props)
  const { login, accessToken, setSelectedOrder, selectedOrder , handleAuthCheck, handleAuthExpiry, logout} = useContext(CustomerContext);
  const { customerData } = useContext(CustomerContext);
  const [imageLoading, setImageLoading] = useState(false);
  const [productLoading, setProductLoading] = useState();
  const [imageName, setImageName] = useState('')
  //console.log(selectedTab);
  // const [currentOrderDetail, setCurrentOrderDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [processLoading, setProcessLoading] = useState(false);
  const [uploadedReviewImage, setUploadedReviewIamge] = useState(null);
  const [orderStatus] = useState(props.state);
  const [thisOrderDetail, setThisOrderDetail] = useState();
  const [thisProductDetails, setThisProductDetails] = useState();
  // const [tab, setTab] = useState(selectedTab);
  const [openModal, setOpenModal] = useState(false);
  // //console.log(refId)
  const toastRef = useRef();
  const [invoiceData, setInvoiceData] = useState(props.invoiceData)

  



  async function fetchProductData(sku) {
    setProductLoading(true);
    try {

      const response = await fetch(`${API_BASE_URL}/productgen/api/v1/get?sku=${sku}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      setProductLoading(false);
      //console.log(response)
      handleAuthCheck(response);
      const data = await response.json();
      //console.log(data?.result[0]);
      setThisProductDetails(data?.result[0]);
    }
    catch {
      setProductLoading(false);

      //console.log('Error')
    }
  }

  useEffect(() => {
    //console.log(selectedOrder);
    //console.log(refId)
    // props.setRefId(refId)
    if (selectedOrder === refId && selectedOrder && selectedTab==='review') {
      // //console.log(itemDetails)
      // //console.log(myOrdersData)
      const data = (myOrdersData?.filter(order => selectedOrder === order?.orderitemNumber))
      // setThisOrderDetail(myOrdersData?.filter(order => selectedOrder === order?.order_RefrenceId))
      fetchProductData(data?.[0]?.sku)

    } 
  }, [])




  const [ratingValue, setRatingValue] = useState(0);

  const handleRatingChange = (event, newValue) => {
    setRatingValue(newValue);
  };
  const ind = itemDetails.findIndex(item=>item?.orderitemNumber===selectedOrder);

  const [reviewMessage, setReviewMessage] = useState('');
  const [returnMessage, setReturnMessage] = useState('');

  const [cancelReason, setCancelReason] = React.useState(null);

  const handleChange = (event) => {
    //console.log(event.target.value, cancelReason)
    setCancelReason(event.target.value);
  };

  function removeLastHyphenAndAfter(input) {
    var lastHyphenIndex = input.lastIndexOf("-");
    if (lastHyphenIndex >= 0) {
      return input.substring(0, lastHyphenIndex);
    }
    return input;
  }

  const handleReplace = async (index) => {
    setProcessLoading(true);
    const ind = itemDetails.findIndex(item=>item?.orderitemNumber===selectedOrder);
    try {
      const response = await fetch(`${API_BASE_URL}/orders/api/v1/cancel/replace/return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "orderid": removeLastHyphenAndAfter(selectedOrder),
          "itemid": selectedOrder,
          "skuid": itemDetails?.[ind]?.sku,
          "amount": parseInt(itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity+100+((itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity)*parseFloat(itemDetails?.[ind]?.gst/100))),
          "customerOrderstatus": "Replace",
          "quantity": itemDetails?.[ind]?.quantity,
          "shipmentstatus": "DC",
          "cancelOrderComment": cancelReason==="Other"?returnMessage:cancelReason,
          
        }),
      });
      setProcessLoading(false);
      setCancelReason('');
        // toastRef.current.showToast("Replace request successful",'success');
        const data = await response.json()
        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
          logout();
          return;
        
      }
      if (response.ok) {
        // successAlert("Replace request successful");
        toastRef.current.showToast("Replace request successful",'success');
        // setReviewText(''); 
        setTimeout(()=>{
          onClose();
          props.setCurrentTab('orders')
          props.setUpdated(true);

        }, 2000)
      } else {
        // errorAlert("Failed to place replace request");
        onClose();
        
        toastRef.current.showToast("Failed to place replace request",'error');
      


      }
    } catch {
      setProcessLoading(false);
      setCancelReason('');
      onClose();
     toastRef.current.showToast("Failed to place replace request",'error');
    }
  }
  const handleReturn = async () => {
    setLoading(true);
    const ind = itemDetails.findIndex(item => item?.orderitemNumber === selectedOrder);
  
    try {
      if (!cancelReason || (cancelReason === "Other" && !returnMessage)) {
        // Show error message if reason field is empty
        toastRef.current.showToast("Please provide a reason for return", 'error');
        setLoading(false);
        return;
      }
  
      const response = await fetch(`${API_BASE_URL}/orders/api/v1/cancel/replace/return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "orderid": removeLastHyphenAndAfter(selectedOrder),
          "itemid": selectedOrder,
          "skuid": itemDetails?.[ind]?.sku,
          "amount": parseInt(itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity+100+((itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity)*parseFloat(itemDetails?.[ind]?.gst/100))),
          "customerOrderstatus": "Return",
          "quantity": itemDetails?.[ind]?.quantity,
          "shipmentstatus": "DC",
          "cancelOrderComment": cancelReason === "Other" ? returnMessage : cancelReason
        }),
      });
      setLoading(false);
      setCancelReason('');
      const data = await response.json()
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      }
  
      if (response.ok) {
        // Success
        toastRef.current.showToast("Return request successful", 'success');
  
        setTimeout(() => {
          onClose();
          props.setCurrentTab('orders');
          props.setUpdated(true);
        }, 2000);
      } else {
        // Error handling
        onClose();
        setProcessLoading(false);
        toastRef.current.showToast("Failed to place Return request", 'error');
      }
    } catch {
      setProcessLoading(false);
      setCancelReason('');
      onClose();
      toastRef.current.showToast("Failed to place Return request", 'error');
    }
  };
  

  const handleCancel = async () => {
   
    setProcessLoading(true);
    if(cancelReason === null || cancelReason=== undefined || cancelReason?.length<=1){
        toastRef.current.showToast("Cancel reason is required",'error');
        setTimeout(()=>{
        }, 2000);
        setLoading(false);
        setProcessLoading(false);

      return;
    }
    const data = await checkCanceOrder(selectedOrder);

    if(data===true){
      // console.log('Working')
      toastRef.current.showToast("Cannot Cancel. Order is out for delivery.",'error');
      setProcessLoading(false);
      return;
    }



    try {
      const response = await fetch(`${API_BASE_URL}/orders/api/v1/cancel/replace/return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json-patch+json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "orderid": removeLastHyphenAndAfter(selectedOrder),
          "itemid": selectedOrder,
          "skuid": itemDetails?.[ind]?.sku,
          "amount": parseInt(itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity+100+((itemDetails?.[ind]?.itemCost*itemDetails?.[ind]?.quantity)*parseFloat(itemDetails?.[ind]?.gst/100))),
          "customerOrderstatus": "Canceled",
          "quantity": itemDetails?.[ind]?.quantity,
          "shipmentstatus": itemDetails?.[ind]?.shipmentStatus==="Pending"?"OPG": itemDetails?.[ind]?.shipmentStatus==="DC"?"OPG":itemDetails?.[ind]?.shipmentStatus,
          "cancelOrderComment": cancelReason
        }),
      });
      setProcessLoading(false);
      setCancelReason('');
      const data = await response.json()
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
    }
      if (response.ok) {
        //console.log(response)
        toastRef.current.showToast("Cancel request successful",'success');
        setTimeout(()=>{
          onClose();
          props.setCurrentTab('orders')
          props.setUpdated(true);

        }, 2000)
      } else {
        setCancelReason('');
        toastRef.current.showToast("Cancel request unsuccessful",'error');
         onClose();
        // errorAlert("Failed to place Cancel request");
      }
    } catch {
      setProcessLoading(false);
      setCancelReason('');
      onClose();
      toastRef.current.showToast("Cancel request unsuccessful",'error');
      // toastRef.current.showToast("Password Update failed", "error");
      // errorAlert("Failed to place Cancel request");
    }
  }

  const checkCanceOrder = async (itemId)=>{
    try{
      const response = await fetch(`${API_BASE_URL}/orders/api/v1/os/list?itemid=${itemId}
      `,{
        headers:{
          Authorization:`Bearer ${accessToken}`
        }
      });
      const data = await response?.json();
      
      // console.log(response);
      if(data?.result?.length===0){
        return false;
      }
      else if(data?.result[data?.result?.length-1].statusCode==='DC' || data?.result[data?.result?.length-1].itemId?.startsWith('QC')){
        return true;
      }else{
        return false;
      }
      // return response;
    }catch{

    }
  }

  const [reviewText, setReviewText] = useState('');

  const handleSubmitReview = async () => {
    setProcessLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/feedback/api/v1/Add`, {
        method: "POST",
        headers: {
          "Content-Type": " application/json-patch+json",
          "Authorization": `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "userId": customerData?.result?.userID,
          "productId": thisProductDetails?.productId,
          "rating": ratingValue,
          "content": reviewMessage,
          "fileNameWithExtension": [imageName]
        }),
      });
      setProcessLoading(false);
      setReviewText('');
      const data = await response.json()
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        logout();
        return;
      
    }
      if (response.ok) {
       
        //console.log("Review added successfully"); // Debugging
        toastRef.current.showToast("Review added successfully", 'success');
        setTimeout(()=>{
          onClose();
          props.setCurrentTab('orders')
          props.setUpdated(true);

        }, 2000)
      } else {
        // setReviewText('');
        setLoading(false);
        // onClose();
        //console.log("Failed to add review"); // Debugging
        toastRef.current.showToast("Failed to add review", 'error');
        onClose();
      }
    } catch (error) {
      onClose();
      //console.log("Error:", error); 
      toastRef.current.showToast("Failed to add review", 'error');
    }
  };
  const handleClose = () => {
    console.log('Closing modal');
    setOpenModal(false);
    
  };
  const [replaceTrue,setReplaceTrue] =useState(false);
  // const [invoiceData, setInvoiceData] = useState([]);
  // const fetchInvoiceData = async (selectedOrder) => {
  //   setLoading(true);

  //   try {
  //     const response = await fetch(`${API_BASE_URL}/orders/api/v1/inv/${selectedOrder}`, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`, // Assuming accessToken is defined
  //       },
  //       // You might not need the body for a GET request, adjust accordingly
  //     });

  //     const data = await response.json();
  //     setLoading(false);
  
  //     if (data?.result) {
  //       // Check if data.result is defined before reversing
  //       console.log('Invoice Data:', data.result);
  //       setInvoiceData(data.result.reverse());
  //       return data;
  //     } else {
  //       console.error('Error: Data or data.result is undefined');
  //       // Handle the case where data.result is undefined
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error('Error fetching invoice data:', error);
  //     // Handle the error as needed
  //   }
  // };
  // useEffect(() => {
  //   fetchInvoiceData();
  // }, [selectedOrder]);
 
  return (
  
    <React.Fragment>

      <Modal open={isOpen} onClose={onClose}>
        <ModalDialog
          aria-labelledby="nested-modal-title"
          aria-describedby="nested-modal-description"
          sx={(theme) => ({
            [theme.breakpoints.only("xs")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
              maxWidth:'100%'
              
            },
            [theme.breakpoints.only("sm")]: {
              top: "unset",
              bottom: 0,
              left: 0,
              right: 0,
              borderRadius: 0,
              transform: "none",
              maxWidth: "unset",
              overflow: "scroll",
            },
            padding: '15px',
            borderRadius: 16,
          })}
        >
          <Box
            sx={{
              padding: '0px',
              display: "flex",
              //   gap: 1,
              width: { xs: "100%", sm:'100%',md: "700px" },
              flexDirection: { xs: "column", sm: "column" },
              alignItems: "flex-start",
              justifyContent: "space-center",
              overflow: "scroll", 
              "::-webkit-scrollbar": {
                width:'0px',
                background: "transparent !important"
              },
              "::-scrollbar": {
                width:'0px',
                background: "transparent !important"
              }
            }}
          >
                  <ToastComponent ref={toastRef} timeout={4000} />

            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              sx={{
                position: 'absolute',
                top: '10px',
                right: '10px', // Adjust positioning as needed
              }}
            >
              <img loading="lazy" src={closeIcon} alt="" />
            </IconButton>
            <div>
              {selectedTab === "cancel" && (
                <div className="customer-order-modal-login">Cancel Order</div>
              )}
              {selectedTab === "review" && (
                <div className="customer-order-modal-login">Review  Product</div>
              )}
              {selectedTab === "return" && (
                <div className="customer-order-modal-login">Order Return </div>
              )}
              {selectedTab === "track" && (
                <div className="customer-order-modal-login">Track  Item</div>

              )}
              {selectedTab === "review" && (productLoading===true?      
                    <div style={{ display: "flex", width:"100%",height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "#FD2A80", height: '100px', width: "100px" }} /></div>                    :(
                <div className="review-modal-container">
                 <div className="order-details-product-info-buyer">
                    <img loading="lazy" className="order-details-product-image" src={itemDetails?.[ind]?.image} />
                    <div className="order-details-product-details">
                      <div className="order-details-product-name">{itemDetails?.[ind]?.title}</div>
                      <div className="order-details-product-size-color-qty">
                      
                     Quantity : {itemDetails?.[ind]?.quantity}                         
                      </div>
                     
                    </div>
                  </div>
                  <div className="rating-container-field">
                    <div className='order-details-subhead'>Ratings</div>
                    <div className="custom-rating-star-field">
                      <Rating
                        name="custom-rating"
                        value={ratingValue}
                        onChange={handleRatingChange}
                        precision={1}
                        max={5}
                      /></div>
                  </div>
                  <div className="rating-container-field">

                    <div className='order-details-subhead'>Add photo/video</div>
                    <div style={{display:"flex",alignItems:"center"}}>
                    <label className="choose-file-btn" style={{background:'white'}}>
                      <img loading="lazy" src={imageUploader} alt=""  style={{width:"100px" ,height:"100px"}}/>
                      <input
                        type="file"
                        // accept=".jpg,.jpeg,.png"
                        onChange={async (event) => {
                          setImageLoading(true);
                          const uploadedImage = event.target.files[0];
                          const maxSizeInBytes = 5 * 1024 * 1024;
                          if (uploadedImage.size > maxSizeInBytes) {
                            toastRef.current.showToast("File size exceeds 5 MB limit.", 'error');
                            setImageLoading(false);
                            return;
                          }
                          if (!uploadedImage) {
                            // No file was selected, handle this case gracefully
                            setImageLoading(false);
                            return;
                          }
                          const readFileAsArrayBuffer = (file) => {
                            return new Promise((resolve, reject) => {
                              const fileReader = new FileReader();

                              fileReader.onload = () => {
                                const arrayBuffer = fileReader.result;
                                resolve(new Uint8Array(arrayBuffer));
                              };

                              fileReader.onerror = (error) => {
                                reject(error);
                              };

                              fileReader.readAsArrayBuffer(file);
                            });
                          };

                          try {
                            const uint8Array = await readFileAsArrayBuffer(uploadedImage);

                            // Check if the magic number corresponds to jpg/jpeg/png
                            if (
                              uint8Array.length >= 2 &&
                              uint8Array[0] === 0xFF &&
                              uint8Array[1] === 0xD8
                            ) {
                              // JPG file
                            } else if (
                              uint8Array.length >= 4 &&
                              uint8Array[0] === 0x89 &&
                              uint8Array[1] === 0x50 &&
                              uint8Array[2] === 0x4E &&
                              uint8Array[3] === 0x47 
                            ) {
                              // PNG file
                            } else if (
                              uint8Array.length >= 2 &&
                              uint8Array[0] === 0xFF &&
                              uint8Array[1] === 0xD9
                            ) {
                              // JPEG file (ending with EOI marker)
                            } else {
                              // Invalid file type
                              toastRef.current.showToast("Invalid file Type", 'error');

                              setImageLoading(false);
                              return;
                            }

                          // Check if file type is jpg/jpeg/png
                          const validTypes = ["image/jpeg", "image/png", "image/jpg"];
                          if (!validTypes.includes(uploadedImage.type)){
                            toastRef.current.showToast("Invalid file Type", 'error');
                            setImageLoading(false);
                            return;
                          }
               

                          const fileExtension = uploadedImage.name
                            .split(".")
                            .pop(); // Extract file extension
                          const imageName = `${uuidv4()}.${fileExtension}`; // Generate image name with uuid and file extension

                          const formData = new FormData();
                          formData.append("ContainerName", "ecomdata");
                          formData.append(
                            "FileNameWithExtension",
                            imageName
                          );
                          formData.append("FileCategory", "feedbackFiles");
                          formData.append(
                            "Attachment",
                            uploadedImage,
                            uploadedImage.name
                          );

                            const response = await fetch(
                              `${API_BASE_URL}/blob/api/v1/uploadmediaWithContainer`,
                              {
                                method: "POST",
                                headers: {
                                  Authorization: `Bearer ${accessToken}`,
                                },
                                body: formData,
                              }
                            );

                            if (!response.ok) {
                              toastRef.current.showToast("Something went wrong", 'error');
                            }

                            // Set image variable as the uploaded image name
                            setImageName(imageName);
                            setUploadedReviewIamge(URL.createObjectURL(uploadedImage))

                            // You can also perform other actions after successful upload
                            setImageLoading(false);

                          } catch (error) {

                            setImageLoading(false);

                          }
                        }}
                        name="image"
                      />
                      {/* Upload */}
                    </label>
                    {uploadedReviewImage && <img loading="lazy" src={uploadedReviewImage} alt="" className="uploaded-review-image" />}
                    {imageLoading && (
                      <CircularProgress className="file-submitted" />
                    )}
                    {!imageLoading && imageName !== '' && <CheckCircle className="file-submitted" />}
                    </div>
                  </div>
                  <div className="rating-container-field">
                    <div className='order-details-subhead'>Add a written review</div>
                    <textarea className='textarea-style' placeholder="Enter message here" value={reviewMessage} onChange={(e) => { setReviewMessage(e.target.value) }} />

                    {!processLoading && <div style={{display:"flex",justifyContent:"flex-start"}}><button className="buyer-save-button" onClick={handleSubmitReview}>Submit</button></div>}
                    {processLoading &&   <div  style={{  display: "flex",  width: "100%",alignItems: "center", justifyContent: "center",  }} >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    }
                  </div>
                </div>
              ))}
              {selectedTab === "cancel" && (productLoading===true?                           <div style={{ display: "flex", width:"100%", height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "rgba(227, 144, 2, 1)", height: '100px', width: "100px" }} /></div>:(
                <div className="byr-cancel-order-container">
                  <div className="buyer-cancel-order-date-info">
                    <div className="ordered-date-lighter-field">ordered on 29 August 2023</div>
                    <div className="odered-item-number-field">Order # {selectedOrder}</div>
                    

                  </div>

                  {/* <div className="delivered-date-field">Delivered on : 30 Aug</div> */}
                  <div className="order-details-product-info-buyer">
                    <img loading="lazy" className="order-details-product-image" src={itemDetails?.[ind]?.image} />
                    <div className="order-details-product-details">
                      <div className="order-details-product-name">{itemDetails?.[ind]?.title}</div>
                      <div className="order-details-product-size-color-qty">
                      
                     Quantity : {itemDetails?.[ind]?.quantity}                         
                      </div>
                     
                    </div>
                  </div>

                  <div className="cancel-odrder-reason-container">
                    <div className="cancel-odrder-reason-head">Cancellation Reason </div>
                    <div className="cancel-reason-dropdown">
                      <Box sx={{ minWidth:300 }} style={{minWidth:'300px'}}>
                        <FormControl >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cancelReason}
                            onChange={handleChange}
                          >
                            <MenuItem value={"Order created by mistake"}>Order created by mistake</MenuItem>
                            <MenuItem value={'Item(s) would not arrive on time'}>Item(s) would not arrive on time</MenuItem>
                            <MenuItem value={'Shipping cost too high'}>Shipping cost too high</MenuItem>
                            <MenuItem value={'Item price too high'}>Item price too high</MenuItem>
                            <MenuItem value={'Others'}>Others</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    {!processLoading &&  <button className="modal-submit-button" onClick={handleCancel}>Confirm Cancel item</button>}
                    {processLoading &&  <div  style={{  display: "flex",  width: "100%",alignItems: "center", justifyContent: "center",  }} >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>
                    }
                   {/* {processLoading&& <>loading</>} */}
                  </div>
                </div>

              ))}
              {selectedTab === "return" && (productLoading===true?                            <div style={{ display: "flex",width:"100%", height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "#FD2A80", height: '100px', width: "100px" }} /></div>:(
                <div className="byr-cancel-order-container">
                  <div className="buyer-cancel-order-date-info">
                    <div className="ordered-date-lighter-field">ordered on 29 August 2023</div>
                    <div className="odered-item-number-field">Order #  {selectedOrder}</div>
                  </div>

                  {/* <div className="delivered-date-field">Delivered on : 30 Aug</div> */}
                  <div className="order-details-product-info-buyer">
                    <img loading="lazy" className="order-details-product-image" src={itemDetails?.[ind]?.image} />
                    <div className="order-details-product-details">
                      <div className="order-details-product-name">{itemDetails?.[ind]?.title}</div>
                      <div className="order-details-product-size-color-qty">
                      
                     Quantity : {itemDetails?.[ind]?.quantity}                         
                      </div>
                     
                    </div>
                  </div>
                  <div className="return-order-question-head">Why are you returning  this? </div>
                  <div className="dropdown-return-order">
                    <Box sx={{ minWidth: 120 }} style={{minWidth:'300px'}}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={cancelReason}
                          onChange={handleChange}
                        >
                          <MenuItem value={'Product damaged but shipping OK'}>Product damaged but shipping OK</MenuItem>
                          <MenuItem value={'Missing parts or accessories'}>Missing parts or accessories</MenuItem>
                          <MenuItem value={'Both product and box damaged'}>Both product and box damaged</MenuItem>
                          <MenuItem value={'Wrong item was sent'}>Wrong item was sent</MenuItem>
                          <MenuItem value={'Item defective or doesn’t work'}>Item defective or doesn’t work</MenuItem>
                          <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                  {/* <div>Comment</div> */}
                  {cancelReason==="Other" &&<textarea className='add-return-reason-textarea' value={returnMessage} onChange={(e) => { setReturnMessage(e.target.value) }} placeholder="Enter Reason here" />}
                  <div className="return-order-question-head">How can we make it right?</div>
                  <div className="return-order-radioGroup">
                    <RadioGroup row
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="Replace with the same product"
                      name="radio-buttons-group"
                      value={replaceTrue===true?"Replace":"Return"}
                      onChange={(e)=>{setReplaceTrue(e.target.value==="Replace")}}
                    >
                      <FormControlLabel value={"Replace"} defaultChecked control={<Radio />} label="Replace with the same product" />
                      <FormControlLabel value={"Return"} control={<Radio />} label="Return Product" />
                    </RadioGroup>
                  </div>
                  {/* <Divider /> */}
                  {/* <div className="return-order-question-head">How would you like to return your item?</div> */}
                  {/* <div className="return-order-question-field">Pickup at the time of delivery</div> */}
                  {/* <div className="return-order-answer-field">Your package will be picked up by a courier service at the time of delivery replacement / exchange order items, please keep your return items ready at the time of delivery.</div> */}
                  <div className="return-order-question-field">Pickup Address</div>
                  <div className="retrun-order-addressContainer">
                    <div className="return-order-address-field">{itemDetails?.[0]?.destinationAddress} , {itemDetails?.[0]?.destinationPlace}, {itemDetails?.[0]?.destinationState}, {itemDetails?.[0]?.destinationPincode}</div>
                    {/* <div className="return-change-address-link">Change</div> */}
                  </div>
                  {!processLoading&& !replaceTrue &&<button className="modal-submit-button" onClick={handleReturn}>Confirm Return</button>}
                  {!processLoading&& replaceTrue &&<button className="modal-submit-button" onClick={handleReplace}>Confirm Replace</button>}
                  {processLoading &&<div  style={{  display: "flex",  width: "100%",alignItems: "center", justifyContent: "center",  }} >
                        <CircularProgress style={{ color: "pink" }} />
                      </div>}
                </div>
              ))}
              {selectedTab === "track" && (productLoading===true?                           <div style={{ display: "flex",width:"100%", height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "#FD2A80", height: '100px', width: "100px" }} /></div>:(
                <div className="byr-cancel-order-container">
                <div className="order-details-product-info-buyer">
                    <img loading="lazy" className="order-details-product-image" src={itemDetails?.[ind]?.image} />
                    <div className="order-details-product-details">
                      <div className="order-details-product-name">{itemDetails?.[ind]?.title}</div>
                      <div className="order-details-product-size-color-qty">
                      
                     Quantity : {itemDetails?.[ind]?.quantity}                         
                      </div>
                     
                    </div>
                  </div>
                  {/*  */}
                  <TrackCheckout itemDetails={itemDetails?.filter(item=>item?.orderitemNumber===selectedOrder).map(item=>item)} thisProductDetails={thisProductDetails}/>
                  <div className="track-ordershipping-Address">
                    <div className="shipping-Address-head">Shipping address</div>
                    <div className="shipping-Address-track--desc">{itemDetails?.[0]?.destinationAddress} , {itemDetails?.[0]?.destinationPlace}, {itemDetails?.[0]?.destinationState}, {itemDetails?.[0]?.destinationPincode}</div>
                    <div className="shipping-Address-Order-Id">Order # {selectedOrder}</div>
                  </div>
                </div>
              ))}
              {selectedTab === "viewInvoice" && (loading===true?   <div style={{ display: "flex",width:"100%", height: '100px', alignItems: "center", justifyContent: "center" }}><CircularProgress style={{ color: "#FD2A80", height: '100px', width: "100px" }} /></div>:(
                <div>
                  
                 <InviteSeller invoiceData={props.invoiceData}/>
                 </div>
        
                    ))}

              

            </div>
          </Box>
        </ModalDialog>
      </Modal>
    </React.Fragment>
    
  );
}


const TrackCheckout = ({itemDetails, thisProductDetails}) => {
  const [current] = useState(0);
 
  useEffect(() => {
    document.getElementById("step-1-dot").classList.add("completed_step");
    document.getElementById("step-1-dot").innerText = "✔";
    document.getElementById("step-2-dot").style.backgroundColor =
      "rgba(255, 208, 216, 1)";
    document.getElementById("step-1-line").classList.add("completed_step");
    //console.log(itemDetails)
    if((itemDetails?.[0]?.shipmentStatus==='OPG'||itemDetails?.[0]?.shipmentStatus==='OPD')){
      document.getElementById("step-2-dot").classList.add("completed_step");
      document.getElementById("step-2-dot").innerText = "✔";
      document.getElementById("step-3-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-2-line").classList.add("completed_step");
    }
    if((itemDetails?.[0]?.shipmentStatus==='PBC'||itemDetails?.[0]?.shipmentStatus==='PIT'||itemDetails?.[0]?.shipmentStatus==='QC_Failed' )){
      document.getElementById("step-2-dot").classList.add("completed_step");
      document.getElementById("step-2-dot").innerText = "✔";
      document.getElementById("step-3-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-2-line").classList.add("completed_step");
      document.getElementById("step-3-dot").classList.add("completed_step");
      document.getElementById("step-3-dot").innerText = "✔";
      document.getElementById("step-4-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-3-line").classList.add("completed_step");
    }
    if((itemDetails?.[0]?.shipmentStatus==='OFD'||itemDetails?.[0]?.shipmentStatus==='RDD'||itemDetails?.[0]?.shipmentStatus==='DA'||itemDetails?.[0]?.shipmentStatus==='DF')){
      document.getElementById("step-2-dot").classList.add("completed_step");
      document.getElementById("step-2-dot").innerText = "✔";
      document.getElementById("step-3-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-2-line").classList.add("completed_step");
      document.getElementById("step-3-dot").classList.add("completed_step");
      document.getElementById("step-3-dot").innerText = "✔";
      document.getElementById("step-4-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-3-line").classList.add("completed_step");
      document.getElementById("step-4-dot").classList.add("completed_step");
      document.getElementById("step-4-dot").innerText = "✔";
      document.getElementById("step-5-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      // document.getElementById("step-4-line").classList.add("completed_step");
    }

    if(itemDetails?.[0]?.orderStatus.toLowerCase()==='Completed'.toLowerCase()){
      document.getElementById("step-2-dot").classList.add("completed_step");
      document.getElementById("step-2-dot").innerText = "✔";
      document.getElementById("step-3-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-2-line").classList.add("completed_step");
      document.getElementById("step-3-dot").classList.add("completed_step");
      document.getElementById("step-3-dot").innerText = "✔";
      document.getElementById("step-4-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-3-line").classList.add("completed_step");
      document.getElementById("step-4-dot").classList.add("completed_step");
      document.getElementById("step-4-dot").innerText = "✔";
      document.getElementById("step-5-dot").style.backgroundColor =
        "rgba(255, 208, 216, 1)";
  
      document.getElementById("step-4-line").classList.add("completed_step");
      document.getElementById("step-5-dot").classList.add("completed_step");
      document.getElementById("step-5-dot").innerText = "✔";
    
  
      // document.getElementById("step-4-line").classList.add("completed_step");
    }

   
  }, [])

  return (
    <div class="order-track">
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 0 && (
            <span
              class="order-track-status-dot"
              id="step-1-dot"
              style={{ backgroundColor: "rgba(255, 208, 216, 1)" }}
            >
              1
            </span>
          )}
          {current > 0 && (
            <span class="order-track-status-dot" id="step-1-dot">
              D
            </span>
          )}
          <span class="order-track-status-line" id="step-1-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Order Placed</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 1 && (
            <span class="order-track-status-dot" id="step-2-dot">
              2
            </span>
          )}
          {current > 1 && (
            <span class="order-track-status-dot" id="step-2-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-2-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Order Confirmed</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 2 && (
            <span class="order-track-status-dot" id="step-3-dot">
              3
            </span>
          )}
          {current > 2 && (
            <span class="order-track-status-dot" id="step-3-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-3-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Order Dispatched</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 2 && (
            <span class="order-track-status-dot" id="step-4-dot">
              4
            </span>
          )}
          {current > 2 && (
            <span class="order-track-status-dot" id="step-4-dot">
              D
            </span>
          )}{" "}
          <span class="order-track-status-line" id="step-4-line"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Out for Delivery</p>
        </div>
      </div>
      <div class="order-track-step">
        <div class="order-track-status">
          {current <= 3 && (
            <span class="order-track-status-dot" id="step-5-dot">
              5
            </span>
          )}
          {current > 3 && (
            <span class="order-track-status-dot" id="step-5-dot">
              D
            </span>
          )}
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">Order Delivered</p>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import "../styles/Common.css";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import mainlogo from "../Images/MainLogo.png";
// import VerifiedIcon from '@mui/icons-material/Verified';
// import BarChartIcon from '@mui/icons-material/BarChart';
// import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import CategoryIcon from '@mui/icons-material/Category';
// import Diversity3Icon from '@mui/icons-material/Diversity3';
import { Avatar } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  CirclesThreePlus,
  PhoneCall,
  Truck,
  ChatsCircle,
  Sparkle,
  Users,
  TagSimple,
  Article,
  UserList,
  ChartBar,
  IdentificationCard,
  SealCheck,
} from "@phosphor-icons/react";
import "../styles/AdminLayout2.css";
import Menu from "@mui/material/Menu"; // Import Menu component
import MenuItem from "@mui/material/MenuItem";
import { Apps, ContactMail, AssignmentInd, Spa } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
// import Footer from "./Footer";
import { green } from "@mui/material/colors";
import { useContext } from "react";
import { AdminContext } from "../context/AdminContext";
// import { useEffect } from "react";
// import Home from "../Images/DashboardIcon.png"
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#F2F2F2 !important",
  // color: "rgba(0, 0, 0, 0.6)",
  color: green,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: "#F2F2F2 !important",

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,

  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${0}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const listItems = [
  // {
  //   listIcon: <DashboardIcon />,
  //   listText: "Dashboard",
  //   listNav: '/AdminDashboard'
  // },
  {
    listIcon: <Article size={24} />,
    listText: "Facts",
    listNav: "/AdminFacts",
  },
  {
    listIcon: <TagSimple size={24} />,
    listText: "Discounts",
    listNav: "/AdminDiscount",
  },
  {
    listIcon: <UserList size={24} />,
    listText: "Invite",
    listNav: "/inviteSeller2",
  },
  // {
  //   listIcon: <Diversity3Icon />,
  //   listText: "InviteLink",
  //   listNav: '/InviteSellerLink'

  // },
  {
    listIcon: <ChartBar size={24} />,
    listText: "Plans",
    listNav: "/adminPlanlist",
  },
  {
    listIcon: <Users size={24} />,
    listText: "Users",
    listNav: "/manageUsers",
  },
  {
    listIcon: <IdentificationCard size={24} />,
    listText: "Admin Invite",
    listNav: "/adminInvite",
  },
  {
    listIcon: <SealCheck size={24} />,
    listText: "Approval",
    listNav: "/adminApproval",
  },
  {
    listIcon: <CirclesThreePlus size={24} />,
    listText: "Category",
    listNav: "/AdminAddCategory",
  },
  {
    listIcon: <Truck size={24} />,
    listText: "Track Order",
    listNav: "/trackOrder",
  },
  {
    listIcon: <Sparkle size={24} />,
    listText: "Review Approval",
    listNav: "/reviewApproval",
  },
  {
    listIcon: <ChatsCircle size={24} />,
    listText: "FAQ",
    listNav: "/AdminFaq",
  },
  {
    listIcon: <PhoneCall size={24} />,
    listText: "Contact Us",
    listNav: "/ContactUs",
  },
  {
    listIcon: <ShoppingCartIcon size={24} />,
    listText: "All Orders",
    listNav: "/allOrders",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Edit Product",
    listNav: "/adminProductSearch",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Gift Card",
    listNav: "/AdminUploadGc",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Buy Gift Card",
    listNav: "/AdminBuyGC",
  },
];
export default function AdminLayout2({ children }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { selectedListItemIndex, setSelectedListItemIndex, logout } =
    useContext(AdminContext);
  // useEffect(()=>{
  //   //console.log(selectedListItemIndex)
  // },[selectedListItemIndex])

  const handleListItemClick = (index) => {
    setSelectedListItemIndex(index);
    //console.log(listItems[index].listNav);
    navigate(listItems[index].listNav);
  };
  const [avatarMenuAnchor, setAvatarMenuAnchor] = useState(null); // State for managing the menu anchor

  // Function to open the avatar menu
  const handleAvatarMenuOpen = (event) => {
    setAvatarMenuAnchor(event.currentTarget);
    // navigate("/AdminProfile")
  };

  // Function to close the avatar menu
  const handleAvatarMenuClose = () => {
    setAvatarMenuAnchor(null);
  };
  return (
    <Box sx={{ display: "flex" }} className="admin-navbar-sidebar">
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        style={{ background: "red !important" }}
      >
        <Toolbar style={{ background: "red !important" }}>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="black"
            aria-label="open drawer"
            onClick={handleDrawerClose}
            edge="start"
            sx={{
              marginRight: 0,
              ...(!open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            loading="lazy"
            src={mainlogo}
            alt=""
            className="Myhraki-logo-nav-bar"
          />
          <IconButton
            // className="common-button"
            variant="outlined"
            color="black"
            onClick={handleAvatarMenuOpen} // Open the avatar menu on click
            style={{ position: "absolute", right: "3.5rem", top: "0.8rem" }}
          >
            <Avatar style={{ color: "black", width: "30px", height: "30px" }} />
          </IconButton>
          <Menu
            anchorEl={avatarMenuAnchor}
            open={Boolean(avatarMenuAnchor)}
            onClose={handleAvatarMenuClose} // Close the avatar menu on close
          >
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} color="red">
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              open && <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {listItems.map((listItem, index) => (
            <ListItem
              onClick={() => {
                handleListItemClick(index);
              }}
              key={index}
              disablePadding
              sx={{
                display: "block",
                backgroundColor:
                  selectedListItemIndex === index ? "#ff7891" : "#f2f2f2",
                borderRadius: "8px",
                minHeight: "3rem",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {listItem.listIcon}
                </ListItemIcon>
                <ListItemText
                  primary={listItem.listText}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <div  style={{position:"absolute", bottom:"0.5rem" ,width:"99%" ,fontSize:"1rem",textAlign:"center", minHeight:"25px", padding:"0.5rem 0"}} onMouseOver={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
        
          e.target.style.cursor="pointer"
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
        
        }}onClick={logout}>
          Logout
        </div> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <div className="seller-layout">{children}</div>
      </Box>
    </Box>
  );
}

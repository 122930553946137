import React from "react";
import Slider from "react-slick";
import Nc1 from "../Images/women's wear aesthetic photoshoot.png";
import Nc2 from "../Images/women's wear aesthetic photoshoot (1).png";
import Nc3 from "../Images/women's wear aesthetic photoshoot (2).png";
import Nc4 from "../Images/women's wear aesthetic photoshoot (4).png";
import "../styles/CustomerLandingPageCard1.css";
import "../styles/CustomerLandingPageCard4-6.css";
import { CaretRight, CaretLeft } from "@phosphor-icons/react";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard4Slidearrowright" onClick={onClick}>
      <CaretRight />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="CustomerLandingCard4Slidearrowleft" onClick={onClick}>
      <CaretLeft />
    </div>
  );
}

// Slider Component
function MultipleItems() {
  const images = [
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc3, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc4, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc3, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc4, title: "Title 2", subtitle: "$200", rating: 2 },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 600, // Screen width up to 600px
        settings: {
          slidesToShow: 1, // Show 1 slide
          slidesToScroll: 1, // Scroll 1 slide at a time
        },
      },
      {
        breakpoint: 1024, // Example for larger breakpoints
        settings: {
          slidesToShow: 3, // Show 3 slides
          slidesToScroll: 2, // Scroll 2 slides at a time
        },
      },
    ],
  };

  return (
    <div className="customerNewlandingCard4">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image.src}
              alt={image.title}
              className="customerNewlandingCard4-image"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

// Main Component
function CustomerLandingCard4() {
  return (
    <div className="customer-landing-card1">
      <div className="CustomerLandingCard1titesection">
        <div className="CustomerLandingCard1titesection-title">
          Explore The Range
        </div>
        <div className="CustomerLandingCard1titesection-subtitle">View All</div>
      </div>
      <div className="CustomerLandingCard4-tabsSection">
        <div className="CustomerLandingCard4-tabsSection-text">
          Women’s Wear
        </div>
        <div className="CustomerLandingCard4-tabsSection-text">Men’s Wear</div>
        <div className="CustomerLandingCard4-tabsSection-text">
          Bags & Footwear
        </div>
        <div className="CustomerLandingCard4-tabsSection-text">
          Home & Living
        </div>
        <div className="CustomerLandingCard4-tabsSection-text">
          Organic Beauty
        </div>
        <div className="CustomerLandingCard4-tabsSection-text">
          Art & Collectibler
        </div>
      </div>
      <div className="CustomerLandingCard4Slidesection">
        <MultipleItems />
      </div>
    </div>
  );
}

export default CustomerLandingCard4;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Info } from "@phosphor-icons/react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export default function ToolTip() {
  const [open, setOpen] = React.useState(false);

  const handleTooltipToggle = () => {
    console.log('clicked');
    setOpen((prev) => !prev);
  };

  const handleTooltipClose = () => setOpen(false);

  return (
    <div>
      <HtmlTooltip
        open={open}
        onClose={handleTooltipClose}
        title={
          <React.Fragment>
            <div>
              <ul style={{ display: "flex", justifyContent: "space-between", gap: "8px", flexDirection: "column", padding: "8px 16px 5px" }}>
                <li>{"Lower case letters (a-z)"}</li>
                <li>{"Upper Case letters (A-Z)"}</li>
                <li>{"Numbers (0-9)"}</li>
                <li>{"At least 8 characters in length"}</li>
                <li>{"Special character"}</li>
              </ul>
            </div>
          </React.Fragment>
        }
        placement="right-start"
      >
        {/* Add both onClick and onBlur to the Info icon */}
        <Info onClick={handleTooltipToggle} onBlur={handleTooltipClose} tabIndex={0} />
      </HtmlTooltip>
    </div>
  );
}

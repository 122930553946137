import React, { useContext, useState, useEffect } from "react";
import LogisticAdminLayoutAdjustment from "../components/LogisticAdminLayoutAdjustment";
import "../styles/LogisticsAdmin.css";
import { useNavigate } from "react-router-dom/dist";
import { LogisticsAdminContext } from "../context/LogisticsAdminContext";
import { API_BASE_URL } from "../App";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

function LogisticsAdminOrders() {
  const [orderData, setOrderData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const { accessToken } = useContext(LogisticsAdminContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/logistic/api/v1/GetAllOrder`,
          {
            method: "GET",
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("Fetched order details:", data.result);
          setOrderData(data.result || []); // Ensure array is set to avoid null
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accessToken]);

  return (
    <LogisticAdminLayoutAdjustment>
      <div className="logistic-orders">
        {/* Status Filter Dropdown */}
        <FormControl variant="outlined" sx={{ minWidth: 200, marginBottom: 2 }}>
          <InputLabel id="statusFilter-label">Filter by Status</InputLabel>
          <Select
            labelId="statusFilter-label"
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            label="Filter by Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="DC_FOR_QC">DC_FOR_QC</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
          </Select>
        </FormControl>

        {/* Order Table Headers */}
        <div className="logistic-order-details">
          <div className="logistic-order-table-item">Order Id</div>
          <div className="logistic-order-table-item">Courier Provider</div>
          <div className="logistic-order-table-item">Estimated Delivery</div>
          <div className="logistic-order-table-item">Status</div>
        </div>

        {/* Order Data Display */}
        {orderData
          .filter((order) => !statusFilter || order.status === statusFilter) // Filter based on selected status
          .map((order) => (
            <div
              className="logistic-order-details-tr"
              key={order.id} // Ensure unique key per order
              onClick={() => navigate(`/orders/${order.orderID}`)}
            >
              <div className="logistic-order-table-item">{order.orderID}</div>
              <div className="logistic-order-table-item">
                {order.courierProviderName}
              </div>
              <div className="logistic-order-table-item">6 - 7 days</div>
              <div className="logistic-order-table-item">
                <div
                  className="status-button"
                  style={{
                    background:
                      order.status === "Pending"
                        ? "orange"
                        : order.status === "Active"
                        ? "green"
                        : "red",
                  }}
                >
                  {order.status}
                </div>
              </div>
            </div>
          ))}
      </div>
    </LogisticAdminLayoutAdjustment>
  );
}

export default LogisticsAdminOrders;

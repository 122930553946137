import React from "react";
import "../styles/CustomerLandingPage-Nav-Noti-Slide1-GC-Footer.css";

function CustomerLandingPageNotification() {
  return (
    <div>
      <div className="CustomerLandingPage-Notification">
        <div>
          <marquee>
            <div className="CustomerLandingPage-Notification-text">
              India's 1st E-commerce Boutique Marketplace
            </div>
          </marquee>
        </div>
      </div>
    </div>
  );
}

export default CustomerLandingPageNotification;

import React, { useState } from "react";
import { Trash, ShareNetwork } from "@phosphor-icons/react";
import "../styles/CustomerNewCart.css";
import "../styles/CustomerNewMyAccount2.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import img1 from "../Images/cart-img1-new.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function CustomerMyAccountWishList() {
  const [items, setItems] = useState([
    {
      id: 1,
      name: "Button Down Dress",
      price: 2000,
      originalPrice: 2200,
      color: "Beige",
      img: img1,
      count: 1,
      showDropdown: false,
      selectedSize: "Small",
    },
    {
      id: 2,
      name: "Button Down Dress",
      price: 2000,
      originalPrice: 2200,
      color: "Beige",
      img: img1,
      count: 1,
      showDropdown: false,
      selectedSize: "Small",
    },

    {
      id: 3,
      name: "Button Down Dress",
      price: 2000,
      originalPrice: 2200,
      color: "Beige",
      img: img1,
      count: 1,
      showDropdown: false,
      selectedSize: "Small",
    },
  ]);

  const sizes = ["Small", "Medium", "Large", "X-Large"];

  // Update count for a specific item
  const updateCount = (id, action) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? {
              ...item,
              count:
                action === "increment"
                  ? item.count + 1
                  : Math.max(1, item.count - 1),
            }
          : item
      )
    );
  };

  // Toggle dropdown for a specific item
  const toggleDropdown = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, showDropdown: !item.showDropdown } : item
      )
    );
  };

  // Select size for a specific item
  const selectSize = (id, size) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id
          ? { ...item, selectedSize: size, showDropdown: false }
          : item
      )
    );
  };

  return (
    <div className="cust-my-acc-wishlist-main-sec">
      {items.map((item) => (
        <div className="CustomerNewCart-subheading-section2" key={item.id}>
          <div className="cust-my-acc-wishlist-main">
            <div className="CustomerNewCart-subheading-section-itemlist-sub1">
              <img
                src={item.img}
                alt="product"
                className="CustomerNewCart-subheading-itemlist-img"
              />

              <div className="CustomerNewCart-subheading-itemlist-deatils">
                <div className="CustomerNewCart-subheading-itemlist-deatils-head">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-heading">
                    {item.name}
                  </div>
                  <div className="cust-my-acc-wishlist-iconssec">
                    <Trash style={{ color: "hsla(359, 99%, 67%, 1)" }} />
                    <ShareNetwork style={{ color: "hsla(359, 99%, 67%, 1)" }} />
                  </div>
                </div>
                <div className="CustomerNewCart-subheading-itemlist-deatils-price">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price1">
                    {item.price}
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price2">
                    {item.originalPrice}
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-price-d">
                    MRP Inclusive of all taxes
                  </div>
                </div>
                <div className="cust-my-acc-wishlist-main-btn-sec">
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button1">
                    <img
                      src={item.img}
                      alt="color preview"
                      className="CustomerNewCart-subheading-itemlist-deatils-button1-img"
                    />
                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-color-size">
                      Color: {item.color}
                    </div>

                    <div className="CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName">
                      Size: {item.selectedSize}
                    </div>
                    <div className="CustomerNewCart-size-dropdown-wrapper">
                      {item.showDropdown ? (
                        <ArrowDropUpIcon
                          onClick={() => toggleDropdown(item.id)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => toggleDropdown(item.id)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                      {item.showDropdown && (
                        <div className="CustomerNewCart-subheading-itemlist-dropdown-menu">
                          {sizes.map((size) => (
                            <div
                              key={size}
                              className="CustomerNewCart-subheading-itemlist-dropdown-item"
                              onClick={() => selectSize(item.id, size)}
                            >
                              {size}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="CustomerNewCart-subheading-itemlist-deatils-button2">
                    <RemoveIcon
                      style={{
                        color: "black",
                        cursor: "pointer",
                        fontSize: window.innerWidth <= 600 ? "12px" : "18px",
                      }}
                      onClick={() => updateCount(item.id, "decrement")}
                    />
                    <div className="CustomerNewCart-subheading-itemlist-deatils-number">
                      {item.count}
                    </div>
                    <AddIcon
                      style={{
                        color: "black",
                        cursor: "pointer",
                        fontSize: window.innerWidth <= 600 ? "12px" : "18px",
                      }}
                      onClick={() => updateCount(item.id, "increment")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomerMyAccountWishList;

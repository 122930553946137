import React from "react";
import MyhrakiFooterimg from "../Images/MyhrakiFooterimg.png";
import "../styles/CustomerLandingPage-Nav-Noti-Slide1-GC-Footer.css";
import { useLocation, useNavigate } from "react-router-dom";
function CustomerLandingPageNewGiftCard() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleGiftCart = () => {
    navigate("/shopgiftcard");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  return (
    <div className="CustomerLandingPageNewGiftCard-section">
      <div>
        {" "}
        <img
          src={MyhrakiFooterimg}
          alt="giftcard"
          className="CustomerLandingPageNewGiftCard-section-img1"
        />
      </div>
      <div className="CustomerLandingPageNewGiftCard-section-text">
        <div className="CustomerLandingPageNewGiftCard-section-title">
          Myhraki Gift Cards
        </div>
        <div className="CustomerLandingPageNewGiftCard-section-subtitle">
          Can’t choose a gift? Here’s a convenient gift that will still get you
          the brownie points you were going for. Shop for the Myhraki e-Gift
          Card and let the recipient choose their own gift for an amount decided
          by you.
        </div>
        <div
          className="CustomerLandingPageNewGiftCard-section-button"
          onClick={handleGiftCart}
        >
          Shop Now
        </div>
      </div>
    </div>
  );
}

export default CustomerLandingPageNewGiftCard;

import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";

import CustomerNewMyAccount from "../components/CustomerNewMyAccount";
function CustomerNewAccount() {
  return (
    <div>
      <CustomerLayoutnew>
        <CustomerNewMyAccount />
      </CustomerLayoutnew>
    </div>
  );
}

export default CustomerNewAccount;

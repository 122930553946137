import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../styles/Seller.css";
import "../styles/Common.css";
import { SellerContext } from "../context/SellerContext";
import SellerLayoutAdjustment from "../components/SellerLayoutAdjustment";
// import TrackDetails from "../components/TrackDetails";
import { errorAlert, successAlert } from "../components/Alert";
import { Divider } from "@mui/joy";
import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { API_BASE_URL } from "../App";
import Loading from "../components/Loading";
import orderInfo from "../OrderDetailsData";

export default function SellerLiveOrderDetails() {
  const params = useParams();
  const sellerItemId = params.itemId;
  // const sellerOrder = sellerOrdersData[sellerProductsId];

  const { accessToken, sellerData, handleAuthExpiry, logout } = useContext(SellerContext)
  // const [tracker, setTracker]= useState(false);
  // const [orderStatus]= useState("Delivered");
  const [address, setAddress] = useState('');

  //   ////console.log(id);
  // const [orderStatus , setOrderStatus]=useState("pending")
  const [sellerOrderItemData, setSellerOrderItemData] = useState([]);
  const [sellerOrderDetailData, setSellerOrderDetailData] = useState([]);
  const [comment, setComment] = useState(null);
  const [commentBoxOpen, setCommentBoxOpen] = useState(false);
  const [addressList, setAddressList] = useState();
  const [qcFailedReason, setQcFailedReason] = useState('AAAAAAAA')
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)
  const [loading, setLoading] = useState(false);
  const [skuItem , setSkuItem] = useState('')
  const [amountAfterGst, setAmountAfterGst] = useState(0);
  function removeLastHyphenAndAfter(input) {
    var lastHyphenIndex = input.lastIndexOf("-");
    if (lastHyphenIndex >= 0) {
      return input.substring(0, lastHyphenIndex);
    }
    return input;
  }

  const fetchAddress = async () => {
    try {
      let response = await fetch(
        // `${API_BASE_URL}/address/api/v1/${sellerData?.result?.userID}/false `,
        `${API_BASE_URL}/address/api/v1/getAll?userId=${sellerData?.result?.typeOfUser === 'SLADM' ? sellerData?.result?.userID : sellerData?.result?.userAdminId}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );


      let data = await response.json();
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        // toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        return;
      }
      setAddressList(data?.result);
    }
    catch (error) {
      //console.error(error);
    }
  };
  const handleAddressChange = (event) => {
    //console.log(event.target.value)
    setAddress(event.target.value);
  };


  useEffect(() => {
    fetchAddress();
  }, []);
  const fetchOrderItemData = async () => {
    const response = await fetch(`${API_BASE_URL}/seller/api/v1/GetOrderByItemId?ItemId=${sellerItemId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,

      },
    });
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert('', 'Your Session has expired');
      // toastRef.current.showToast("Your Session has expired.", "error");
      logout();
      return;
    }
    setSellerOrderItemData(data);
    setSkuItem(data?.result?.skuid)
    await fetchproductDataTest(data?.result?.skuid);
    await fetchOrderDeatil(data?.result);
    //console.log(data);
  };

  

  const [productDataTest, setproductDataTest] = useState();

  const fetchproductDataTest = async (sku) => {
    setLoading(true);

    try {
      // setLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/get?sku=${sku}`
      );
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        // toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        setLoading(false);

        return;
      }
      if (response.ok) {
        const data = await response.json();
        setproductDataTest(data?.result);
        console.log(data.result[0].title);
        setLoading(false);

      } else {
        setLoading(false);
        errorAlert('',"Error fetching order data:");

        // throw new Error("Failed to fetch product data");
        // setLoading(false);

      }
    } catch (error) {
      errorAlert('',"Error fetching order data:");

      // setLoading(false);
      setLoading(false);

    }
  };


  const fetchOrderDeatil = async (sku) => {
    setLoading(true);
    try {


      const response = await fetch(`${API_BASE_URL}/orders/api/v1/orderDetailsById?orderid=${removeLastHyphenAndAfter(sellerItemId)}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      });
      const handleAuthError = handleAuthExpiry(response);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        // toastRef.current.showToast("Your Session has expired.", "error");
        logout();
        setLoading(false);

        return;
      }
      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        setSellerOrderDetailData(data.result);
        console.log(data)
        setLoading(false);

      } else {
        errorAlert('',"Error fetching order data:");
      }
    } catch {
      setLoading(false);

      errorAlert('', 'Something Went wrong. Please try again.')
    }
  }
 

  const fetchOrderStatuses = async () => {
    const data = await fetch(`${API_BASE_URL}/orders/api/v1/os/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    });
    const response = await data.json();
    const handleAuthError = handleAuthExpiry(data);
    if (handleAuthError === true) {
      errorAlert('', 'Your Session has expired');
      // toastRef.current.showToast("Your Session has expired.", "error");
      logout();
      return;
    }
    //console.log(response);
  }

  const sellerOrderAccept = async () => {
    try{
      setAcceptLoading(true);
    if (!address) {
      errorAlert('', 'Select an address')
      setAcceptLoading(false);

      return;
    }
    const selectedPincode = addressList?.filter(item => item.id === address)
    const response = await fetch(`${API_BASE_URL}/seller/api/v1/AcceptOrder`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(
          {
            "orderItemId": sellerItemId,
            "orderId": sellerOrderItemData?.result?.orderNumber,
            "sku": sellerOrderItemData?.result?.skuid,
            "warehouseId": address,
            "retrunwarehouseId": address,
            "warehousePincode": selectedPincode[0]?.pincode
          })
      });
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert('', 'Your Session has expired');
      // toastRef.current.showToast("Your Session has expired.", "error");
      logout();
      setAcceptLoading(false);

      return;
    }
    data?.result === "Order Updated" ? successAlert('', 'Accepted') : errorAlert('', 'Something went wrong')
    fetchOrderItemData();
    setAcceptLoading(false);

  }catch{
    errorAlert('', 'Something went wrong')
    setAcceptLoading(false);

  }
}


  const sellerOrderReject = async () => {
   
    setRejectLoading(true)
    try{

    
    if (!address && !comment) {
      errorAlert('', ' Please Select an address and provide a suitable reason for rejecting this order.')
      setRejectLoading(false)

      return;
    }
    if (!address) {
      errorAlert('', ' Please Select an address')
      setRejectLoading(false)

      return;
    }
    if (!comment) {
      errorAlert('', 'Please enter a valid reason')
      setRejectLoading(false)

      return;
    }
    const response = await fetch(`${API_BASE_URL}/seller/api/v1/RejectOrder`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(
          {
            "orderItemId": sellerItemId,
            "orderId": sellerOrderItemData?.result?.orderNumber,
            "comment": comment,
            "amount":amountAfterGst,
            "skuid": sellerOrderItemData?.result?.skuid,
          })
      });
    const data = await response.json();
    const handleAuthError = handleAuthExpiry(response);
    if (handleAuthError === true) {
      errorAlert('', 'Your Session has expired');
      // toastRef.current.showToast("Your Session has expired.", "error");
      logout();
      setRejectLoading(false)

      return;
    }
    data?.result === true ? successAlert('', 'Rejected') : errorAlert('', 'Something went wrong')
    fetchOrderItemData();
    setRejectLoading(false)

  }
  catch{
    setRejectLoading(false)

    errorAlert('', 'Something went wrong')
  }
}


  useEffect(() => {
    fetchOrderItemData();
    // fetchOrderDeatil();
    fetchOrderStatuses();

  }, []);

  // useEffect(()=>{
  //   fetchOrderDeatil();
  // })

  const acceptReturn = async () => {
    try {
      const result = await fetch(`${API_BASE_URL}/seller/api/v1/ReceivedReturnedOrderItem
    `,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(
            {
              "orderItemId": sellerItemId,
              "orderId": sellerOrderItemData?.result?.orderNumber,
              "amount":amountAfterGst,

          
            })
        });
      const data = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      data?.result === true ? successAlert('', 'Return Process Completed') : errorAlert('', 'Something went wrong');
      fetchOrderItemData();

    } catch {
      errorAlert('', 'Something went wrong');
    }
  }
  const acceptReplace = async () => {
    try {
      const result = await fetch(`${API_BASE_URL}/seller/api/v1/cretaeReplaceNewOrder
    `,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(
            {
              "sku": sellerOrderItemData?.result?.skuid,
              "sellerid": sellerOrderItemData?.result?.sellerID,
              "imagename": productDataTest[0]?.productSKUs?.[0]?.imageList[0],
              "quantity": sellerOrderItemData?.result?.quentity,
              "cost": parseInt(sellerOrderItemData?.result?.totalCost),
              "description": productDataTest[0]?.description,
              "orderid": sellerOrderItemData?.result?.orderNumber,
              "orderItemId": sellerItemId,
              "title": productDataTest[0]?.title,
              "ifQCFailed": null
            }
          )
        });
      const data = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      data?.result === true ? successAlert('', 'Replace Process Completed') : errorAlert('', 'Something went wrong');
      fetchOrderItemData();

    } catch {
      errorAlert('', 'Something went wrong');
    }
  }
  const acceptReplaceFromQc = async () => {
    try {
      const result = await fetch(`${API_BASE_URL}/seller/api/v1/cretaeReplaceNewOrder
    `,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(
            {
              "sku": sellerOrderItemData?.result?.skuid,
              "sellerid": sellerOrderItemData?.result?.sellerID,
              "imagename": productDataTest[0]?.productSKUs?.[0]?.imageList[0],
              "quantity": sellerOrderItemData?.result?.quentity,
              "cost": parseInt(sellerOrderItemData?.result?.totalCost),
              "description": productDataTest[0]?.description,
              "orderid": sellerOrderItemData?.result?.orderNumber,
              "orderItemId": sellerItemId,
              "title": productDataTest[0]?.title,
              "ifQCFailed": 'AAAAAAa'
            }
          )
        });
      const data = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      data?.result === true ? successAlert('', 'Replace Process Completed') : errorAlert('', 'Something went wrong');
      fetchOrderItemData();

    } catch {
      errorAlert('', 'Something went wrong');
    }
  }


  const sellerOrderAcceptAfterQcFailed = async () => {
    if (!qcFailedReason) {
      errorAlert('', 'Enter a valid reason')
    }
    if (!address) {
      errorAlert('', ' Please Select an address')
      setRejectLoading(false)

      return;
    }
    try {
      const result = await fetch(`${API_BASE_URL}/seller/api/v1/cretaeReplaceNewOrder
    `,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(
            {
              "sku": sellerOrderItemData?.result?.skuid,
              "sellerid": sellerOrderItemData?.result?.sellerID,
              "imagename": productDataTest[0]?.productSKUs?.[0]?.imageList[0],
              "quantity": sellerOrderItemData?.result?.quentity,
              "cost": parseInt(sellerOrderItemData?.result?.totalCost),
              "description": productDataTest[0]?.description,
              "orderid": sellerOrderItemData?.result?.orderNumber,
              "orderItemId": sellerItemId,
              "title": productDataTest[0]?.title,
              "ifQCFailed": qcFailedReason
            }
          )
        });
      const data = await result.json();
      const handleAuthError = handleAuthExpiry(result);
      if (handleAuthError === true) {
        errorAlert('', 'Your Session has expired');
        logout();
        return;
      }
      data?.result===true&& sellerOrderAccept();
      data?.result === true ? successAlert('', 'Replace Process Completed') : errorAlert('', 'Something went wrong');
      fetchOrderItemData();

    } catch {
      errorAlert('', 'Something went wrong');
    }
  }


  const calculateTotalAfterGst = () => {
    const totalCost = sellerOrderItemData?.result?.totalCost || 0;
    const quantity = sellerOrderItemData?.result?.quentity || 0;
    const baseAmount = totalCost * quantity ;
    const shipping = 100
    // Find the GST rate or default to 0 if it's invalid (null, undefined, NaN, or 0)
    const gstRate = parseFloat(sellerOrderDetailData?.result?.find(item => item?.sku === skuItem)?.gst) || 0;
    
    // Calculate GST amount
    const gstAmount = baseAmount * (gstRate / 100);
    
    // Total after adding GST
    const totalAfterGst = parseInt(baseAmount + gstAmount + shipping);

    console.log(baseAmount, gstAmount, shipping,  totalAfterGst);
    setAmountAfterGst(totalAfterGst);
};
useEffect(()=>{
  calculateTotalAfterGst();

},[skuItem, sellerOrderDetailData])

  return (
    <SellerLayoutAdjustment>
      {/* <Heading text="Order Number" /> */}
      {(!productDataTest?.[0]?.title || !sellerOrderItemData?.result?.orderStatus)&&<div className="loading-container" style={{ minHeight: "300px", minWidth: "80%" ,width:"1000px !important"}}>
            <Loading />
          </div> }
      {productDataTest?.[0]?.title&& sellerOrderItemData?.result?.orderStatus&&<div className="page-content">
        <Link to="/sellerOrders" style={{ color: 'black', fontSize: '18px', paddingTop:'5rem' }}>Back to Orders</Link>
        {/* <div className="sellerLiveOrderId">Order Id : {sellerProductsId}</div> */}
        <div className="seller-live-order-details-product-details-and-cost">
          <div className="seller-live-order-details-order-details-box">
            <div className="seller-products-list-table-header">
              <div className="seller-products-list-table-product-name">
                Item Summary
              </div>
              <div className="seller-products-list-table-product-item-details" style={{ fontWeight: "600" }}>
                Price
              </div>
              <div className="seller-products-list-table-product-item-details" style={{ fontWeight: "600" }}>
                Quantity
              </div>
              <div className="seller-products-list-table-product-item-details" style={{ fontWeight: "600" }}>
                GST
              </div>
              <div className="seller-products-list-table-product-item-details" style={{ fontWeight: "600" }}>
                Shipping
              </div>

              <div className="seller-products-list-table-product-item-details " style={{ fontWeight: "600" }}>Total</div>
            </div>
            <Divider style={{ margin: "1rem" }} />
            <div >

              {productDataTest?.map((item) => (
                <div className="seller-products-list-table-content">
                  <div className="seller-products-list-table-product-name">
                    <div className="seller-products-name-image-field">
                      <img loading="lazy" alt=" " style={{ height: "3rem", width: "5rem" }}
                        className="seller-products-card-product-image"
                        src={productDataTest[0]?.productSKUs?.[0]?.imagesUriList[0]?.thumbnailURI}
                      />
                      <div className="seller-prdct-item-title">
                        <div style={{ fontSize: "18px", color: "#000", fontWeight: "500" }}>{item.title}</div>
                      </div>
                    </div>
                  </div>
                  <div className="seller-products-list-table-product-item-detail2">
                    <div>{sellerOrderItemData?.result?.totalCost}</div>
                  </div>
                  <div className="seller-products-list-table-product-item-detail2">
                    <div>{sellerOrderItemData?.result?.quentity}</div>

                  </div>
                  <div className="seller-products-list-table-product-item-detail2">
                    <div>{(amountAfterGst-100-(sellerOrderItemData?.result?.totalCost*sellerOrderItemData?.result?.quentity))||0}</div>

                  </div>
                  <div className="seller-products-list-table-product-item-detail2">
                    <div>{ parseFloat(100*sellerOrderItemData?.result?.quentity)}</div>

                  </div>
                  <div className="seller-products-list-table-product-item-detail2">
                    <div>{ amountAfterGst}</div>

                  </div>

                </div>))}

            </div>

          </div>
          {/* <div className="seller-live-order-details-order-price-box"> */}
            {/* <div className="seller-live-order-details-box-head"> Order Summary </div> */}
            {/* <Divider style={{ margin: "1rem" }} /> */}
            {/* <div className="seller-live-order-details-customer-details-small">
              <div>Amount </div>
              <div>{amountAfterGst}</div>
            </div> */}
            {/* <div className="seller-live-order-details-customer-details-small">
              <div>Discount Amount </div>
              <div>0</div>
            </div> */}
            {/* <div className="seller-live-order-details-customer-details-small">
              <div>Quantity </div>
              <div>{sellerOrderItemData?.result?.quentity}</div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>TOTAL AMOUNT </div>
              <div>{ amountAfterGst}</div>
            </div> */}

          {/* </div> */}


        </div>

        <div className="seller-live-order-details-product-details-and-cost">
          <div className="seller-live-order-details-order-details-box2">
            <div className="seller-live-order-details-box-head">Customer and Order Details</div>
            <Divider style={{ margin: "1rem" }} />
            <div className="seller-live-order-details-customer-details">
              <div>Created Date:</div>
              <div>{sellerOrderItemData?.result?.createdDate}</div>
            </div>
            {/* <div className="seller-live-order-details-customer-details">
                <div>Contact Number:</div>
                <div>9089898776</div>
            </div> */}
            <div className="seller-live-order-details-customer-details">
              <div>Payment Status:</div>
              <div>{sellerOrderItemData?.result?.paymentStatus}</div>
            </div>
            {/* <div className="seller-live-order-details-customer-details">
                <div>Payment Status:</div>
                <div>{sellerOrderItemData[0].status}</div>
            </div> */}
            <div className="seller-live-order-details-customer-details">
              <div>Order Status:</div>
              <div>{sellerOrderItemData?.result?.orderStatus}</div>
            </div>
            <div className="seller-live-order-details-customer-details">
              <div>Shipment Status:</div>
              <div>{sellerOrderItemData?.result?.shipmentStatus}</div>
            </div>

          </div>
          {/* <div className="seller-live-order-details-order-delivery-box" >
            <div className="seller-live-order-details-box-head">Delivery Address</div>
            <Divider style={{ margin: "1rem" }} />
            <div className="seller-live-order-details-customer-details-small">
              <div>Country: </div>
              <div>India</div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>State: </div>
              <div>Karnataka</div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>City: </div>
              <div>{sellerOrderItemData?.locationName}</div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>Street Name: </div>
              <div>1st Main 1st cross </div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>Landmark: </div>
              <div>ABC circle</div>
            </div>
            <div className="seller-live-order-details-customer-details-small">
              <div>Postal Pin Code: </div>
              <div></div>
            </div>


          </div> */}
        </div>

        {!sellerItemId.startsWith('QCF_')&& !sellerItemId.startsWith('RPS_')&&sellerOrderItemData?.result?.orderStatus.toLowerCase() === "pending".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus.toLowerCase() === "Pending".toLowerCase() &&
          <div className="seller-live-orders-approval-pending">
            <div className="seller-live-orders-approval-head">Do you want to Approve this Order ?</div>
            <TextField
              select
              value={address}
              onChange={handleAddressChange}
              fullWidth
              outlined
              // error={Boolean(errors.address)}
              // helperText={errors.address}
              style={{ width: '60%', margin: '8px auto' }}
            >
              {addressList?.map((address) => (
                <MenuItem value={address.id}>{address.addressLine1 + ' ' + address.state + ' ' + address.city + ' ' + address.pincode}</MenuItem>
              ))}
            </TextField>
            <div className="Approve-reject-buttons">
              {!acceptLoading&&<button className="common-button" onClick={sellerOrderAccept}>Approve</button>}
              {acceptLoading&& <div style={{width:'200px'}}><CircularProgress style={{color:'pink'}} /></div>}
              <button className="common-button reject-button" onClick={() => setCommentBoxOpen(true)}>Reject</button>
            </div>
            {commentBoxOpen && <div className="reject-textarea">
              <div style={{ width: '70%', margin: '0 auto' }}>Reason for Rejecting</div>
              <textarea className="seller-order-details-textarea" value={comment} onChange={(e) => { setComment(e.target.value) }} placeHolder="Enter reason..." />
              <div className="reject-button-field" style={{ float: 'right' }}>
                {!rejectLoading &&<button className="buyer-save-button" onClick={() => { comment != "" ? sellerOrderReject() : errorAlert('', 'Please enter a reason') }}>Submit</button>}
                {rejectLoading&& <div style={{width:'200px'}}><CircularProgress style={{color:'pink'}} /></div>}

              </div>
            </div>}
          </div>}
        {sellerItemId.startsWith('QCF_')&&sellerOrderItemData?.result?.orderStatus.toLowerCase() === "pending".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus.toLowerCase() === "Pending".toLowerCase() &&
          <div className="seller-live-orders-approval-pending">
            <div className="seller-live-orders-approval-head">Do you want to Approve this Order ?</div>
            <TextField
              select
              value={address}
              onChange={handleAddressChange}
              fullWidth
              outlined
              // error={Boolean(errors.address)}
              // helperText={errors.address}
              style={{ width: '60%', margin: '8px auto' }}
            >
              {addressList?.map((address) => (
                <MenuItem value={address.id}>{address.addressLine1 + ' ' + address.state + ' ' + address.city + ' ' + address.pincode}</MenuItem>
              ))}
            </TextField>
            <div className="Approve-reject-buttons">
              <button className="common-button" onClick={sellerOrderAccept}>Approve</button>
            </div>
           
          </div>}
          
    
          {sellerItemId.startsWith('QCF_')&&sellerOrderItemData?.result?.orderStatus === "pending" && sellerOrderItemData?.result?.shipmentStatus === "Pending" && <div>
          <div className="seller-live-orders-approval-head">You have accepted a Replace, Please Approve this Order</div>
          <TextField
            select
            value={address}
            onChange={handleAddressChange}
            fullWidth
            outlined
            // error={Boolean(errors.address)}
            // helperText={errors.address}
            style={{ width: '60%', margin: '8px auto' }}
          >
            {addressList?.map((address) => (
              <MenuItem value={address.id}>{address.addressLine1 + ' ' + address.state + ' ' + address.city + ' ' + address.pincode}</MenuItem>
            ))}
          </TextField>
          <div className="Approve-reject-buttons">
            <button className="common-button" onClick={sellerOrderAcceptAfterQcFailed}>Approve</button>
          </div>
         </div>}
          {sellerItemId.startsWith('RPS')&&sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "pending".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus?.toLowerCase()  === "Pending".toLowerCase() && 
          <div className="seller-live-order-details-acceptance-container">
          <div className="seller-live-orders-approval-head">You have accepted a Replace, Please Approve this Order</div>
            <TextField
              select
              value={address}
              onChange={handleAddressChange}
              fullWidth
              outlined
              // error={Boolean(errors.address)}
              // helperText={errors.address}
              style={{ width: '60%', margin: '8px auto' }}
            >
              {addressList?.map((address) => (
                <MenuItem value={address.id}>{address.addressLine1 + ' ' + address.state + ' ' + address.city + ' ' + address.pincode}</MenuItem>
              ))}
            </TextField>
            <div className="Approve-reject-buttons">
              <button className="common-button" onClick={sellerOrderAccept}>Approve</button>
            </div>
           
          
         </div>}

          
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "pending" && sellerOrderItemData?.result?.shipmentStatus === "OPG" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "pending" && sellerOrderItemData?.result?.shipmentStatus === "PBC" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "PIT" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "RDD" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "OFD" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "PPS" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "DA" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "DF" &&

          <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>

        }
        {sellerOrderItemData?.result?.orderStatus === "pending"?.toLowerCase() && sellerOrderItemData?.result?.shipmentStatus === "OCC" &&

          <div className="seller-live-orders-updated-status">This Order has been cancelled by the Customer.</div>

        }

        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Pending".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "QC_Failed" && <div>
          <div className="seller-live-orders-updated-status" >
            <div> Your Product is being sent back to you as it did not pass the Quality Check.</div>
          </div>
            <div style={{maxWidth:'400px', margin:'0 auto'}}>Do you wish to replace it with another unit? </div>
            <div style={{maxWidth:'400px', margin:'0 auto'}}>
            <button className='common-button' onClick={acceptReplaceFromQc}>Yes</button>
            <button className='common-button' onClick={acceptReturn}>No</button>
</div>
        </div>}

        {sellerOrderItemData?.result?.orderStatus === "Return" && sellerOrderItemData?.result?.shipmentStatus == "DC" &&
          <div className="seller-live-orders-updated-status">Buyer has initiated the return of this order.

          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Completed" && sellerOrderItemData?.result?.shipmentStatus == "RDEL" && <div>
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. You have Received your item back.</div>
          {/* <button className="common-button" onClick={acceptReturn}>I have accepted this item</button> */}
        </div>}
        {sellerOrderItemData?.result?.orderStatus === "Return" && sellerOrderItemData?.result?.shipmentStatus == "RDEL" && <div>
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. You have Received your item back.</div>
          <button className="common-button" onClick={acceptReturn}>I have accepted this item</button>
        </div>}
        {sellerOrderItemData?.result?.orderStatus === "Return" && sellerOrderItemData?.result?.shipmentStatus == "RTOI" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Return" && sellerOrderItemData?.result?.shipmentStatus == "RINT" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Return" && sellerOrderItemData?.result?.shipmentStatus == "RPSH" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Completed" && sellerOrderItemData?.result?.shipmentStatus == "Refund Scheduled" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. You have Received your item back.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Refund Scheduled" && sellerOrderItemData?.result?.shipmentStatus == "Return" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. You have Received your item back.
          </div>}


          {sellerOrderItemData?.result?.orderStatus === "Replace" && sellerOrderItemData?.result?.shipmentStatus == "DC" &&
          <div className="seller-live-orders-updated-status">Buyer has initiated the return of this order.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Replace" && sellerOrderItemData?.result?.shipmentStatus == "RDEL" && <div>
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. You have Received your item back.</div>
          <button className="common-button" onClick={acceptReturn}>I have accepted this item</button>
          <button className="common-button" onClick={acceptReplace}>I have accepted this item and Initiated new Order</button>
        </div>}
    
        {sellerOrderItemData?.result?.orderStatus === "Replace" && sellerOrderItemData?.result?.shipmentStatus == "RTOI" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Replace" && sellerOrderItemData?.result?.shipmentStatus == "RINT" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}
        {sellerOrderItemData?.result?.orderStatus === "Replace" && sellerOrderItemData?.result?.shipmentStatus == "RPSH" &&
          <div className="seller-live-orders-updated-status">Buyer has Returned this order. Item has been picked up from Buyer, Will reach you soon!.
          </div>}


        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Completed".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "DC" &&
          <div className="seller-live-orders-updated-status">This Order has been completed.
          </div>}
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Completed".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "Return" &&
          <div className="seller-live-orders-updated-status">This Order has been Returned to you..
          </div>}

        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Completed".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "Replace" &&
          <div className="seller-live-orders-updated-status">This Order has been Replaced.
          </div>}
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Completed".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "Canceled" &&
          <div className="seller-live-orders-updated-status">This Order has been cancelled by the Customer.
          </div>}
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Canceled".toLowerCase() && 
          <div className="seller-live-orders-updated-status">This Order has been cancelled by the Customer.
          </div>}
        {sellerOrderItemData?.result?.orderStatus?.toLowerCase() === "Completed".toLowerCase() && sellerOrderItemData?.result?.shipmentStatus == "Rejected" &&
          <div className="seller-live-orders-updated-status">You have rejected this order.
          </div>}







        {/* {sellerOrderItemData?.result?.orderStatus === "pending" && sellerOrderItemData?.result?.shipmentStatus == "QC_Failed" && <div className="seller-live-orders-updated-status">I have proceeded to generate a new order following a quality check failure.<button className='common-button' onClick={acceptReplaceFromQc}>Acccepted and created new order AfterFailing from QC</button> <button className='common-button' onClick={acceptReturn}> I lack an extra item for replacement. I'm canceling the order and requesting a refund for the buyer.</button></div>}
        {sellerOrderItemData?.result?.orderStatus === "Canceled" && <div className="seller-live-orders-updated-status">Buyer has canceled this Order. You will be receiving this order back in 3-5 working days.</div>}
        {sellerOrderItemData?.result?.orderStatus === "Rejected" && <div className="seller-live-orders-updated-status" >You have Already Rejected this order ! </div>}
        {sellerOrderItemData?.result?.orderStatus === "Completed" && <div className="seller-live-orders-updated-status">Completed Return or cancel !</div>}
        {sellerOrderItemData?.result?.orderStatus === "completed" && <div className="seller-live-orders-updated-status">Completed Delivery !</div>}
        {sellerOrderItemData?.result?.orderStatus === "Refund Scheduled" && sellerOrderItemData?.result?.paymentStatus === "scheduled" && <div className="seller-live-orders-updated-status">This Order has been returned back to you</div>} */}
        {/* <div>------------------------------------------------------------------------------------------------------------------------------------</div> */}
        {/* <div className="seller-live-orders-updated-status">You have Already Approved this order!</div>
        <div className="seller-live-orders-updated-status">I have successfully received my Item.<div><button className='common-button' onClick={acceptReturn}>Confirm</button></div></div>
        <div className="seller-live-orders-updated-status">I have received this item and initiating a fresh order. <button className='common-button'onClick={acceptReplace}>Accepted this and creating new order </button></div>
        <div className="seller-live-orders-updated-status">I have proceeded to generate a new order following a quality check failure.<button className='common-button' onClick={acceptReplaceFromQc}>Acccepted and created new order AfterFailing from QC</button> <button className='common-button' onClick={acceptReturn}> I lack an extra item for replacement. I'm canceling the order and requesting a refund for the buyer.</button></div>
        <div className="seller-live-orders-updated-status">Buyer has canceled this Order. You will be receiving this order back in 3-5 working days.</div>
        <div className="seller-live-orders-updated-status" >You have Already Rejected this order! </div>
        <div className="seller-live-orders-updated-status">Completed Return or cancel</div>
    <div className="seller-live-orders-updated-status">Completed Delivery</div>
    <div className="seller-live-orders-updated-status">This Order has been returned back to you</div> */}


      </div>}
    </SellerLayoutAdjustment>
  );
}
import React, { useState } from "react";
import "../styles/CustomerNewCart.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { Trash, Circle, Plus, XCircle } from "@phosphor-icons/react";
const DeliveryAddressComponent = () => {
  const getDynamicWidth = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      return 280; // Less width for small screens
    } else if (width >= 600 && width <= 900) {
      return 600; // More width for medium screens
    }
    return 400; // Default width for other cases
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: getDynamicWidth(),
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  /*Delivery adress functionality */
  const [openAddDeliveryAddress, setOpenAddDeliveryAddress] =
    React.useState(false);
  const [deliveryAddresses, setDeliveryAddresses] = React.useState([
    {
      id: 1,
      name: "Vaidehi Paradkar",
      address:
        "Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHMEDABAD, GUJARAT, INDIA - 380009",
    },
  ]);
  const [deliveryAddressIndex, setDeliveryAddressIndex] = useState(null);

  const [newDeliveryAddress, setnewDeliveryAddress] = useState({
    name: "",
    mobile: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    saveAs: "",
  });

  const handleAddDeliveryAddress = () => setOpenAddDeliveryAddress(true);
  const handleCloseDeliveryAddress = () => setOpenAddDeliveryAddress(false);

  const handleCircleClick = (index) => {
    setDeliveryAddressIndex(index);
  };

  const handleDeliveyAdressInputChange = (field, value) => {
    setnewDeliveryAddress((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddAddressToList = () => {
    setDeliveryAddresses((prev) => [...prev, newDeliveryAddress]);
    setnewDeliveryAddress({
      name: "",
      mobile: "",
      address: "",
      state: "",
      city: "",
      pincode: "",
      saveAs: "",
    });
    handleCloseDeliveryAddress();
  };
  /*Shipping adress functionality */
  const [openAddShippingAddress, setOpenAddShippingAddress] =
    React.useState(false);
  const [shippingAddresses, setShippingAddresses] = React.useState([
    {
      id: 1,
      name: "Vaidehi Paradkar",
      address:
        "Raiya Kar Bhavan Nr Times Of India, Ashram Road, AHMEDABAD, GUJARAT, INDIA - 380009",
    },
  ]);
  const [newAddress, setNewAddress] = React.useState({
    name: "",
    address: "",
    state: "",
    city: "",
    pinCode: "",
    saveAs: "",
  });
  const [selectedAddressId, setSelectedAddressId] = React.useState(null);

  const handleCircleShippingClick = (id) => {
    setSelectedAddressId(id);
  };

  const handleAddShippingAddress = () => setOpenAddShippingAddress(true);
  const handleCloseShippingAddress = () => setOpenAddShippingAddress(false);

  const handleShippingInputChange = (field, value) => {
    setNewAddress((prev) => ({ ...prev, [field]: value }));
  };

  const handleSaveAddress = () => {
    const newId = shippingAddresses.length + 1;
    const addressToAdd = {
      id: newId,
      name: newAddress.name,
      address: `${newAddress.address}, ${newAddress.city}, ${newAddress.state}, INDIA - ${newAddress.pinCode}`,
    };
    setShippingAddresses([...shippingAddresses, addressToAdd]);
    setNewAddress({
      name: "",
      address: "",
      state: "",
      city: "",
      pinCode: "",
      saveAs: "",
    });
    handleCloseShippingAddress();
  };

  return (
    <div className="CustomerNewCart-Adress-section2">
      {/* SHIPPING ADDRESS */}
      <div className="CustomerNewCart-subheading-section-itemlist-ForAdress">
        <div className="CustomerNewCart-adress-sectionMain">
          <div className="CustomerNewCart-adress-Heading">Delivery Address</div>

          {/* Dynamically Render Addresses */}
          {deliveryAddresses.map((address, index) => (
            <div className="CustomerNewCart-adress-Details-Section" key={index}>
              <div onClick={() => handleCircleClick(index)}>
                <Circle
                  style={{ color: "#FE585B" }}
                  weight={deliveryAddressIndex === index ? "fill" : undefined}
                />
              </div>
              <div>
                <div>
                  <div className="CustomerNewCart-adress-Details-Name">
                    {address.name}
                  </div>
                  <div>
                    <Trash
                      style={{ color: "#FE585B", cursor: "pointer" }}
                      onClick={() =>
                        setDeliveryAddresses((prev) =>
                          prev.filter((_, i) => i !== index)
                        )
                      }
                    />
                  </div>
                </div>
                <div className="CustomerNewCart-adress">
                  {address.address}, {address.city}, {address.state}, INDIA -{" "}
                  {address.pincode}
                </div>
              </div>
            </div>
          ))}

          {/* Add Address Section */}
          <div className="Customer-Cart-Add-Adress-Sec">
            <Plus weight="bold" style={{ color: "#fe585b" }} />
            <div
              className="Customer-Cart-Add-Adress-"
              onClick={handleAddDeliveryAddress}
            >
              Address
            </div>
          </div>
          <Modal
            open={openAddDeliveryAddress}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="Add-shipping-adress-modal-main">
                <div className="Add-shipping-adress-title">Add Address</div>
                <XCircle
                  weight="bold"
                  style={{ color: "#fe585b", cursor: "pointer" }}
                  onClick={handleCloseDeliveryAddress}
                />
              </div>
              <div className="Add-shipping-adress-modal">
                <div className="Add-shipping-adress-subtitle">
                  Contact Details
                </div>
                <TextField
                  required
                  label="Full Name"
                  variant="standard"
                  value={newDeliveryAddress.name}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("name", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="Enter Mobile Number"
                  variant="standard"
                  value={newDeliveryAddress.mobile}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("mobile", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
              </div>
              <div className="Add-shipping-adress-modal">
                <div className="Add-shipping-adress-subtitle">
                  Address Details
                </div>
                <TextField
                  required
                  label="Full Address"
                  variant="standard"
                  value={newDeliveryAddress.address}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("address", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="State"
                  variant="standard"
                  value={newDeliveryAddress.state}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("state", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="City"
                  variant="standard"
                  value={newDeliveryAddress.city}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("city", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="PinCode"
                  variant="standard"
                  value={newDeliveryAddress.pincode}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("pincode", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="Save As"
                  variant="standard"
                  value={newDeliveryAddress.saveAs}
                  onChange={(e) =>
                    handleDeliveyAdressInputChange("saveAs", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
              </div>
              <div className="add-deveilveryadressbutton-sec">
                <div
                  className="add-deveilveryadressbutton"
                  onClick={handleAddAddressToList}
                >
                  Add Address
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      {/* DELIVERY ADDRESS */}

      <div className="CustomerNewCart-subheading-section-itemlist-ForAdress">
        <div className="CustomerNewCart-adress-sectionMain">
          <div className="CustomerNewCart-adress-Heading">Shipping Address</div>

          {/* Existing Addresses */}
          {shippingAddresses.map((address) => (
            <div
              key={address.id}
              className="CustomerNewCart-adress-Details-Section"
            >
              <div onClick={() => handleCircleShippingClick(address.id)}>
                <Circle
                  style={{ color: "#FE585B" }}
                  weight={selectedAddressId === address.id ? "fill" : undefined}
                />
              </div>
              <div>
                <div>
                  <div className="CustomerNewCart-adress-Details-Name">
                    {address.name}
                  </div>
                  <div>
                    <Trash style={{ color: "#FE585B" }} />
                  </div>
                </div>
                <div className="CustomerNewCart-adress">{address.address}</div>
              </div>
            </div>
          ))}

          {/* Add Address Button */}
          <div className="Customer-Cart-Add-Adress-Sec">
            <Plus weight="bold" style={{ color: "#fe585b" }} />
            <div
              className="Customer-Cart-Add-Adress-"
              onClick={handleAddShippingAddress}
            >
              Add Address
            </div>
          </div>

          {/* Add Address Modal */}
          <Modal
            open={openAddShippingAddress}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="Add-shipping-adress-modal-main">
                <div className="Add-shipping-adress-title">Add Address</div>
                <XCircle
                  weight="bold"
                  style={{ color: "#fe585b", cursor: "pointer" }}
                  onClick={handleCloseShippingAddress}
                />
              </div>
              <div className="Add-shipping-adress-modal">
                <div className="Add-shipping-adress-subtitle">
                  Contact Details
                </div>
                <TextField
                  required
                  label="Full Name"
                  variant="standard"
                  value={newAddress.name}
                  onChange={(e) =>
                    handleShippingInputChange("name", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="Enter Mobile Number"
                  variant="standard"
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
              </div>
              <div className="Add-shipping-adress-modal">
                <div className="Add-shipping-adress-subtitle">
                  Address Details
                </div>
                <TextField
                  required
                  label="Full Address"
                  variant="standard"
                  value={newAddress.address}
                  onChange={(e) =>
                    handleShippingInputChange("address", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="State"
                  variant="standard"
                  value={newAddress.state}
                  onChange={(e) =>
                    handleShippingInputChange("state", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="City"
                  variant="standard"
                  value={newAddress.city}
                  onChange={(e) =>
                    handleShippingInputChange("city", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
                <TextField
                  required
                  label="PinCode"
                  variant="standard"
                  value={newAddress.pinCode}
                  onChange={(e) =>
                    handleShippingInputChange("pinCode", e.target.value)
                  }
                  className="widthofipline-develiveradresscom"
                  sx={{
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#fe585b",
                    },
                  }}
                />
              </div>
              <div className="add-deveilveryadressbutton-sec">
                <div
                  className="add-deveilveryadressbutton"
                  onClick={handleSaveAddress}
                >
                  Add Address
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

function CustomernewCartCheckout() {
  return (
    <div>
      <div>
        <div className="CustomerNewCart-heading">Check Out</div>
        <div className="CustomerNewCart-subheading-section">
          <DeliveryAddressComponent />

          <div className="CustomerNewCart-subheading-section-paymentDetails">
            <div className="CustomerNewCart-subheading-section-AmountSection">
              Order Details
            </div>
            <div className="CustomerNewCart-subheading-section-AmountDetailsSection-main">
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  Sub Total{" "}
                  <span className="CustomerNewCart-subheading-section-AmountDetailsSection-text1-span">
                    (3 Items)
                  </span>
                </div>
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  ₹ 2000
                </div>
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  Discount
                </div>
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  - ₹ 50
                </div>
              </div>
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  Shipping
                </div>
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text1">
                  ₹ 50
                </div>
              </div>
              <hr style={{ width: "100%", height: " 0px" }} />
              <div className="CustomerNewCart-subheading-section-AmountDetailsSection">
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text2">
                  To Pay
                  <span className="CustomerNewCart-subheading-section-AmountDetailsSection-text2-span">
                    (Inclusive Of Taxes)
                  </span>
                </div>
                <div className="CustomerNewCart-subheading-section-AmountDetailsSection-text3">
                  ₹ 2000
                </div>
              </div>
            </div>
            <div className="CustomerNewCart-proceedtopay-section">
              <div className="CustomerNewCart-proceedtopay-btn">
                Proceed To Pay
              </div>
            </div>
            <div className="customernewcart-p-t-p">
              Great! You are saving ₹50 in total
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomernewCartCheckout;

import { errorAlert, successAlert } from "../components/Alert";
import { API_BASE_URL } from "../App";

const addToWishlist = async (props, customerData, accessToken ,productId) => {
  // //console.log(buyerData, 'TESTBYRDATA')
    // //console.log('From Wishlist Function ' , props)
    try {
      // Make your API call here to add the item to the cart
      const response = await fetch(`${API_BASE_URL}/favorites/api/v1/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json-patch+json',
           Authorization: `Bearer ${accessToken}`,
},
        body: JSON.stringify({
            
                  //  "BuyerEmail": buyerData?.result?.email,
        "imageFilename": props?.productSKUs[0]?.imageList[0],
        "title": props?.title,
        "description": props?.description,
        "sku": props?.productSKUs[0].sku,
        "unitCost": parseInt(props?.productSKUs[0]?.sellingPrice),
        "userId": customerData?.result?.userID,
        "productId":props.productId

        // "ID": buyerData?.result?.userID
                  
                
              }

        )
      });
      
      if (response?.ok) {
        const responseData = await response.json();
        // fetchWishlistData(); 
        // successAlert('', 'Added to Wishlist')  
        //console.log(responseData);
        //console.log(response);
        
        return responseData?.result?.id; 
      } else {
          // errorAlert('', 'Could not add to Wishlist')
          return false
        throw new Error('Failed to add item to Wishlist');

      }
    } catch (error) {
        // errorAlert('', 'Could not add to Wishlist')

      console.error('Error adding item to Wishlist:', error);
      throw error;
      return false
    }
  };

  const deleteFromWishlist =  async( accessToken, id)=>{
    try{
      const result = await fetch(`${API_BASE_URL}/favorites/api/v1/${id}`,{
        method:'Delete',
        headers:{
          Authorization:`Bearer ${accessToken}`
        }
      });
      const response = await result.json();
      if(response?.message!==""){
        // successAlert('','Removed from wishlist')
        return true
      }
      else{
        return false
        // errorAlert('','Error Removing from Wishlist . Try again.')
      }
    }catch{
      return false
    }
  }
  
  export  {addToWishlist, deleteFromWishlist}


import React from "react";
import CustomerLayoutnew from "../components/CustomerLayoutnew";
import CustomerLandingCard5 from "../components/CustomerLandingCard5";
import CustomerLandingCard4 from "../components/CustomerLandingCard4";
import CustomerNewGiftCard from "../components/CustomerNewGiftCard";
function CustomerNewGiftCrtPage() {
  return (
    <CustomerLayoutnew>
      <CustomerNewGiftCard />
      <CustomerLandingCard4 />
      <CustomerLandingCard5 />
    </CustomerLayoutnew>
  );
}

export default CustomerNewGiftCrtPage;

import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import {
  Apps,
  Menu,
  ContactMail,
  AssignmentInd,
  Spa,
} from "@mui/icons-material";
import VerifiedIcon from "@mui/icons-material/Verified";
import BarChartIcon from "@mui/icons-material/BarChart";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import OnlinePredictionSharpIcon from "@mui/icons-material/OnlinePredictionSharp";
import CategoryIcon from "@mui/icons-material/Category";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {
  Heart,
  PhoneCall,
  Truck,
  ChatsCircle,
  AddressBook,
  Sparkle,
  Users,
  TagSimple,
  Article,
  UserList,
  ChartBar,
  IdentificationCard,
  SealCheck,
} from "@phosphor-icons/react";
import { AdminContext } from "../context/AdminContext";
import "../styles/AdminLayout2.css";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: 350,
    background: "#F2F2F2",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: "100%",
    position: "relative",
  },
  avatar: {
    margin: "0.5rem auto",
    padding: "1rem",
  },
  listItem: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "0.5rem",
    "&:hover": {
      background: "#FF99A5",
      color: "white",
    },
  },
  selectedListItem: {
    background: "linear-gradient(90deg, #FA068A 0%, #FE505F 100%)",
    color: "white",
  },
}));
const listItems = [
  // {
  //   listIcon: <DashboardIcon />,
  //   listText: "Dashboard",
  //   listNav: "/AdminDashboard",
  // },

  {
    listIcon: <Article size={24} />,
    listText: "Facts",
    listNav: "/AdminFacts",
  },
  {
    listIcon: <TagSimple size={24} />,
    listText: "Discounts",
    listNav: "/AdminDiscount",
  },
  {
    listIcon: <UserList size={24} />,
    listText: "Invite",
    listNav: "/inviteSeller2",
  },
  // {
  //   listIcon: <Diversity3Icon />,
  //   listText: "InviteLink",
  //   listNav: "/InviteSellerLink",
  // },
  {
    listIcon: <ChartBar size={24} />,
    listText: "Plans",
    listNav: "/adminPlanlist",
  },
  {
    listIcon: <Users size={24} />,
    listText: "Users",
    listNav: "/manageUsers",
  },

  {
    listIcon: <IdentificationCard size={24} />,
    listText: "Admin Invite",
    listNav: "/adminInvite",
  },
  {
    listIcon: <SealCheck size={24} />,
    listText: "Approval",
    listNav: "/AdminApproval",
  },
  {
    listIcon: <CategoryIcon size={24} />,
    listText: "Category",
    listNav: "/AdminAddCategory",
  },
  {
    listIcon: <Truck size={24} />,
    listText: "Track Order",
    listNav: "/trackOrder",
  },
  {
    listIcon: <Sparkle size={24} />,
    listText: "Review Approval",
    listNav: "/reviewApproval",
  },
  {
    listIcon: <ChatsCircle size={24} />,
    listText: "FAQ",
    listNav: "/AdminFaq",
  },
  {
    listIcon: <PhoneCall size={24} />,
    listText: "Contact Us",
    listNav: "/ContactUs",
  },
  {
    listIcon: <ShoppingCartIcon size={24} />,
    listText: "All Orders",
    listNav: "/allOrders",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Edit Product",
    listNav: "/adminProductSearch",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Gift Card",
    listNav: "/AdminUploadGc",
  },
  {
    listIcon: <Spa size={24} />,
    listText: "Buy Gift Card",
    listNav: "/AdminBuyGC",
  },
];

export default function AdminLayout(props) {
  // //console.log('Name :', window.myConfig.name)
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(false);
  const navigate = useNavigate();
  // const handleNav=(x)=>{
  //   navigate(x)
  // }
  const { selectedListItemIndex, setSelectedListItemIndex, logout } =
    useContext(AdminContext);
  const toggleSlider = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setSelectedListItemIndex(listItems[0]);
  }, []);

  const handleListItemClick = (index) => {
    setSelectedListItemIndex(index);
    //console.log(listItems[index].listNav);
    navigate(listItems[index].listNav);
  };

  const sideList = () => (
    <div className={classes.menuSliderContainer}>
      <Avatar
        className={classes.avatar}
        // src="https://i.ibb.co/rx5DFbs/avatar.png"
        alt="admin"
      />
      <Divider />
      <List>
        {/* <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}> */}
        {listItems.map((listItem, index) => (
          <ListItem
            className={`${classes.listItem} ${
              selectedListItemIndex === index ? classes.selectedListItem : ""
            }`}
            onClick={() => {
              handleListItemClick(index);
            }}
            key={index}
            style={{ padding: "1rem auto" }}
          >
            <ListItemIcon className={classes.listItem}>
              {listItem.listIcon}
            </ListItemIcon>
            <ListItemText primary={listItem.listText} />
          </ListItem>
        ))}
      </List>
      {/* <div className={classes.listItem} style={{position:"absolute", bottom:"0.1rem" ,width:"99%" ,fontSize:"1rem",textAlign:"center", minHeight:"25px", padding:"0.5rem 0"}} onMouseOver={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
          e.target.style.cursor="pointer";
        }}
        onMouseLeave={(e) => {
          e.target.style.backgroundColor = '#f2f2f2';
        }}onClick={logout}>
          Logout
        </div> */}
    </div>
  );

  return (
    <>
      <nav>
        <AppBar position="fixed" style={{ background: "#F2F2F2" }}>
          <Toolbar>
            <IconButton onClick={toggleSlider}>
              <Menu />
            </IconButton>
            <Drawer open={open} anchor="left" onClose={toggleSlider}>
              {sideList()}
            </Drawer>
          </Toolbar>
          {/* <Avatar
            style={{
              position: "absolute",
              right: "1rem",
              color: "black",
              top: "1.3rem",
              width: "30px",
              height: "30px",
            }}
            /> */}
        </AppBar>
        <div className="admin-layout">{props.children}</div>
        {/* <Footer color="admin-footer-color" /> */}
        {/* <BcHeading text={window.myConfig.name} /> */}
      </nav>
    </>
  );
}

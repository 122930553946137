import React, { useState } from "react";
import "../styles/CustomerNewMyAccount3.css";
import "../styles/CustomerNewCart.css";
import "../styles/CustomerNewMyAccount2.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import img1 from "../Images/cart-img1-new.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ArrowLeft } from "@phosphor-icons/react";

function CustomerNewMyAccountOrders() {
  const [orders, setOrders] = useState([
    {
      id: 1,
      orderDetails: {
        orderNumber: "403-8896183-3096341",
        orderDate: "29 August 2023",
        recipient: "Vaidehi Paradkar",
        deliveryDate: "1 Sept 2023",
        shippingStatus: "Shipping Today",
      },
      items: [
        {
          id: 1,
          name: "Button Down Dress",
          price: 2000,
          originalPrice: 2200,
          color: "Beige",
          img: img1,
          count: 1,
          showDropdown: false,
          selectedSize: "Small",
        },
      ],
    },
    {
      id: 2,
      orderDetails: {
        orderNumber: "403-8896183-3096342",
        orderDate: "10 September 2023",
        recipient: "Ravi Kumar",
        deliveryDate: "15 Sept 2023",
        shippingStatus: "Shipping Soon",
      },
      items: [
        {
          id: 2,
          name: "Blue Jeans",
          price: 1500,
          originalPrice: 1800,
          color: "Blue",
          img: img1,
          count: 2,
          showDropdown: false,
          selectedSize: "Medium",
        },
      ],
    },
  ]);

  const sizes = ["Small", "Medium", "Large", "X-Large"];

  const updateCount = (orderId, itemId, action) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId
          ? {
              ...order,
              items: order.items.map((item) =>
                item.id === itemId
                  ? {
                      ...item,
                      count:
                        action === "increment"
                          ? item.count + 1
                          : Math.max(1, item.count - 1),
                    }
                  : item
              ),
            }
          : order
      )
    );
  };

  const toggleDropdown = (orderId, itemId) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId
          ? {
              ...order,
              items: order.items.map((item) =>
                item.id === itemId
                  ? { ...item, showDropdown: !item.showDropdown }
                  : item
              ),
            }
          : order
      )
    );
  };

  const selectSize = (orderId, itemId, size) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === orderId
          ? {
              ...order,
              items: order.items.map((item) =>
                item.id === itemId
                  ? { ...item, selectedSize: size, showDropdown: false }
                  : item
              ),
            }
          : order
      )
    );
  };
  const [showDetails, setShowDetails] = useState(false);

  const handleOrderDetailsClick = () => {
    setShowDetails(true);
  };

  const handleBackClick = () => {
    setShowDetails(false);
  };
  return (
    <div className="customer-new-all-order-detail">
      {orders.map((order) => (
        <div key={order.id} className="customer-new-order-container">
          {/* Order Details Table */}
          {!showDetails ? (
            <div className="customer-new-all-order-details-table">
              <div className="customer-new-all-order-table-row">
                <div className="customer-new-all-order-table-cell">
                  Order Placed
                </div>
                <div className="customer-new-all-order-table-cell">
                  Shipped To
                </div>
                <div className="customer-new-all-order-table-cell">
                  Order # {order.orderDetails.orderNumber}
                </div>
              </div>
              <div className="customer-new-all-order-table-row">
                <div className="customer-new-all-order-table-cell1">
                  {order.orderDetails.orderDate}
                </div>
                <div className="customer-new-all-order-table-cell1">
                  {order.orderDetails.recipient}
                </div>
                <div
                  className="customer-new-all-order-table-cell-ord-det-btn"
                  onClick={handleOrderDetailsClick}
                >
                  Order details
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className="customer-new-all-order-details-back-button"
                onClick={handleBackClick}
              >
                <ArrowLeft style={{ color: "#fd2583" }} />
                Back
              </div>
              <div className="customer-new-all-order-details-table">
                <div className="customer-new-all-order-table-row2">
                  <div className="customer-new-all-order-table-cell">
                    Shipping Address
                  </div>
                  <div className="customer-new-all-order-table-cell">
                    Payment Method
                  </div>
                  <div className="customer-new-all-order-table-cell">
                    Order Summary
                  </div>
                </div>
                <div className="customer-new-all-order-table-row2">
                  <div className="customer-new-all-order-table-cell2">
                    Vaidehi Paradkar Nr Times Of India, Ashram Road, AHEMEDABAD,
                    GUJARAT, INDIA - 380009
                  </div>
                  <div className="customer-new-all-order-table-cell2">
                    Payment Method
                  </div>
                  <div className="customer-new-all-order-table-cell2">
                    <div className="customer-new-all-orderdetails-table">
                      <div>Subtotal (4 items)</div>
                      <div>₹3600</div>
                    </div>
                    <div className="customer-new-all-orderdetails-table">
                      <div>Discount</div>
                      <div>₹199</div>
                    </div>
                    <div className="customer-new-all-orderdetails-table">
                      <div>Shipping</div>
                      <div>₹50</div>
                    </div>
                    <div className="customer-new-all-orderdetails-table">
                      <div>To Pay</div>
                      <div>₹3401</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Delivery Information */}
          <div>
            <div className="customer-new-all-order-detail-date">
              Arriving on: {order.orderDetails.deliveryDate}
            </div>
            <div className="customer-new-all-order-detail-day">
              {order.orderDetails.shippingStatus}
            </div>
          </div>

          {/* Items Section */}
          <div className="customer-new-all-order-details-img-section">
            {order.items.map((item) => (
              <div
                className="CustomerNewCart-subheading-section2"
                key={item.id}
              >
                <div className="customer-new-all-order-img-details-section">
                  <div className="cust-new-myacc-img-sec">
                    <img
                      src={item.img}
                      alt="product"
                      className="CustomerNewCart-subheading-itemlist-img"
                    />

                    <div className="CustomerNewCart-subheading-itemlist-deatils">
                      <div className="CustomerNewCart-subheading-itemlist-deatils-head">
                        <div className="CustomerNewCart-subheading-itemlist-deatils-heading">
                          {item.name}
                        </div>
                      </div>
                      <div className="CustomerNewCart-subheading-itemlist-deatils-price">
                        <div className="CustomerNewCart-subheading-itemlist-deatils-price1">
                          ₹{item.price}
                        </div>
                        <div className="CustomerNewCart-subheading-itemlist-deatils-price2">
                          ₹{item.originalPrice}
                        </div>
                        <div className="CustomerNewCart-subheading-itemlist-deatils-price-d">
                          MRP Inclusive of all taxes
                        </div>
                      </div>
                      <div className="cust-my-acc-wishlist-main-btn-sec">
                        <div className="CustomerNewCart-subheading-itemlist-deatils-button1">
                          <img
                            src={item.img}
                            alt="color preview"
                            className="CustomerNewCart-subheading-itemlist-deatils-button1-img"
                          />
                          <div className="CustomerNewCart-subheading-itemlist-deatils-button1-color-size">
                            Color: {item.color}
                          </div>

                          <div className="CustomerNewCart-subheading-itemlist-deatils-button1-colorName-sizeName">
                            Size: {item.selectedSize}
                          </div>
                          <div className="CustomerNewCart-size-dropdown-wrapper">
                            {item.showDropdown ? (
                              <ArrowDropUpIcon
                                onClick={() =>
                                  toggleDropdown(order.id, item.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <ArrowDropDownIcon
                                onClick={() =>
                                  toggleDropdown(order.id, item.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                            {item.showDropdown && (
                              <div className="CustomerNewCart-subheading-itemlist-dropdown-menu">
                                {sizes.map((size) => (
                                  <div
                                    key={size}
                                    className="CustomerNewCart-subheading-itemlist-dropdown-item"
                                    onClick={() =>
                                      selectSize(order.id, item.id, size)
                                    }
                                  >
                                    {size}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="CustomerNewCart-subheading-itemlist-deatils-button2">
                          <RemoveIcon
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() =>
                              updateCount(order.id, item.id, "decrement")
                            }
                          />
                          <div className="CustomerNewCart-subheading-itemlist-deatils-number">
                            {item.count}
                          </div>
                          <AddIcon
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() =>
                              updateCount(order.id, item.id, "increment")
                            }
                          />
                        </div>
                      </div>
                      <div className="CustomerNewCart-all-ord-imag-details-btn-sec">
                        <div className="CustomerNewCart-all-ord-imag-details-btn-track">
                          Track Order
                        </div>
                        <div className="CustomerNewCart-all-ord-imag-details-btn-cancel">
                          Cancel Order
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CustomerNewMyAccountOrders;

import React, { useState } from "react";
import "../styles/CustomerListingPage.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Menu, MenuItem } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import Nc1 from "../Images/sliderimage3.png";
import Nc2 from "../Images/sliderimage4.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { TailSpin } from "react-loading-icons";

function CustomerListingPageC1() {
  const images = [
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 4 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 5 },
    { src: Nc1, title: "Title 1", subtitle: "$200", rating: 5 },
    { src: Nc2, title: "Title 2", subtitle: "$200", rating: 2 },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const [favoritedStates, setFavoritedStates] = useState({}); // For click state
  const [hoveredStates, setHoveredStates] = useState({}); // For hover state

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHover = (index, isHovering) => {
    setHoveredStates((prev) => ({
      ...prev,
      [index]: isHovering, // Set hover state for specific image
    }));
  };

  const handleFavoriteClick = (index) => {
    setFavoritedStates((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle favorite state for specific image
    }));
  };

  return (
    <div>
      <div className="customerListingPage-Main-heading">
        <div className="customerListingPage-Main-heading-title">New Launch</div>
        <div className="customerListingPage-Main-heading-subtitle">
          All Products
        </div>
      </div>
      <div className="customerListingPage-Main-sortsection">
        <FilterAltOutlinedIcon
          onClick={handleClick}
          style={{ cursor: "pointer", color: "#fe585b" }}
        />
        <div
          className="customerListingPage-Main-sortby"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          Sort by
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem onClick={handleClose}>Sort By : Recommended</MenuItem>
          <MenuItem onClick={handleClose}>Recommended</MenuItem>
          <MenuItem onClick={handleClose}>What’s New</MenuItem>
          <MenuItem onClick={handleClose}>Popularity</MenuItem>
          <MenuItem onClick={handleClose}>Discount</MenuItem>
          <MenuItem onClick={handleClose}>Price: Low To High</MenuItem>
          <MenuItem onClick={handleClose}>Price: High To Low</MenuItem>
          <MenuItem onClick={handleClose}>Customer Rating</MenuItem>
        </Menu>
      </div>
      <div className="customerListingPage-cards">
        {images.map((image, index) => {
          const percentage = (image.rating / 5) * 100;
          const isFavorited = favoritedStates[index] || false; // Get click state
          const isHovered = hoveredStates[index] || false; // Get hover state

          return (
            <div key={index}>
              <div className="customernewListingCard1-best-seller-label">
                Best Seller
              </div>
              <img
                src={image.src}
                alt={image.title}
                style={{ width: "100%" }}
              />
              <div className="CustomernewlistingCard1-imageRating">
                <StarIcon
                  key="single-star"
                  rating={image.rating}
                  style={{
                    color: "#029F4A",
                    clipPath: `inset(0 ${100 - percentage}% 0 0)`,
                  }}
                />
                <div>{image.rating} | 5</div>
              </div>
              <button
                className="Customernewlistingheart-button"
                onClick={() => handleFavoriteClick(index)} // Toggle favorite on click
                onMouseEnter={() => handleHover(index, true)} // Set hover true
                onMouseLeave={() => handleHover(index, false)} // Set hover false
              >
                {isFavorited || isHovered ? (
                  <FavoriteIcon
                    style={{ fontSize: "16px", color: "#fd2583" }}
                  />
                ) : (
                  <FavoriteBorderIcon
                    style={{ fontSize: "16px", color: "#fd2583" }}
                  />
                )}
              </button>
              <div className="customerNewlistingCard-titlesection">
                <div className="customerNewlistingCard1-title">
                  {image.title}
                </div>
                <div className="customerNewlistingCard1-subtitle">
                  {image.subtitle}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="customerlistingPage-loadmore">
        <div>Load More</div>
        <div>
          <TailSpin stroke="#fd2583" width="20px" height="20px" />
        </div>
      </div>
    </div>
  );
}

export default CustomerListingPageC1;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BuyersMyAccount from "./pages/BuyersMyAccount";
import Footer from "./pages/Footer";

import HomePage from "./pages/Home";
import LoginPage from "./pages/LoginPage";
// import MyAccount from "./components/MyAccount";
import MyOrders from "./pages/MyOrders";
import OtpPage from "./pages/BuyerOtpLogin";
import OtpVerify from "./pages/BuyerOtpVerify";
import ProductFilterPage from "./pages/ProductFilterPage";
import ResetPassword from "./pages/ResetPassword";
import SignUpPage from "./pages/SIgnUpPage";
import SingleProduct from "./pages/SingleProduct";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import SellerSubscriptionList from "./pages/SellerSubscriptionList";
import SellerPersonalData from "./pages/SellerPersonalData";
import InviteSeller from "./pages/InviteSeller";
// import SellerRegistration from "./pages/SellerRegistration";
import Helper from "./pages/Helper";
// import TrackOrder from "./pages/TrackOrder";
import SellerLiveOrders from "./pages/SellerLiveOrders";
import SellerLiveOrderDetails from "./pages/SellerLiveOrderDetails";
// import AdminManageUsers from "./pages/AdminManageUsers";
import AddNewUser from "./pages/AddNewUser";
import AddressPage from "./pages/AddressPage";
import AdminAllOrders from "./components/AdminAllOrders";
import ReviewsAndRatings from "./pages/ReviewsAndRatings";
import Contact from "./pages/Contact";
import BrowsingHistory from "./pages/BrowsingHistory";
import SellerCustomerList from "./pages/SellerCustomerList";
// import SellerDiscounts from "./pages/SellerDiscounts";
import SellerFacts from "./pages/SellerFacts";
import SellerDashboard from "./pages/SellerDashboard";
import SellerLogin from "./pages/SellerLogin";
import SellerMyAccount from "./pages/SellerMyAccount";
import AdminLogin from "./pages/AdminLogin";
import SellerOtpConfirmationPage from "./pages/SellerOtpConfirmationPage";
import SellerOtpVerify from "./pages/SellerOtpVerify";
import SellerForgetPassword from "./pages/SellerForgetPassword";
import SellerResetPassword from "./pages/SellerResetPassword";
import AdminPlanList from "./pages/AdminPlanList";
// import AdminSubsDetailPage from "./pages/AdminSubsDetailPage";
import AdminApproval from "./pages/AdminApproval";
import AdminApprovalDetailVerification from "./pages/AdminApprovalDetailVerification";
import SellerAcceptInvite from "./pages/SellerAcceptInvite";
import SellerInviteReject from "./pages/SellerInviteReject";
// import InviteSeller2 from "./pages/InviteSeller2";
import AdminOtpLogin from "./pages/AdminOtpLogin";
import AdminAddPlan from "./pages/AdminAddPlan";
// import RequireAdminAuth from "./Context/RequireAdminAuth";
import AdminForgetPassword from "./pages/AdminForgetPassword";
import AdminOtpVerify from "./pages/AdminOtpVerify";
import AdminResetPassword from "./pages/AdminResetPassword";
// import SellerProductDetails from "./pages/SellerProductDetails";
// import HelperSubsPlan from "./pages/HelperSubsPlan";
import AdminSubscriptionPlanDetails from "./pages/AdminSubscriptionPlanDetails";
import AdminInvite from "./pages/AdminInvite";
import InviteSeller2 from "./pages/InviteSeller2";
import SellerOtpLogin from "./pages/SellerOtpLogin";
// import SellerOtpLoginVerify from "./pages/SellerOtpLoginVerify";
import SellerRegister from "./pages/SellerRegister";
import SellerProductsPage from "./pages/SellerProductsPage";
import SellerCategoriesListingPage from "./pages/SellerCategoriesListingPage";
import AddProductBySeller from "./pages/AddProductBySeller";
import SellerProductDetails2 from "./pages/SellerProductDetails2";
import AddProductBySeller2 from "./pages/AddProductBySeller2";
import OrderTracking from "./pages/OrderTracking";
import AdminAddCategory from "./pages/AdminAddCategory";
import { AdminContextProvider } from "./context/AdminContext";
import { SellerContextProvider } from "./context/SellerContext";
import AdminFacts from "./pages/AdminFacts";
import SellerManagementPage from "./pages/SellerManagementPage";
import SellerResetPasswordEmail from "./pages/SellerResetPasswordEmail";
import AdminDashboard from "./pages/AdminDashboard";
import SellerDiscounts from "./pages/SellerDiscounts";
import AdminDiscount from "./pages/AdminDiscount";
// import Seller_Login from "./pages/Seller_Login";
import SellerOtpVerifyLogin from "./pages/SellerOtpVerifyLogin";
import AdminForgotPasswordOtpVerify from "./pages/AdminForgotPasswordOtpVerify";
import SellerForgotPasswordOtpVerify from "./pages/SellerForgotPasswordOtpVerify";
import CardComponent from "./components/CardComponent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndCondition from "./components/TermsAndCondition";
import ComingSoon from "./components/ComingSoon";
import AdminDiscountCard from "./components/SellerDiscountCard";
import TrackOrder from "./pages/TrackOrder";
import AdminManageUsers from "./pages/AdminManageUsers";
import { BuyerContext, BuyerContextProvider } from "./context/BuyerContext";
import BuyerOtpLogin from "./pages/BuyerOtpLogin";
import BuyerOtpVerify from "./pages/BuyerOtpVerify";
import BuyerForgetPassword from "./pages/BuyerForgetPassword";
import BuyerForgetPasswordOtpVerify from "./pages/BuyerForgetPasswordOtpVerify";
import BuyerRegister from "./components/BuyerRegister";
import BuyerCard from "./components/BuyerCard";
import BuyerLoginPage from "./pages/BuyersLogin";
import LandingPage from "./pages/LandingPage";
import { CartProvider } from "./context/CartContext";
import ViewOrder from "./pages/ViewOrder";
import SingleProductReviewsAndRatings from "./pages/SingleProductReview";
import BuyerLoginPasswordScreen from "./pages/BuyerLoginPasswordScreen";
import RatingCardComponent from "./components/RatingCardComponent";
import BuyerSignIn from "./components/BuyerSignIn";
import Chatbot from "./components/ChatBot";
import BuyerRegisterEmail from "./pages/BuyerRegisterEmail";
import Cart2 from "./pages/Cart2";
import ManageProfile from "./pages/ManageProfile";
import { FilterProvider } from "./context/FilterContext";
import BuyerReviewsRatings from "./pages/BuyerReviewsRating";
import ReviewApproval from "./pages/AdminReview";
import BcWishlistCard2 from "./buyerComponents/BcWishlistCard2";
import WishlistPage from "./buyerComponents/WishlistPage";

import LocalVendorManageOrders from "./pages/LocalVendorManageOrders";
import BcModalTestPage from "./buyerComponents/BcModalTestPage";
import {
  CustomerContextProvider,
  CustomerProvider,
  useCustomerContext,
} from "./context/CustomerContext";
import CustomerLandingPage from "./pages/CustomerLandingPage";
import CustomerProductListingPage from "./pages/CustomerProductListingPage";
import CustomerProfilePage from "./pages/CustomerProfilePage";
import LocalVendorOrder from "./pages/LocalVendorOrder";
import {
  LocalVendorContext,
  LocalVendorContextProvider,
} from "./context/LocalVendorContext";
import BuyersHelp from "./components/BuyersHelp";
import CustomerCart from "./pages/CustomerCart";
import CustomerProductDetail from "./pages/CustomerProductDetail";
import CustomerDetails from "./components/CustomerDetails";
import CustomerFaq from "./pages/CustomerFaq";
import CustomerContactEmail from "./pages/CustomerContactEmail";
import Address from "./pages/Address";
import AddressCard from "./components/AddressCard";
import EmptyPage from "./pages/EmptyPage";
import LogisticsAdminLogin from "./pages/LogisticsAdminLogin";
import LogisticsAdminOrders from "./pages/LogisticsAdminOrders";
import { LogisticsAdminContextProvider } from "./context/LogisticsAdminContext";
import LogisticsAdminOrderItemDetails from "./pages/LogisticsAdminOrderItemDetails";
import SingleProductCustomerReviews from "./pages/SingleProductCustomerReviews";
import CustomerPhoneOtpLogin from "./pages/CustomerPhoneOtpLogin";
import CustomerOtpVerifyLogin from "./pages/CustomerOtpVerifyLogin";
import LogisticAdminReturnOrder from "./pages/LogisticAdminReturnOrder";
import LogisticAdminCancelOrder from "./pages/LogisticAdminCancelOrder";
import LogisticAdminThirdParty from "./pages/LogisticAdminThirdParty";
import AdminFaq from "./pages/AdminFaq";
import CustomerPaymentConfirmationPage from "./pages/CustomerPaymentConfirmationPage";
import SellerPageLogin from "./pages/SellerPageLogin";
import SellerAccount from "./pages/SellerAccount";
import Blog from "./pages/Blog";
import DetailedBlogPage from "./components/DetailedBlogPage";
import Offers from "./pages/Offers";
import SellerPaymentConfirmationPage from "./pages/SellerPaymentConfirmationPage";
import SellerAddress from "./pages/SellerAddress";
import SellerSubsPlanPage from "./pages/SellerSubsPlanPage";
import InviteSellerLink from "./pages/InviteSellerLink";
import { ConstructionComponent } from "./pages/ConstructionComponent";
import AdminContactUs from "./pages/AdminContactUs";
import AdminEditPage from "./pages/AdminEditPage";
import AdminProductSearch from "./pages/AdminProductSearch";
import AdminProductPreview from "./pages/AdminProductPreview";
import ShippingPolicy from "./components/ShippingPolicy";
import TermsAndConditionSeller from "./components/TermsAndConditionSeller";
import AboutUsFooter from "./components/AboutUsFooter";
import ReturnRefundPolicy from "./components/ReturnRefund";
import BuyerPrivacyPolicy from "./components/BuyerPrivacyPolicy";
import RefundPolicySeller from "./pages/RefundPolicySeller";
import BuyerShippingPolicy from "./pages/BuyerShippingPolicy";
// import CustomerVoucher from "./pages/CustomerVoucher";
import CustomerGiftCard from "./pages/CustomerGiftCard";
import CustomerGiftCardDetail1 from "./pages/CustomerGiftCardDetail1";
import CustomerGiftCardDetail2 from "./pages/CustomerGiftCardDetail2";
import CustomerGiftCardDetail3 from "./pages/CustomerGiftCardDetail3";
import CustomerGiftCardDetail4 from "./pages/CustomerGiftCardDetail4";
import CustomerGiftCardDetail from "./pages/CustomerGiftCardDetails";
import SearchNotMatch from "./pages/SearchNotMatch";
import UnderConstruction from "./pages/UnderConstruction";
// import ProductDetailDescription from "./pages/ProductDetailDescription";
import CustomerCancellationPolicy from "./components/CustomerCancellationPolicy";
import GiftCardPurchaseDetails from "./pages/GiftCardPurchaseDetails";
import AdminAddGiftCard from "./pages/AdminAddGiftCard";
import AdminAddNewGiftCard from "./pages/AdminAddNewGiftCard";
import AdminBuyGC from "./pages/AdminBuyGC";
import CustSingleProductBanner from "./pages/CustSingleProductBanner";
/*NEW LANDING PAGE COMPONENTS */
import CustomerLandingPageNew from "./pages/CustomerLandingPageNew";
import CustomerListingPageNew from "./pages/CustomerListingPageNew";
import CustomerNewProductDetails from "./pages/CustomerNewProductDetails";
import CustomerNewCart from "./pages/CustomerNewCart";
import CustomerNewCheckOut from "./pages/CustomerNewCheckOut";
import CustomerNewAccount from "./pages/CustomerNewAccount";
import CustomerNewGiftCrtPage from "./pages/CustomerNewGiftCrtPage";
import CustomerLandingBlogs from "./pages/CustomerLandingBlogs";

// import SeasonOfferCard from "./components/SeasonOfferCard";

function App() {
  let hostname = window.location.hostname;
  let RootComponent;

  if (
    hostname === "dev-admin.myhraki.com" ||
    hostname === "stage-admin.myhraki.com" ||
    hostname === "admin.myhraki.com" ||
    hostname === "4.213.40.16"
    // || hostname === "localhost"
  ) {
    RootComponent = Admin;
  } else if (
    hostname === "dev-seller.myhraki.com" ||
    hostname === "stage-seller.myhraki.com" ||
    hostname === "seller.myhraki.com" ||
    hostname === "4.213.40.16"
    //|| hostname === "localhost"
  ) {
    RootComponent = Seller;
  } else if (
    hostname === "dev-seller.myhraki.com" ||
    hostname === "stage-seller.myhraki.com" ||
    hostname === "seller.myhraki.com" ||
    hostname === "4.213.40.16"
    // || hostname === "localhost"
  ) {
    RootComponent = LocalVendor;
  } else if (
    hostname === "dev.myhraki.com" ||
    hostname === "stage.myhraki.com" ||
    hostname === "myhraki.com" ||
    hostname === "www.dev.myhraki.com" ||
    hostname === "www.stage.myhraki.com" ||
    hostname === "www.myhraki.com" ||
    hostname === "4.213.40.16" ||
    hostname === "https://myhraki.azureedge.net" ||
    hostname === "https://www.myhraki.azureedge.net" ||
    hostname === "localhost"
  ) {
    RootComponent = Customer;
  }
  // else if (

  //   hostname === "myhraki.com"||
  //   //  ||  hostname === "localhost"
  //   hostname === "4.224.100.61"
  // ) {
  //   RootComponent = Construction
  // }
  else if (
    hostname === "dev-logistic.myhraki.com" ||
    hostname === "stage-logistic.myhraki.com" ||
    hostname === "logistic.myhraki.com" ||
    hostname === "4.213.40.16"
    // || hostname === "localhost"
  ) {
    RootComponent = LogisticsAdmin;
  } else {
    RootComponent = Error;
  }

  return (
    <div>
      <RootComponent />
    </div>
  );
}

export default App;

function Construction() {
  return <ConstructionComponent />;
}

function Error() {
  return <h1>Error</h1>;
}

function Seller() {
  return (
    <div>
      <BrowserRouter>
        <SellerContextProvider>
          <FilterProvider>
            <Routes>
              <Route
                path="/sellerPersonalData"
                element={<SellerPersonalData />}
              />
              <Route path="/comingSoon" element={<ComingSoon />} />
              <Route path="/cardcomponent" element={<CardComponent />} />
              <Route
                path="/orderApproval/:sellerProductsId"
                element={<SellerLiveOrderDetails />}
              />
              <Route
                path="/sellerForgetPassword"
                element={<SellerForgetPassword />}
              />
              <Route
                path="/sellerResetPassword"
                element={<SellerResetPassword />}
              />
              <Route path="/SellerAddress" element={<SellerAddress />} />
              <Route
                path="/SellerResetPasswordEmail"
                element={<SellerResetPasswordEmail />}
              />
              <Route
                path="/sellerInviteReject"
                element={<SellerInviteReject />}
              />

              <Route
                path="/:sellerId/SellerMyAccount"
                element={<SellerMyAccount />}
              />
              <Route
                path="/addProductBySeller"
                element={<AddProductBySeller />}
              />
              <Route
                path="/addProductBySeller2"
                element={<AddProductBySeller2 />}
              />
              {/* <Route path="/:sellerId/SellerProductDetails/:sellerProductId" element={<SellerProductDetails />} /> */}
              <Route
                path="/SellerProductDetails2/:id"
                element={<SellerProductDetails2 />}
              />
              <Route
                path="/SellerProductsPage/:category"
                element={<SellerProductsPage />}
              />
              <Route
                path="/SellerCategoriesListingPage"
                element={<SellerCategoriesListingPage />}
              />
              <Route
                path="/sellerManagement"
                element={<SellerManagementPage />}
              />
              <Route path="/SellerOrders" element={<SellerLiveOrders />} />
              <Route
                path="/SellerOrdersDeatils"
                element={<SellerLiveOrderDetails />}
              />
              <Route
                path="/SellerOrderDetails/:itemId"
                element={<SellerLiveOrderDetails />}
              />
              <Route path="/SellerDashboard" element={<SellerDashboard />} />
              <Route path="/" element={<SellerLogin />} />
              <Route
                path="/sellerAcceptInvite/:id"
                element={<SellerAcceptInvite />}
              />
              <Route path="/SellerCustomers" element={<SellerCustomerList />} />
              <Route
                path="/SubscriptionPlans"
                element={<SellerSubsPlanPage />}
              />
              <Route path="/SellerAccount" element={<SellerAccount />} />
              <Route path="/SellerDiscounts" element={<SellerDiscounts />} />
              <Route path="/SellerFacts" element={<SellerFacts />} />
              <Route
                path="/sellerOtpConfirmation"
                element={<SellerOtpConfirmationPage />}
              />
              <Route path="/sellerOtpVerify" element={<SellerOtpVerify />} />
              <Route
                path="/sellerOtpVerifyLogin"
                element={<SellerOtpVerifyLogin />}
              />
              <Route
                path="/SellerForgotPasswordOtpVerify"
                element={<SellerForgotPasswordOtpVerify />}
              />
              <Route
                path="/paymentConfirmation"
                element={<SellerPaymentConfirmationPage />}
              />
              <Route path="/SellerRegister" element={<SellerRegister />} />
              <Route path="/sellerPageLogin" element={<SellerPageLogin />} />
              <Route path="/SellerDashboard" element={<SellerDashboard />} />
              <Route path="/sellerOtplogin" element={<SellerOtpLogin />} />
              <Route path="/inviteSeller" element={<InviteSeller />} />
              <Route
                path="/termsAndCondition"
                element={<TermsAndCondition />}
              />
              <Route
                path="/termsAndConditionSeller"
                element={<TermsAndConditionSeller />}
              />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
              <Route path="/RefundPolicy" element={<RefundPolicySeller />} />
            </Routes>
          </FilterProvider>
        </SellerContextProvider>
      </BrowserRouter>
    </div>
  );
}

function Admin() {
  return (
    <div>
      <BrowserRouter>
        <AdminContextProvider>
          <Routes>
            <Route
              path="/adminForgetPassword"
              element={<AdminForgetPassword />}
            />
            <Route path="/AdminDashboard" element={<AdminDashboard />} />
            <Route path="/AdminDiscount" element={<AdminDiscount />} />
            <Route path="/AdminDiscountCard" element={<AdminDiscountCard />} />
            <Route
              path="/adminResetPassword"
              element={<AdminResetPassword />}
            />
            <Route path="/adminAddPlan" element={<AdminAddPlan />} />
            <Route path="/adminApproval" element={<AdminApproval />} />
            <Route path="/reviewApproval" element={<ReviewApproval />} />
            <Route path="/adminPlanlist" element={<AdminPlanList />} />
            {/* <Route path="/FactEditingModal" element={<FactModal />} /> */}
            {/* <Route path="/adminPlansDetailPage/:subsId" element={<AdminSubsDetailPage />} /> */}
            <Route
              path="/adminApprovalDetailVerification/:id"
              element={<AdminApprovalDetailVerification />}
            />
            <Route path="/inviteSeller2" element={<InviteSeller2 />} />
            <Route path="/InviteSellerLink" element={<InviteSellerLink />} />
            <Route
              path="/adminProductSearch"
              element={<AdminProductSearch />}
            />
            <Route path="/adminEditPage/:id" element={<AdminEditPage />} />
            <Route
              path="/adminPreview/:productId"
              element={<AdminProductPreview />}
            />
            <Route path="/" element={<AdminLogin />} />
            <Route path="/adminAddCategory" element={<AdminAddCategory />} />
            {/* <Route path="/adminlogin2" element={<AdminLogin2 />} /> */}
            <Route path="/termsAndCondition" element={<TermsAndCondition />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
            <Route path="/AdminOtpVerify" element={<AdminOtpVerify />} />
            <Route
              path="/AdminForgotPasswordOtpVerify"
              element={<AdminForgotPasswordOtpVerify />}
            />
            <Route path="/adminOtplogin" element={<AdminOtpLogin />} />
            <Route path="/AdminInvite" element={<AdminInvite />} />
            <Route path="/AdminFacts" element={<AdminFacts />} />
            <Route
              path="/AdminSubscriptionPlanDetails/:subsId"
              element={<AdminSubscriptionPlanDetails />}
            />
            <Route path="/trackOrder" element={<TrackOrder />} />
            <Route path="/ContactUs" element={<AdminContactUs />} />
            <Route path="/allOrders" element={<AdminAllOrders />} />

            <Route path="/account/contact" element={<Contact />} />
            <Route path="/manageUsers" element={<AdminManageUsers />} />
            <Route path="/addNewUsers" element={<AddNewUser />} />
            <Route path="/comingSoon" element={<ComingSoon />} />
            <Route path="/adminFaq" element={<AdminFaq />} />
            <Route path="/AdminUploadGC" element={<AdminAddGiftCard />} />
            <Route
              path="/AdminUploadNewGc/:category"
              element={<AdminAddNewGiftCard />}
            />
            <Route path="/AdminBuyGC" element={<AdminBuyGC />} />
          </Routes>
        </AdminContextProvider>
      </BrowserRouter>
    </div>
  );
}

function Buyer() {
  return (
    <BrowserRouter>
      <BuyerContextProvider>
        <CartProvider>
          <FilterProvider>
            <Routes>
              <Route
                path="/buyerEmailRegsiter"
                element={<BuyerRegisterEmail />}
              />
              <Route path="/login" element={<BuyerLoginPage />} />
              <Route path="/register" element={<BuyerRegister />} />
              {/* <Route path="/reset" element={<ResetPassword />} />  */}
              <Route path="/otpLogin" element={<BuyerOtpLogin />} />
              <Route path="/landingPage" element={<LandingPage />} />
              <Route path="/inviteSeller" element={<InviteSeller />} />
              <Route path="/cardcomponent" element={<CardComponent />} />
              <Route path="/BuyerCard" element={<BuyerCard />} />
              <Route
                path="/buyerloginpasswordscreen"
                element={<BuyerLoginPasswordScreen />}
              />
              <Route path="/otpLogin" element={<BuyerOtpLogin />} />
              <Route path="/otpVerify" element={<BuyerOtpVerify />} />
              <Route
                path="/buyerForgetPassword"
                element={<BuyerForgetPassword />}
              />
              <Route
                path="/buyerForgetPasswordOtpVerify"
                element={<BuyerForgetPasswordOtpVerify />}
              />
              <Route path="/footer" element={<Footer />} />
              {/* <Route path="/verify" element={<OtpVerify />} /> */}
              <Route
                path="/singleProduct/:productId"
                element={<SingleProduct />}
              />

              <Route
                path="/productFilter/home"
                element={<ProductFilterPage />}
              />
              <Route path="/productFilter" element={<ProductFilterPage />} />
              <Route path="/helper" element={<Helper />} />
              <Route path="/comingSoon" element={<ComingSoon />} />
              <Route
                path="/sellerSubscription"
                element={<SellerSubscriptionList />}
              />
              <Route
                path="/termsAndCondition"
                element={<TermsAndCondition />}
              />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/account/contact" element={<Contact />} />
              <Route path="/BuyerSignIn" element={<BuyerSignIn />} />
              <Route path="/account/history" element={<BrowsingHistory />} />
              <Route path="/buyerLogin" element={<BuyerLoginPage />} />
              {/* <Route path="/" element={<BcBuyerLandingPage />} /> */}
              <Route path="/" element={<LandingPage />} />
              {/* <Route path="/localVendormanage" element={<LocalVendorManageOrders />} /> */}
              <Route path="/BcWishlistCard2" element={<BcWishlistCard2 />} />
              <Route path="/ChatBot" element={<Chatbot />} />
              <Route path="/Address" element={<Address />} />
            </Routes>
          </FilterProvider>
        </CartProvider>
      </BuyerContextProvider>
    </BrowserRouter>
  );
}

function Customer() {
  return (
    <BrowserRouter>
      <CustomerContextProvider>
        <FilterProvider>
          <Routes>
            {/*  new/}

            {/*  <Route path="/" element={<CustomerLandingPageNew />} /> */}
            <Route path="/CustomerNewCart" element={<CustomerNewCart />} />
            <Route
              path="/CustomerNewCheckOut"
              element={<CustomerNewCheckOut />}
            />
            <Route
              path="/productDetail"
              element={<CustomerNewProductDetails />}
            />
            <Route path="/shopgiftcard" element={<CustomerNewGiftCrtPage />} />
            <Route path="/Blogs" element={<CustomerLandingBlogs />} />
            <Route path="/myaccount" element={<CustomerNewAccount />} />
            <Route
              path="/productListingnew"
              element={<CustomerListingPageNew />}
            />
            {/*  old*/}
            <Route path="/" element={<CustomerLandingPage />} />
            <Route path="/modalTest" element={<BcModalTestPage />} />
            <Route path="/termsAndCondition" element={<TermsAndCondition />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="/products" element={<CustomerProductListingPage />} />
            <Route path="/homepage" element={<CustomerLandingPage />} />
            <Route path="/dummy" element={<InviteSeller />} />
            <Route path="/ac_voucher" element={<CustomerLandingPage />} />
            <Route
              path="/productListing"
              element={<CustomerProductListingPage />}
            />

            <Route path="/cart" element={<CustomerCart />} />
            <Route path="/profile" element={<CustomerProfilePage />} />

            <Route
              path="/productDetail/:productId"
              element={<CustomerProductDetail />}
            />
            <Route
              path="/paymentConfirmation"
              element={<CustomerPaymentConfirmationPage />}
            />
            <Route path="/customerDetails" element={<CustomerDetails />} />
            <Route path="/reviewRating" element={<ReviewsAndRatings />} />
            <Route
              path="/BuyerReviewsRatings"
              element={<BuyerReviewsRatings />}
            />
            <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
            <Route path="/account/reviews" element={<ReviewsAndRatings />} />
            <Route
              path="/RatingCardComponent"
              element={<RatingCardComponent />}
            />
            <Route path="/buyerHelp" element={<BuyersHelp />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/aboutUs" element={<AboutUsFooter />} />
            <Route path="/RetunRefund" element={<ReturnRefundPolicy />} />
            <Route
              path="/DetailedBlogPage/:id"
              element={<DetailedBlogPage />}
            />
            {/* <Route path="/detailed-blog/:id" component={DetailedBlogPage} /> */}
            <Route path="/account/addresses" element={<AddressPage />} />
            <Route path="/viewOrder" element={<ViewOrder />} />
            <Route path="/myorders" element={<MyOrders />} />
            <Route path="/orderTracking" element={<OrderTracking />} />
            <Route path="/account" element={<BuyersMyAccount />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart2" element={<Cart2 />} />
            <Route path="/wishlistpage" element={<WishlistPage />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/faq" element={<CustomerFaq />} />
            <Route path="/manageProfile" element={<ManageProfile />} />
            <Route path="/termsAndCondition" element={<TermsAndCondition />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="/buyerPrivacyPolicy"
              element={<BuyerPrivacyPolicy />}
            />
            <Route
              path="/CustomerCancellationPolicy"
              element={<CustomerCancellationPolicy />}
            />
            {/* <Route path="/voucher" element={<CustomerVoucher />} />*/}
            <Route path="/giftCard" element={<CustomerGiftCard />} />
            {/* <Route
              path="/giftCardDetail/:id"
              element={<CustomerGiftCardDetail />}
            /> */}
            <Route
              path="/giftCardDetail"
              element={<CustomerGiftCardDetail />}
            />
            <Route path="/SearchNotMatch" element={<SearchNotMatch />} />
            <Route path="/UnderConstruction" element={<UnderConstruction />} />
            <Route
              path="/SingleProductBanner"
              element={<CustSingleProductBanner />}
            />
            <Route
              path="/GiftCardPurchaseDetails"
              element={<GiftCardPurchaseDetails />}
            />
            {/* <Route path="/ProductDetailDescription" element={<ProductDetailDescription/>}></Route> */}
            {/* <Route
              path="/giftCardDetail1"
              element={<CustomerGiftCardDetail1 />}
            />
            <Route
              path="/giftCardDetail2"
              element={<CustomerGiftCardDetail2 />}
            />
            <Route
              path="/giftCardDetail3"
              element={<CustomerGiftCardDetail3 />}
            />
            <Route
              path="/giftCardDetail4"
              element={<CustomerGiftCardDetail4 />}
  /> */}
            <Route path="/ShippingPolicy" element={<ShippingPolicy />} />
            <Route
              path="/CustomerContactEmail"
              element={<CustomerContactEmail />}
            />
            <Route
              path="/SingleProductReviewsAndRatings"
              element={<SingleProductReviewsAndRatings />}
            />
            <Route path="/Address" element={<Address />} />
            <Route path="/EmptyPage" element={<EmptyPage />} />
            <Route path="/Offers" element={<Offers />} />
            <Route
              path="/SingleProductCustomerReviews"
              element={<SingleProductCustomerReviews />}
            />
            <Route
              path="/CustomerOtpVerifyLogin"
              element={<CustomerOtpVerifyLogin />}
            />
            <Route
              path="/CustomerPhoneOtpLogin"
              element={<CustomerPhoneOtpLogin />}
            />
            <Route
              path="/BuyerShippingPolicy"
              element={<BuyerShippingPolicy />}
            />
          </Routes>
        </FilterProvider>
      </CustomerContextProvider>
    </BrowserRouter>
  );
}
function LocalVendor() {
  return (
    <BrowserRouter>
      <LocalVendorContextProvider>
        <Routes>
          <Route path="/localVendorOrder" element={<LocalVendorOrder />} />
          <Route
            path="/localVendorManageOrders"
            element={<LocalVendorManageOrders />}
          />
        </Routes>
      </LocalVendorContextProvider>
    </BrowserRouter>
  );
}

function LogisticsAdmin() {
  return (
    <BrowserRouter>
      <LogisticsAdminContextProvider>
        <Routes>
          <Route path="/" element={<LogisticsAdminLogin />} />
          <Route path="/orders" element={<LogisticsAdminOrders />} />
          <Route
            path="/orders/:orderId"
            element={<LogisticsAdminOrderItemDetails />}
          />
          <Route path="/return" element={<LogisticAdminReturnOrder />} />
          <Route path="/return/:orderId" />
          <Route path="/cancel" element={<LogisticAdminCancelOrder />} />
          <Route path="/thirdParty" element={<LogisticAdminThirdParty />} />
        </Routes>
      </LogisticsAdminContextProvider>
    </BrowserRouter>
  );
}

const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  //console.log("hostname", hostname);

  if (hostname.includes("localhost") || hostname.includes("dev")) {
    return "https://dev-api.myhraki.com/web";
  } else if (hostname.includes("stage")) {
    return "https://stage-api.myhraki.com/web";
  } else {
    return "https://api.myhraki.com/web";
  }
};

export const API_BASE_URL = getApiBaseUrl();
